<template>
    <UserManagmentModal
        :value="value && allowCreation"
        :headline="$t('classMgmt.createClassHeader')"
        @closeDialogs="closeDialogs()"
    >
        <form @submit.prevent="createClass(newClass)">
            <v-card-text>
                <v-text-field
                    v-model="newClass.name"
                    label="Class Name"
                    v-validate="'required'"
                    name="class name"
                    :error-messages="errors.collect('class name')"
                ></v-text-field>
                <v-autocomplete
                    v-if="otherTeachers.length || loadingTeachers"
                    v-model="newClass.teachers"
                    :items="otherTeachers"
                    item-value="id"
                    :item-text="function(teacher){return teacher.lastName+', '+teacher.firstName}"
                    label="Additional Teachers"
                    chips
                    multiple
                ></v-autocomplete>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="loadingTeachers" color="primary" class="elevation-0" type="submit">
                    <v-icon v-show="!loadingTeachers">note_add</v-icon>
                    <v-icon v-show="loadingTeachers">fas fa-circle-notch fa-spin</v-icon>
                    &nbsp;{{$t('classMgmt.addClassButton')}}
                </v-btn>
            </v-card-actions>
        </form>
    </UserManagmentModal>
</template>

<script>
    import UserManagmentModal from 'components/layout/UserManagmentModal'

	export default {
        name: 'CreateClassModal',
        components: { UserManagmentModal },
        props: {
            allowCreation: Boolean,
            auth: Object,
            institutionID: Number,
            isNewClassCCActive: Boolean,
            loadingTeachers: Boolean,
            otherTeachers: Array,
            value: Boolean,
        },
        data() {
            return {
                newClass: {},
            }
        },
		computed : {
            platform(){
                 return this.$store.state.platform
            }
        },
        watch: {
            value(val){
                if(!this.allowCreation){
                    this.$emit("classCapReached")
                    this.closeDialogs()
                }
                if (val){
                    this.newClass = {}
                }
            }
        },
        methods: {
            async createClass(newClass){
                var valid = await this.$validator.validateAll()
                if(valid){
                    let institutionId = this.institutionID
                    let productId = this.productId
                    let ownerId = this.auth.claims.logID
                    let name = newClass.name
                    let teacherIds = newClass.teachers
                    let isClassCodeActive = true
                    let isClassCodeEnabled = this.isNewClassCCActive
                    let classId = await this.platform.createClass({institutionId, productId, ownerId, name, isClassCodeEnabled, isClassCodeActive})
                    if(teacherIds){
                        await this.platform.addTeachersToClass({classId, teacherIds})
                    }
                    await this.$emit("updateModel")
                    await this.$emit("switchClass", classId)
                    this.closeDialogs()
                }
            },
            closeDialogs() {
                this.$emit('closeDialogs')
            },
        }
	}
</script>

<style lang="less" scoped>
</style>
