<template>
	<div class="legal">
        <!-- original text taken from https://www.lexialearning.com/california-do-not-sell-my-personal-information-page -->
		<span class="center"><Headline headline="California Do Not Sell My Personal Information Page"></Headline></span>

		<p>This page applies to “Consumers” residing in California as defined by the <i>California Consumer Privacy Act (CCPA)</i>.</p>
		
		<p>If you reside in California, we are required to provide additional information to you about how we use and disclose your information. We have included this California-specific information in our Privacy Policy, accessible <router-link :to="{name: 'Privacy'}">here</router-link> or using the link below.</p>

		<div class="title">Cookies</div>
        
		<p>When you visit any web site, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience.</p>

		<p>You can review our cookie policy on our <router-link :to="{name: 'Privacy'}">privacy page</router-link>.</p>

		<p>You can also disable the use of cookies via your browser, but please note that our website may no longer work properly.</p>

		<p>If you are a California resident and want to exercise the right to opt out of the sale of your personal information to third parties or want to exercise other rights granted to California residents, please notify us at <a href="mailto:support@explorelearning.com">support@explorelearning.com</a>.</p>

		<p>We will not ask you to create an account in order to complete the opt-out process, and we will use information gathered during the opt-out process from you for the purpose of complying with the opt-out request.</p>

		<!-- <p class="text-xs-right">Last Updated: March 5th, 2021</p> -->
	</div>
</template>

<script>
	import Headline from 'components/layout/Headline'
	export default {
		name: 'DoNotSellMyInformation',
		metaInfo : {
			title : 'Do Not Sell'
		},
		components: { Headline },
		computed: {
			friendlyURL(){
				return this.productName+'Math.com'
			},
			productName(){
				let product = this.currentEnvironment
				return product.charAt(0).toUpperCase() + product.slice(1)
			},
			showCerts(){
				if(this.getEnvironment()=='frax'){
					return false
				} else {
					return true
				}
			},
			supportEmail(){
				return 'support@'+this.friendlyURL
			}
		}
	}
</script>


<style lang="less">
	.cert {
		width: 100px;
		display: inline-block;
	}
	.cert-text {
		padding-top: 25px;
		padding-left: 30px;
    }
    .center {
        text-align: center;
    }

	@media screen and (max-width: 1264px) {
		.cert {
		width: 85px;
		}
		.cert-text {
			padding-top: 10px;
			padding-left: 10px;
		}
	}
	@media screen and (max-width: 866px) {
		.cert {
		width: 75px;
		}
		.cert-text {
			padding-top: 2px;
			padding-left: 0;
		}
	}
	@media screen and (max-width: 790px) {
		.cert {
		width: 70px;
		}
		.cert-text {
			padding: 0;
		}
	}
	@media screen and (max-width: 760px) {
		.cert {
		width: 60px;
		}
		.cert-text {
			padding: 0;
		}
	}
	@media screen and (max-width: 690px) {
		.cert {
		width: 50px;
		}
	}
	@media screen and (max-width: 600px) {
		.cert {
		width: 100px;
		}
	}
	@media screen and (max-width: 475px) {
		.cert {
		width: 70px;
		}
	}
</style>
