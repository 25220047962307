<template>
    <div class="mb-5">
        <v-layout id="homeFooter" :style="{'max-width':width+'px'}">
            <a :href="getEducatorResourceHubURL()" target="_blank">{{$t('home.teacherHub')}}</a>
        </v-layout>
    </div>
</template>

<script>
    import APIs from 'mixins/APIs'
	export default {
		name: 'HomeFooter',
        mixins: [APIs],
		computed : {
            width(){
				return this.$route.meta.cardWidth
			}
        }
	}
</script>

<style lang="less" scoped>
    #homeFooter {
        margin: 0 auto;
        font-size: 1.1em;
        a {
            text-decoration: none;
            font-weight: 600;
            margin: 0 auto;
        }
    }
</style>
