<template>
	<v-flex>
        <v-layout wrap>
            <Headline :headline="$t('trial.existingHeader')"></Headline>
        </v-layout>
        <v-layout v-if="isAdmin" wrap>
            <v-flex xs12>
					<p>{{$t('regCode.otherAccount')}}</p>
            </v-flex>
            <v-flex xs12>
                <v-btn @click="logout()" color="primary" large block>
                    {{$t('regCode.loginTeacher')}}
                </v-btn>
            </v-flex>
        </v-layout>
        <v-layout v-else-if="institutions.length<=1" wrap>
             <v-flex xs12>
                <Messaging outline></Messaging>
            </v-flex>
            <v-flex>
                {{$t('trial.hold')}}
            </v-flex>
        </v-layout>
        <v-layout v-else wrap>
             <v-flex xs12>
                <Messaging outline></Messaging>
            </v-flex>
            <v-flex xs12>
                <v-btn 
                    v-for="institution in institutions"
                    :key="institution.institutionID"
                    @click="linkTrialToInstitution(institution.institutionID)"
                    color="primary"
                    large
                    block
                    class="mb-4"
                >
                    {{institution.name}}
                </v-btn>
            </v-flex>
        </v-layout>
    </v-flex>
</template>

<script>
    import APIs from 'mixins/APIs'
    import Constants  from 'mixins/Constants'
    import Forwarding from 'mixins/Forwarding'
    import Headline from 'components/layout/Headline'
    import LoginDetector from 'mixins/LoginDetector'
    import Messaging from 'components/layout/Messaging'
    import Tracking from 'mixins/Tracking'
    import EnvironmentDetector from 'mixins/EnvironmentDetector'
    import { mapGetters, mapActions } from 'vuex'
	export default {
		name: 'TrialExisting',
		metaInfo : {
			title : 'Existing User'
		},
        components  : {Headline, Messaging},
        mixins : [APIs, Constants, Forwarding, LoginDetector, Tracking, EnvironmentDetector],
        data () {
			return {
                email: null,
				institutions : [],
                regCode: null
			}
        },
        computed: {
            ...mapGetters('platformData/profile', ['isAdmin', 'profile']),
        },
        methods : {
            ...mapActions('platformData/app', ['fetchModel', 'updateAuthClaims']),
            async linkTrialToInstitution(institutionID){
                if(!this.isAdmin){
                    let trialInfo = {
                        firstName : this.profile.firstName,
                        lastName : this.profile.lastName,
                        email : this.profile.email,
                        formInfo : this.addTrackingInfo()
                    }
                    await this.$http.post(this.getTrialCreationAPI(institutionID, this.regCode), trialInfo, this.jtiHeader).then(async response=>{
                        let userInfo = this.decodeXclaims(response)
                        await this.updateAuthClaims({ claims: {...this.claims, jti: userInfo.jti} })
                        this.$store.commit('setJTI', userInfo.jti)
			            const isFree = userInfo.productMap[this.getProductCode()].free
                        let accountType = 'trial'
                        if(isFree){
                            accountType = 'free access'
                        }
                        if(typeof window.dataLayer != 'undefined'){
                            var dataObject = {
                                event : 'trial_2b',
                                product : this.currentEnvironment,
                                category : 'cross_sell'
                            }
                            window.dataLayer.push(dataObject);
                        }
                        await this.fetchModel()
                        this.$router.push({name:'Home', params:{newSub:{type:accountType,productIDs:[this.getProductCode()]}}})
                    },error=>{
                        let errorCode = error.response.status
                        if(errorCode == 400){
                            this.$router.push({name:'Subscriptions', query:{e:'existingSub'}})
                        } else {
                            this.$store.commit('setMessageError', error.response.data.MessageResponse.message)
                        }
                    })
                }
            },
            async logout(){
				this.$http.get(this.getLogoutAPI(),this.jtiHeader).then(response => {
                    this.$store.commit('setMessageInfo', null)
                    this.$store.commit('setForwardingURL', {name:'TrialRegExisting'})
				    this.$router.push({name:'Login', params: {userType:'educator', flowOverride:'reg'}})
				}, error => {
					console.log(error.response)
				})
			}
        },
        watch: {
            email(val){
                if(val && !this.isAdmin){
                    this.$http.get(this.getModelAPI(),this.jtiHeader).then(response=>{
                        let institutionList = response.data.AdminClientModel.institutionList
                        if(institutionList.length==1){
                            let institutionID = institutionList[0].institutionID
                            this.linkTrialToInstitution(institutionID)
                        } else {
                            this.$store.commit('setMessageInfo', this.$t('regCode.chooseSchool'))
                            this.institutions = institutionList
                        }
                    }, error => (
                        console.log(error)
                    ))
                }
            }
        },
        async created(){
            if(this.$route.params.regCode){
                this.regCode = this.$route.params.regCode
            }
            if (this.profile.email) {
                this.email = this.profile.email
            }
        }
    }
</script>
