<template>
    <UserManagmentModal
        :value="value"
        :headline="editedStudent.firstName + ' ' + editedStudent.lastName"
        :size="'550'"
        @closeDialogs="closeDialogs()"
    >
        <v-list v-if="!isEmpty(editedStudent)">
            <v-subheader>
                {{$t('classMgmt.productsAndAssignments')}}
                <span v-if="isAdmin" class="caption ml-2">({{$t('classMgmt.adminInstructions')}})</span>
            </v-subheader>
            <v-flex mb-2>
                <ProfileProductList
                    @openProductSpecificAssignmentModal="openProductSpecificAssignmentModal"
                    :altLicenseSource="altLicenseSource[student[0].id]"
                    :ownedProducts="ownedProducts"
                    :user="editedStudent"
                    :productColorClass="productColorClass"
                    :productImgPath="productImgPath"
                    :subscriptions="subscriptions"
                    :checkProductUpgrade="checkProductUpgrade"
                    :determineAssignment="determineAssignment"
                    :editable="!isAdmin"
                ></ProfileProductList>
            </v-flex>
            <v-divider></v-divider>
            <v-subheader>
                <v-icon v-if="editedStudent.isRostered">sync_alt</v-icon>
                <span v-if="editedStudent.isRostered">&nbsp;{{$t('classMgmt.rostered')}}&nbsp;</span>
                {{$t('classMgmt.profile')}}
            </v-subheader>
            <form @submit.prevent="updateStudentProfile(editedStudent,editedStudentInfo)">
                <v-flex v-if="editStudentModalEditMode" class="mx-4 mb-3">
                    <Messaging outline></Messaging>
                </v-flex>
                <v-list-tile avatar>
                    <v-list-tile-avatar>
                        <v-icon>perm_identity</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content v-if="editStudentModalEditMode">
                        <v-layout wrap>
                            <v-flex xs4 class="pr-1">
                                <v-text-field
                                    v-model="editedStudentInfo.firstName"
                                    label="First Name *"
                                    v-validate="'required|max:30|noEscapes'"
                                    name="first name"
                                    :error-messages="errors.collect('first name')"
                                    hide-details
                                    data-vv-validate-on="change"
                                    :disabled="editedStudent.isRostered"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs4 class="pr-1">
                                <v-text-field
                                    v-model="editedStudentInfo.middleName"
                                    label="Middle Name"
                                    v-validate="'max:30|noEscapes'"
                                    name="middle name"
                                    placeholder=" "
                                    :error-messages="errors.collect('middle name')"
                                    hide-details
                                    data-vv-validate-on="change"
                                    :disabled="editedStudent.isRostered"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs4>
                                <v-text-field
                                    v-model="editedStudentInfo.lastName"
                                    label="Last Name *"
                                    v-validate="'required|max:30|noEscapes'"
                                    name="last name"
                                    :error-messages="errors.collect('last name')"
                                    hide-details
                                    data-vv-validate-on="change"
                                    :disabled="editedStudent.isRostered"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-list-tile-content>
                    <v-list-tile-content v-else>
                        <v-list-tile-sub-title>{{$t('classMgmt.fullName')}}</v-list-tile-sub-title>
                        <v-list-tile-title>
                            {{editedStudent.firstName}}&nbsp;<span v-if="editedStudent.middleName">{{editedStudent.middleName}}&nbsp;</span>{{editedStudent.lastName}}
                        </v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
                <v-list-tile avatar v-if="isClassCodeEnabledClass">
                    <v-list-tile-avatar>
                        <v-icon>badge</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content v-if="editStudentModalEditMode">
                        <v-layout wrap class="fullwidth">
                            <v-flex>
                                <v-text-field
                                    v-model="editedStudentInfo.userName"
                                    label="Username *"
                                    v-validate="'required|max:50'"
                                    name="username"
                                    :error-messages="errors.collect('username')"
                                    :disabled="editedStudent.isRostered"
                                    hide-details
                                    data-vv-validate-on="change"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-list-tile-content>
                    <v-list-tile-content v-else>
                        <v-layout  wrap class="fullwidth">
                            <v-flex>
                                <v-list-tile-sub-title>{{$t('form.userName')}}</v-list-tile-sub-title>
                                <v-list-tile-title>{{editedStudent.username}}</v-list-tile-title>
                            </v-flex>
                        </v-layout>
                    </v-list-tile-content>
                </v-list-tile>
                <v-list-tile avatar v-else>
                    <v-list-tile-avatar>
                        <v-icon>badge</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content v-if="editStudentModalEditMode">
                        <v-layout wrap class="fullwidth">
                            <v-flex>
                                <v-text-field
                                    v-model="editedStudentInfo.sisID"
                                    label="Student ID *"
                                    v-validate="'required|max:50'"
                                    name="student ID"
                                    :error-messages="errors.collect('student ID')"
                                    :disabled="editedStudent.isRostered"
                                    hide-details
                                    data-vv-validate-on="change"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-list-tile-content>
                    <v-list-tile-content v-else>
                        <v-layout  wrap class="fullwidth">
                            <v-flex>
                                <v-list-tile-sub-title>{{$t('form.studentID')}}</v-list-tile-sub-title>
                                <v-list-tile-title>{{editedStudent.sisID}}</v-list-tile-title>
                            </v-flex>
                        </v-layout>
                    </v-list-tile-content>
                </v-list-tile>
                <v-list-tile avatar>
                    <v-list-tile-avatar>
                        <v-icon>security</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content v-if="editStudentModalEditMode">
                        <v-layout wrap class="fullwidth">
                            <v-flex>
                                <v-text-field
                                    v-model="editedStudentInfo.password"
                                    :label="$t('classMgmt.studentPassword')+' *'"
                                    v-validate="'required|max:30|noDiacritics'"
                                    name="student password"
                                    :error-messages="errors.collect('student password')"
                                    hide-details
                                    data-vv-validate-on="change"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-list-tile-content>
                    <v-list-tile-content v-else>
                        <v-layout wrap class="fullwidth">
                            <v-flex>
                                <v-list-tile-sub-title>{{$t('classMgmt.studentPassword')}}</v-list-tile-sub-title>
                                <v-list-tile-title>{{editedStudent.password}}</v-list-tile-title>
                            </v-flex>
                        </v-layout>
                    </v-list-tile-content>
                </v-list-tile>
                <v-list-tile avatar>
                    <v-list-tile-avatar>
                        <v-icon>school</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content v-if="editStudentModalEditMode">
                        <v-layout wrap class="fullwidth">
                            <v-flex :xs6="isAdmin" :class="{'pr-1':isAdmin}">
                                <v-select
                                    v-model="editedStudentInfo.gradeID"
                                    :items="gradeLevelIDs"
                                    item-text="label"
                                    item-value="id"
                                    :label="$t('classMgmt.gradeLevel')+' *'"
                                    v-validate="'required'"
                                    name="grade level"
                                    :error-messages="errors.collect('grade level')"
                                    :disabled="editedStudent.isRostered"
                                    hide-details
                                    data-vv-validate-on="change"
                                ></v-select>
                            </v-flex>
                            <v-flex v-if="isAdmin" xs6>
                                <v-select
                                    v-model="editedStudentInfo.specialEdID"
                                    :items="yesNoNA"
                                    item-text="label"
                                    item-value="id"
                                    label="Special Ed Status"
                                ></v-select>
                            </v-flex>
                        </v-layout>
                    </v-list-tile-content>
                    <v-list-tile-content v-else>
                        <v-layout  wrap class="fullwidth">
                            <v-flex>
                                <v-list-tile-sub-title>{{$t('classMgmt.gradeLevel')}}</v-list-tile-sub-title>
                                <v-list-tile-title>{{getGradeLevel(editedStudent.gradeID)}}</v-list-tile-title>
                            </v-flex>
                            <v-flex v-if="isAdmin" xs6>
                                <v-list-tile-sub-title>{{$t('classMgmt.specialEdStatus')}}</v-list-tile-sub-title>
                                <v-list-tile-title>{{getYesNoNA(editedStudent.specialEdID)}}</v-list-tile-title>
                            </v-flex>
                        </v-layout>
                    </v-list-tile-content>
                </v-list-tile>
                
                <v-list-tile avatar>
                    <v-list-tile-avatar>
                        <v-icon>language</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content v-if="editStudentModalEditMode">
                        <v-layout wrap class="fullwidth">
                            <v-flex :xs6="isAdmin" :class="{'pr-1':isAdmin}">
                                <v-select
                                    v-model="editedStudentInfo.languageID"
                                    :items="languages"
                                    item-text="label"
                                    item-value="id"
                                    label="Language"
                                >
                                    <template #append>
                                        <LanguageToolTip small></LanguageToolTip>
                                    </template>
                                </v-select>
                            </v-flex>
                            <v-flex xs6 v-if="isAdmin">
                                <v-select
                                    v-model="editedStudentInfo.engProfID"
                                    :items="yesNoNA"
                                    item-text="label"
                                    item-value="id"
                                    label="Limited English Proficiency"
                                ></v-select>
                            </v-flex>
                        </v-layout>
                    </v-list-tile-content>
                    <v-list-tile-content v-else>
                        <v-layout wrap class="fullwidth">
                            <v-flex xs6>
                                <v-list-tile-sub-title>{{$t('classMgmt.language')}} <LanguageToolTip small></LanguageToolTip></v-list-tile-sub-title>
                                <v-list-tile-title>{{getLanguage(editedStudent.languageID)}}</v-list-tile-title>
                            </v-flex>
                            <v-flex xs6 v-if="isAdmin">
                                <v-list-tile-sub-title>{{$t('classMgmt.limitedEnglishProficiency')}}</v-list-tile-sub-title>
                                <v-list-tile-title>{{getYesNoNA(editedStudent.engProfID)}}</v-list-tile-title>
                            </v-flex>
                        </v-layout>
                    </v-list-tile-content>
                </v-list-tile>
                <v-list-tile avatar v-if="isAdmin">
                    <v-list-tile-avatar>
                        <v-icon>category</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content v-if="editStudentModalEditMode">
                        <v-layout wrap class="fullwidth">
                            <v-flex xs6 class="pr-1">
                                <v-select
                                    v-model="editedStudentInfo.genderID"
                                    :items="genders"
                                    item-text="label"
                                    item-value="id"
                                    label="Gender"
                                ></v-select>
                            </v-flex>
                            <v-flex xs6>
                                <v-select
                                    v-model="editedStudentInfo.ethnicityID"
                                    :items="ethnicities"
                                    item-text="label"
                                    item-value="id"
                                    label="Race / Ethnicity"
                                    class="text-no-wrap text-truncate"
                                ></v-select>
                            </v-flex>
                        </v-layout>
                    </v-list-tile-content>
                    <v-list-tile-content v-else>
                        <v-layout wrap class="fullwidth">
                            <v-flex xs6>
                                <v-list-tile-sub-title>{{$t('classMgmt.gender')}}</v-list-tile-sub-title>
                                <v-list-tile-title>{{getGender(editedStudent.genderID)}}</v-list-tile-title>
                            </v-flex>
                                <v-flex xs6>
                                <v-list-tile-sub-title>{{$t('classMgmt.raceEthnicity')}}</v-list-tile-sub-title>
                                <v-list-tile-title>{{getEthnicity(editedStudent.ethnicityID)}}</v-list-tile-title>
                                </v-flex>
                        </v-layout>
                    </v-list-tile-content>
                </v-list-tile>
                <v-list-tile avatar v-if="isAdmin">
                    <v-list-tile-avatar>
                        <v-icon>attach_money</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content v-if="editStudentModalEditMode">
                        <v-layout wrap class="fullwidth">
                            <v-flex xs12>
                                <v-select
                                    v-model="editedStudentInfo.disadvID"
                                    :items="yesNoNA"
                                    item-text="label"
                                    item-value="id"
                                    label="Economically Disadvantaged"
                                ></v-select>
                            </v-flex>
                        </v-layout>
                    </v-list-tile-content>
                    <v-list-tile-content v-else>
                        <v-list-tile-sub-title>{{$t('classMgmt.economicallyDisadvantaged')}}</v-list-tile-sub-title>
                        <v-list-tile-title>{{getYesNoNA(editedStudent.disadvID)}}</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
            </form>
        </v-list>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn v-if="!isAllClasses && !editedStudent.isRostered && !editStudentModalEditMode" color="error" flat @click="openDeleteUserDialog(editedStudent)" :disabled="editStudentModalEditMode" class="elevation-0">
                <span class="mr-1">
                    <v-icon>person_remove</v-icon>
                </span>
                &nbsp;{{deleteBtnTxt}}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="editStudentModalEditMode" color="primary" flat @click="exitStudentModalEditMode()">
                {{$t('classMgmt.cancel')}}
            </v-btn>
            <v-btn v-if="editStudentModalEditMode" color="primary px-3" class="elevation-0" @click="updateStudentProfile(editedStudent,editedStudentInfo)">
                <v-icon>check</v-icon>&nbsp;{{$t('classMgmt.save')}}
            </v-btn>
            <v-btn v-else color="primary" @click="enterStudentEditMode(editedStudent)">
                <v-icon>edit</v-icon>&nbsp;{{$t('classMgmt.editProfile')}}
            </v-btn>
        </v-card-actions>
    </UserManagmentModal>
</template>

<script>
    import APIs from 'mixins/APIs'
    import Constants from 'mixins/Constants'
    import LanguageToolTip from 'components/classMgmt/LanguageTooltip'
    import Messaging from 'components/layout/Messaging'
    import ProfileProductList from 'components/layout/ProfileProductList'
    import UserManagmentModal from 'components/layout/UserManagmentModal'
    import { mapActions } from 'vuex'

	export default {
        name: 'EditStudentModal',
        components: { LanguageToolTip, Messaging, ProfileProductList, UserManagmentModal },
        mixins: [APIs, Constants],
        props: {
            altLicenseSource: {
                type: Object,
                default(){
                    return {}
                }
            },
            checkProductUpgrade: Function,
            determineAssignment: Function,
            institutionID: Number,
            isAllClasses: Boolean,
            isClassCodeEnabledClass: Boolean,
            multiProduct: Boolean,
            ownedProducts: Array,
            selectedClassID: Number,
            student: Array,
            subscriptions: Array,
            tenantID: Number,
            value: Boolean,
            hasProductSubscription: Function,
        },
        data() {
            return {
                editedStudent: {},
                editedStudentInfo : {gradeID:0},
                editStudentModalEditMode : false,
            }
        },
		computed : {
            deleteBtnTxt(){
                if(this.isAdmin){
                    return this.$t('classMgmt.delete')
                } else {
                    return this.$t('classMgmt.removeFromClass')

                }
            },
            isAdmin(){
                return this.$store.getters["platformData/profile/isAdmin"]
            },
            platform(){
                 return this.$store.state.platform
            }
        },
        methods: {
            ...mapActions('platformData/students', ['updateStudents']),
            closeDialogs() {
                this.$emit('closeDialogs')
                this.exitStudentModalEditMode()
            },
            enterStudentEditMode(student){
                this.editedStudentInfo.firstName = student.firstName
                this.editedStudentInfo.middleName = student.middleName
                this.editedStudentInfo.lastName = student.lastName
                this.editedStudentInfo.gradeID = student.gradeID
                this.editedStudentInfo.sisID = student.sisID
                this.editedStudentInfo.userName = student.username
                this.editedStudentInfo.password = student.password
                this.editedStudentInfo.languageID = student.languageID
                this.editedStudentInfo.specialEdID = student.specialEdID
                this.editedStudentInfo.engProfID = student.engProfID
                this.editedStudentInfo.genderID = student.genderID
                this.editedStudentInfo.ethnicityID = student.ethnicityID
                this.editedStudentInfo.disadvID = student.disadvID
                this.editStudentModalEditMode = true
            },
            exitStudentModalEditMode(){
                this.editStudentModalEditMode = false
                this.editedStudentInfo = {gradeID:0}
            },
            isEmpty(obj) {
                return Object.keys(obj).length === 0;
            },
            openDeleteUserDialog(users){
                this.$emit('openDialog', 'deleteConfirmModal', {users})
            },
            openProductSpecificAssignmentModal(productID, users){
                this.$emit('openProductSpecificAssignmentModal', productID, users, false)
            },
            setup(){
                this.editedStudent = this.student[0]
                this.editStudentModalEditMode = false
            },
            async updateStudentProfile(student,fieldsToChange){
                var valid = await this.$validator.validateAll()
                if(valid){
                    student.firstName = fieldsToChange.firstName
                    student.middleName = fieldsToChange.middleName
                    student.lastName = fieldsToChange.lastName
                    student.gradeId = fieldsToChange.gradeID
                    student.sisUserId = fieldsToChange.sisID
                    student.username = fieldsToChange.userName
                    student.passwordClear = fieldsToChange.password
                    student.password = fieldsToChange.password
                    student.languageId = fieldsToChange.languageID
                    student.specialEdId =  fieldsToChange.specialEdID
                    student.engProfId =  fieldsToChange.engProfID
                    student.genderId =  fieldsToChange.genderID
                    student.ethnicityId =  fieldsToChange.ethnicityID
                    student.disadvId = fieldsToChange.disadvID
                    if(typeof student.reflexJson == 'object'){
                        student.reflexJson = JSON.stringify(student.reflexJson)
                    }
                    let students = [student]
                    let institutionId = this.institutionID
                    let tenantId = this.tenantID
                    let self = this;
                    (async function() {
                        try {
                            await self.updateStudents({institutionId, tenantId, students})
                        } catch(error){
                            console.log(error)
                            if(error.message.indexOf('406')){
                                const msg = self.editedStudent.isClassCodeEnabled ? self.$t('error.usernameInUse') : self.$t('error.idInUse')
                                self.$store.commit('setMessageError', msg)
                            }
                            return
                        }
                        self.$emit("updateModel")
                        self.exitStudentModalEditMode()
                    })()
                } else {
                    let errorMsg = ''
                    this.$validator.errors.items.forEach(error=>{
                        errorMsg = errorMsg + error.msg + ' '
                    })
                    if(errorMsg){
                        this.$store.commit('setMessageError',errorMsg)
                    }
                }
            },
        },
        watch: {
            value(val) {
                if (val){
                    this.setup()
                }
            },
            student(){
                this.setup()
            }
        },
        created(){
            this.setup()
        }
	}
</script>

<style lang="less" scoped>
</style>