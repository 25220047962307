import Forwarding from 'mixins/Forwarding'
export default {
	mixins: [Forwarding],
	methods: {
		displayErrorMsg : function(message){
			this.$store.commit('setMessageError', message)
		},
		expiredCredentials : function(){
			this.$store.commit('setForwardingURL', {name: this.$route.name})
			this.$router.push({name:'Login', params: {userType:'educator'}})
		},
		getErrorID : function(response){
			// New Error IDs
			if(response.data && response.data.hasOwnProperty('id')) {
				return response.data.id
			} else if(response.data && response.data.MessageResponse && response.data.MessageResponse.hasOwnProperty('id')) {
				return response.data.MessageResponse.id
			} else {
				// Old Style Error IDs
				let colonPosition = response.data.MessageResponse.message.indexOf(':')
				if(colonPosition>=0){
					return response.data.MessageResponse.message.substring(0,colonPosition)
				} else {
					// New Style Error IDs
					if(response.data.MessageResponse.id){
						return response.data.MessageResponse.id
					} else {
						return null
					}
				}
			}
		},
		getErrorMsg : function(errorID){
			var errorLookup = 'error.error-'+errorID
			if(this.$t(errorLookup)!==errorLookup){
				return this.$t(errorLookup)
			} else {
				return this.$t('error.unknown')
			}
		},
		handleError : function(response, hideIDs=[]){
			if(response == undefined || response.status == undefined ||  response.status == 404 || response.status == 0){
				let errorMsg = this.$t('error.404')
				this.displayErrorMsg(errorMsg)
			} else {
				var errorID = this.getErrorID(response)
				var errorMsg = this.getErrorMsg(errorID)
				if(hideIDs.indexOf(errorID)==-1){
					this.displayErrorMsg(errorMsg)
				}
				return {errorID, errorMsg}
			}
		}
	}
}
