<template>
	<v-flex>
		<v-layout wrap mb-2 mt-4>
			<v-flex xs2 sm1 px-3>
	      		<v-icon color="green">check</v-icon>
	    	</v-flex>
	    	<v-flex xs10 sm11 class="subheading">
	      		<span>{{$t('grant.term0-0',{product:productName})}}&nbsp;<a target="_blank" :href="systemRequirmentsURL">{{$t('grant.term0-1')}}</a>&nbsp;{{$t('grant.term0-2')}}</span>
			</v-flex>
		</v-layout>
		<v-layout wrap my-2>
			<v-flex xs2 sm1 px-3 pt-1>
	      		<v-icon color="green">check</v-icon>
	    	</v-flex>
	    	<v-flex xs10 sm11 class="subheading">
	      		<span>{{$t('grant.term1')}}&nbsp;<router-link target="_blank" :to="{name:'Terms'}">{{$t('footer.terms')}}</router-link>.</span>
			</v-flex>
		</v-layout>
		<v-layout wrap my-2>
			<v-flex xs2 sm1 px-3 pt-1>
	      		<v-icon color="green">check</v-icon>
	    	</v-flex>
	    	<v-flex xs10 sm11 class="subheading">
	      		{{$t('grant.term2')}}
			</v-flex>
		</v-layout>
		<v-layout v-if="productID==1"  wrap my-2>
			<v-flex xs2 sm1 px-3 pt-1>
	      		<v-icon color="green">check</v-icon>
	    	</v-flex>
	    	<v-flex xs10 sm11 class="subheading">
	      		{{$t('grant.term3')}}
			</v-flex>
		</v-layout>
		<v-layout wrap my-2>
			<v-flex xs2 sm1 px-3 pt-1>
				<v-icon color="green">check</v-icon>
			</v-flex>
			<v-flex xs10 sm11 class="subheading">
				{{$t('grant.term4')}}
			</v-flex>
		</v-layout>
		<v-layout wrap my-2>
			<v-flex xs2 sm1 px-3 pt-1>
				<v-icon color="green">check</v-icon>
			</v-flex>
			<v-flex xs10 sm11 class="subheading">
				{{$t('grant.term5')}}
			</v-flex>
		</v-layout>
		<v-layout wrap mt-2>
			<v-flex xs2 sm1 px-3 pt-1>
				<v-icon color="green">check</v-icon>
			</v-flex>
			<v-flex xs10 sm11 class="subheading">
				{{$t('grant.term6')}}
			</v-flex>
		</v-layout>
		<v-layout wrap mt-2>
			<v-flex xs2 sm1 px-3 pt-1>
				<v-icon color="green">check</v-icon>
			</v-flex>
			<v-flex xs10 sm11 class="subheading">
				{{$t('confirm.coppaAgree',{product:productName})}}
			</v-flex>
		</v-layout>
		<v-layout wrap mb-5>
			<v-flex xs10 offset-xs2 sm11 offset-sm1>
				<COPPA :productName="productName"></COPPA>
			</v-flex>
		</v-layout>
	</v-flex>
</template>

<script>
	import COPPA from 'components/legal/COPPA'
	import ProductInfo from 'mixins/ProductInfo'
	export default {
		name: 'GrantTerms',
		metaInfo : {
            title : 'Grant Terms'
        },
		components: { COPPA },
		mixins: [ProductInfo],
		props: {
			productID: Number,
		},
		computed : {
			productName(){
				return this.productDisplayName(this.productID)
			},
			systemRequirmentsURL(){
				return this.productSystemRequirementsURL(this.productID)
			}
		}
	}
</script>
