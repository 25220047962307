<template>
    <v-flex v-if="dataLoaded" class="ma-0 pa-0">
        <v-flex v-if="subscriptions.length" class="ma-0 pa-0">
            <v-layout wrap v-if="institutionList.length">
                <v-flex xs12 class="mb-2" v-if="classes.length>1 && institutionList.length<2"><div class="subheading">{{ myClassesText }}</div></v-flex>
                <v-menu xs11 sm8 lg9 v-model="classDropdown" max-width="400" bottom nudge-bottom="30" nudge-right="4">
                    <template #activator="{}">
                        <Headline
                            :headline="selectedClassName"
                            :flowOverride="(institutionList.length>1?selectedSchool.name:'none')"
                            :flowDropdown="institutionList.length>1"
                            dropdown
                            v-on:dropdown-click="classDropdown=true"
                            v-on:flow-click="institutionDropdown=true"                            
                            :icon="(selectedClassIsRostered?'sync_alt':'')"
                            color="#1976D2"
                        >
                        </Headline>
                    </template>
                    <v-card max-height="600" class="whiteBG">
                        <v-list class="py-0">
                            <v-subheader>
                                {{$tc('classMgmt.class', localizationConstants.MULTIPLE).toUpperCase()}}
                                <v-spacer/>
                                <span v-if="hasClassCap && classesCountedTowardsClassCap.length / classCap <= 1">
                                    {{classesCountedTowardsClassCap.length}} of {{classCap}}
                                    <v-tooltip top>
                                        <template #activator="{ on }">
                                            <v-icon v-on="on" small color="blue">help</v-icon>
                                        </template>
                                        <span v-if="isSelectedSchoolRostered">{{$t('classMgmt.classCapExplainedRostered',{cap:classCap})}}</span>
                                        <span v-else>{{$t('classMgmt.classCapExplained',{cap:classCap})}}</span>
                                    </v-tooltip>
                                </span>
                            </v-subheader>
                            <v-list-tile avatar v-for="c in classes" v-if="c.id>=0" :key="'class-'+c.id" @click="switchClass(c.id)" :class="{'selectedClass':selectedClassID==c.id}">
                                <v-list-tile-content>
                                    <v-list-tile-title>
                                        {{c.name}}
                                    </v-list-tile-title>
                                    <!-- <v-list-tile-sub-title v-if="hasClassCap && c.ownerId != currentUserID">
                                        Owner:
                                        <span v-if="getTeachers([c.ownerId])[0].firstName">
                                            {{getTeachers([c.ownerId])[0].firstName}} {{getTeachers([c.ownerId])[0].lastName}}
                                        </span>
                                        <span v-else>
                                            Undefined
                                        </span>
                                    </v-list-tile-sub-title> -->
                                </v-list-tile-content>
                                <v-list-tile-avatar v-if="c.isRostered" class="text-xs-right blue--text">
                                    <v-tooltip top>
                                        <template #activator="{ on }">
                                            <v-icon v-on="on">sync_alt</v-icon>
                                        </template>
                                        <span>{{$t('classMgmt.rosteredClass')}}</span>
                                    </v-tooltip>
                                </v-list-tile-avatar>
                                <v-list-tile-avatar v-if="c.studentIDs" class="text-xs-right blue--text">
                                    <span v-if="c.studentIDs.length<10">&nbsp;&nbsp;</span>{{c.studentIDs.length}}&nbsp;<v-icon color="blue">people</v-icon>
                                </v-list-tile-avatar>
                            </v-list-tile>
                        </v-list>
                        <v-divider class="whiteBG"></v-divider>
                        <v-card-actions class="whiteBG">
                            <v-btn v-if="classes.length>2 && students.length" flat @click="switchClass(-1)">{{$t('classMgmt.all')}}</v-btn>
                            <v-spacer></v-spacer>
                            <v-tooltip top :disabled="!classCapReached">
                                <template #activator="{ on }">
                                    <span v-on="on">
                                        <v-btn 
                                            flat 
                                            color="blue" 
                                            @click="openDialog('createClassModal')"
                                            :disabled=classCapReached
                                        >
                                            <v-icon>note_add</v-icon>&nbsp;{{$t('classMgmt.addClass')}}
                                        </v-btn>
                                    </span>
                                </template>
                                <span v-if="isSelectedSchoolRostered">{{$t('classMgmt.classCapReachedRostered',{cap:classCap})}}</span>
                                <span v-else>{{$t('classMgmt.classCapReached',{cap:classCap})}}</span>
                            </v-tooltip>
                        </v-card-actions>
                    </v-card>
                </v-menu>
                <v-menu v-if="institutionList.length>1" xs11 sm8 lg9 v-model="institutionDropdown" activator="#headline" max-width="400" bottom nudge-bottom="16" nudge-right="4">
                    <v-card max-height="600" class="whiteBG">
                        <v-list class="py-0">
                            <v-subheader>{{$tc('classMgmt.school', localizationConstants.MULTIPLE)}}</v-subheader>
                            <v-list-tile avatar v-for="i in institutionList" v-if="i.id>=0" :key="i.id" @click="switchInstitutions(i.id)" :class="{'selectedClass':selectedSchoolID==i.id}">
                                <v-list-tile-avatar>
                                    <v-icon>school</v-icon>
                                </v-list-tile-avatar>
                                <v-list-tile-content>
                                    <v-list-tile-title v-html="i.name"></v-list-tile-title>
                                    <v-list-tile-sub-title v-html="printLocation(i.location)"></v-list-tile-sub-title>
                                </v-list-tile-content>
                            </v-list-tile>
                        </v-list>
                    </v-card>
                </v-menu>             
            </v-layout>
            <v-layout v-if="classes.length > 1 && selectedClass.id >= 0">
                <v-flex xs12 pa-0 mb-4 px-1>
                    <v-btn v-if="!selectedClassIsRostered" id="class_settings_button" block class="elevation-0 grey--text text--darken-2 text-capitalize" @click="openDialog('classSettingsModal')">
                        <v-layout justify-space-between>
                            <v-tooltip top :disabled="selectedClass.teacherIDs.length < 2">
                                <template #activator="{ on }">
                                    <div class="align-self-center text-truncate"  v-on="on">
                                        {{ settingsBarText }}
                                    </div>
                                </template>
                                <span>{{ otherTeachersString }}</span>
                            </v-tooltip>
                            <div class="d-flex">
                                <div 
                                    v-if="isClassCodeEnabledClass" 
                                    class="mr-3 align-self-center"
                                >
                                    Class Code:
                                    <span class="blue--text class-code-link" @click.stop="openDialog('classCodeModal')">{{ `EL${selectedClass.classCode}` }}{{ !isClassCodeActiveClass ? ' (paused)' : ''}}</span>
                                </div>
                                <v-icon class="mr-1" small>settings</v-icon>
                                <span class="align-self-center">{{$t('schoolMgmt.settings')}}</span>  
                            </div>
                        </v-layout>
                    </v-btn>
                    <div v-else id="class_settings_button" class="roster-box-bg py-2 px-3 grey--text text--darken-2 d-flex">
                        <v-layout justify-space-between>
                            <div class="align-self-center text-truncate">
                                {{ settingsBarText }}
                            </div>
                            <div class="d-flex align-self-center">
                                <span v-if="selectedClassIsRostered"><v-icon class="body-2 pb-1">sync_alt</v-icon>&nbsp;</span>
                                <span v-else>{{$t('classMgmt.non')}}</span>{{$t('classMgmt.rosteredClass')}}
                                <RosterModal @click.stop class="ml-2 d-flex"></RosterModal>            
                            </div>
                        </v-layout>
                    </div>
                </v-flex>
            </v-layout>
            <v-layout v-if="classes.length>1 && studentsInSelectedClass.length" justify-space-between px-2 mb-3 wrap>
                <!-- ROSTER and ASSIGNMENT TABS -->
                <v-tabs
                    v-if="!isClassCodeActiveClass"
                    v-model="activeTab"
                    slider-color="blue"
                >
                    <v-tab id="roster_tab" key="roster" ripple>
                        {{$t('classMgmt.roster')}}
                    </v-tab>
                    <v-tab id="assignments_tab" key="assignments" ripple>
                        {{$tc('classMgmt.assignment', localizationConstants.MULTIPLE)}}
                    </v-tab>
                </v-tabs>
                <!-- SEARCH, ADD and PRINT BUTTONS -->
                <v-flex>
                    <v-layout row justify-end wrap>
                        <v-btn id="search_button" :flat="!searchOpen" color="primary" @click="searchToggle()"><v-icon v-if="searchOpen">close</v-icon><v-icon v-else>search</v-icon>&nbsp;Search</v-btn>
                        <v-menu offset-y bottom left>
                            <template #activator="{ on }">
                                <v-btn id="print_button" flat color="primary" v-on="on">
                                    <v-icon>print</v-icon>&nbsp;{{$t('classMgmt.print')}}<span v-if="selectedStudents.length">&nbsp;({{selectedStudents.length}})</span>&nbsp;&#9662;
                                </v-btn>
                            </template>
                            <v-card>
                                <v-list>
                                    <v-list-tile @click="doNothing()">
                                        <v-list-tile-content class="blue--text" @click="onPrint('cards')">
                                            {{$t('classMgmt.studentLoginCards')}}
                                        </v-list-tile-content>
                                        <v-list-tile-action>
                                            <v-btn icon class="blue--text" @click="onSave('cards')">
                                                <v-icon>get_app</v-icon>
                                            </v-btn>
                                        </v-list-tile-action>
                                    </v-list-tile>
                                    <v-list-tile @click="doNothing()">
                                        <v-list-tile-content class="blue--text" @click="onPrint('roster')">
                                            {{$t('classMgmt.classRoster')}}
                                        </v-list-tile-content>
                                        <v-list-tile-action>
                                            <v-btn icon class="blue--text" @click="onSave('roster')">
                                                <v-icon>get_app</v-icon>
                                            </v-btn>
                                        </v-list-tile-action>
                                    </v-list-tile>
                                    <v-list-tile v-if="hasProductSubscription(this.subscriptions,1)" @click="doNothing()">
                                        <v-list-tile-content class="blue--text" @click="onPrint('letters-en')">
                                            <span><span v-if="multiProduct">{{productSettings[1].displayName}} </span>{{$t('classMgmt.parentAccountInviteEnglish')}}</span>
                                        </v-list-tile-content>
                                        <v-list-tile-action>
                                            <v-btn icon class="blue--text" @click="onSave('letters-en')">
                                                <v-icon>get_app</v-icon>
                                            </v-btn>
                                        </v-list-tile-action>
                                    </v-list-tile>
                                    <v-list-tile v-if="hasProductSubscription(this.subscriptions,1)" @click="doNothing()">
                                        <v-list-tile-content class="blue--text" @click="onPrint('letters-es')">
                                            <span><span v-if="multiProduct">{{productSettings[1].displayName}} </span>{{$t('classMgmt.parentAccountInviteSpanish')}}</span>
                                        </v-list-tile-content>
                                        <v-list-tile-action>
                                            <v-btn icon class="blue--text" @click="onSave('letters-es')">
                                                <v-icon>get_app</v-icon>
                                            </v-btn>
                                        </v-list-tile-action>
                                    </v-list-tile>
                                </v-list>
                            </v-card>
                        </v-menu>
                        <v-tooltip top :disabled="!exceedsStudentCap">
                            <template #activator="{ on }">
                                <span v-on="on">
                                    <v-btn 
                                        v-if="!isAllClasses && !selectedClassIsRostered"
                                        :disabled="exceedsStudentCap"
                                        flat 
                                        color="primary" 
                                        id="addMenu" 
                                        @click="addMenu=true" 
                                        class="mr-0"
                                    >
                                        <v-icon>person_add</v-icon>&nbsp;{{$t('classMgmt.addStudents')}}&nbsp;&#9662;
                                    </v-btn>
                                </span>
                            </template>
                            {{$t('classMgmt.studentCapExplained',{cap:studentCap})}}
                        </v-tooltip>
                        <v-menu offset-y bottom left v-model="addMenu" activator="#addMenu">
                            <v-card id="addOptions">
                                <v-list class="blue--text">
                                    <v-list-tile 
                                    v-for="button in addStudentOptions"
                                    @click="openDialog(button.modal)" 
                                    :key="button.id"
                                    :id="button.id">
                                        <v-list-tile-action>
                                            <v-icon color="blue">{{ button.icon }}</v-icon>
                                        </v-list-tile-action>
                                        <v-list-tile-content>
                                            {{button.label}}
                                        </v-list-tile-content>
                                    </v-list-tile>
                                </v-list>
                            </v-card>
                        </v-menu>
                    </v-layout>    
                </v-flex>
            </v-layout>
            <!-- SEARCH INFO ROW -->
            <v-card-title v-if="classes.length>1 && searchOpen" class="searchTitle px-1 py-2 d-flex">
                <v-layout class="d-flex justify-space-between" px-1>
                    <span class="subheading mt-auto grey--text">
                        <span v-show="showFilterMsg">{{filterMsg}}</span>
                        <span v-show="studentsInSelectedClass.length!=0 && !showFilterMsg">Showing {{studentsInSelectedClass.length}} of {{studentsInSelectedClass.length}}</span>
                        <span v-show="studentsInSelectedClass.length!=0 && !showFilterMsg"> {{$tc('classMgmt.students', studentsInSelectedClass.length)}}</span>
                    </span>
                    <v-text-field
                        v-model="search"
                        v-if="studentsInSelectedClass.length"
                        prepend-icon="search"
                        clearable
                        label="Search"
                        hide-details
                        class="userSearch"                    
                    ></v-text-field>
                </v-layout>
            </v-card-title>
            <v-flex v-if="studentsInSelectedClass.length">
                <v-data-table
                    v-model="selectedStudents"
                    :headers="headers"
                    :hide-actions="!searchOpen && studentsInSelectedClass.length < 51"
                    :items="studentsInSelectedClass"
                    item-key="id"
                    :search="search"
                    :pagination.sync="paginationOptions"
                    :rows-per-page-items="rowsPerPageItems(studentsInSelectedClass.length)"
                    select-all
                    :no-data-text="$t('classMgmt.noData')"
                    class="elevation-2 mb-3 roundedCorners"
                    must-sort
                    dense
                    ref="studentTable"
                >
                    <template #headers="props">
                        <tr>
                            <th class="px-3">
                                <v-checkbox
                                    :input-value="props.all"
                                    :indeterminate="props.indeterminate"
                                    primary
                                    hide-details
                                    @click.stop="toggleAll('selectedStudents',filteredTableData('studentTable'))"
                                ></v-checkbox>
                            </th>
                            <th
                                v-for="header in props.headers"
                                :key="header.key"
                                :class="['column',
                                    header.sortable ? 'sortable':'',
                                    paginationOptions.descending ? 'desc' : 'asc',
                                    header.sortable && header.value === paginationOptions.sortBy ? 'active' : '',
                                    'text-xs-'+header.align,
                                    header.icon ? 'px-0':'px-3',
                                    header.productID ? 'product-header':''
                                ]"
                                @click="if(header.sortable)changeSort(header.value,paginationOptions)"
                            >
                                <span v-if="header.icon">
                                    <v-icon small class="header-icon pt-1">{{header.icon}}</v-icon>
                                </span>
                                <span v-if="header.productID">
                                    <img :src="productImgPath(header.productID)"
                                        class="product-header"
                                        :alt="productDisplayName(header.productID)+' Logo'"
                                    />
                                    <span class="ml-1">
                                        {{productSettings[header.productID].assignmentType=="access"?$t('classMgmt.access'):$tc('classMgmt.assignment')}}
                                    </span>
                                </span>
                                <span v-else>{{ header.text }}</span>
                                <v-icon v-if="header.sortable" small>arrow_upward</v-icon>
                            </th>
                        </tr>
                    </template>
                    <template #items="student">
                        <tr>
                            <td class="px-3">
                                <v-checkbox
                                    v-model="student.selected"
                                    primary
                                    hide-details
                                ></v-checkbox>
                            </td>
                            <td v-if="isSelectedSchoolRostered" @click="openDialog('editStudentModal', {users: student.item})" class="px-0">
                                <v-icon small v-if="student.item.isRostered">sync_alt</v-icon>
                            </td>
                            <td @click="openDialog('editStudentModal', {users: student.item})" :class="{'blue--text':isTabRoster}" class="pointer px-3" >
                                {{student.item.lastName}}, {{student.item.firstName}}
                                <v-tooltip v-if="!canStudentLogIn(student.item)" bottom>
                                    <template #activator="{ on }">
                                        <v-icon v-on="on" color="error" class="text--lighten-1" small>error</v-icon>
                                    </template>
                                    <span v-if="multiProduct || ownedProducts.length==0">
                                        {{$t('classMgmt.noAssignmentAlert',{student:student.item.firstName+' '+student.item.lastName})}}
                                    </span>
                                    <span v-else>
                                        {{$t('classMgmt.noProductAccessAlert',{product:productDisplayName(ownedProducts[0]), student:student.item.firstName+' '+student.item.lastName, missing:productSettings[ownedProducts[0]].missing})}}
                                        </span>
                                </v-tooltip>
                            </td>
                            <td v-if="isTabRoster" @click="openDialog('editStudentModal', {users: student.item})" class="pointer px-3">
                                {{isClassCodeEnabledClassWithAllStudents ? student.item.username : student.item.sisID}}
                            </td>
                            <td v-if="isTabRoster" @click="openDialog('editStudentModal', {users: student.item})" class="pointer text-xs-center px-3">
                                <v-tooltip bottom :key="student.item.sisID+'-pword'">
                                    <template #activator="{ on }">
                                        <v-btn small class="elevation-0 passwordSheild" color="grey lighten-2 grey--text text--darken-2" dark v-on="on">*****</v-btn>
                                    </template>
                                    <span>{{student.item.password}}</span>
                                </v-tooltip>
                            </td>
                            <td v-if="isTabRoster" class="pointer text-xs-right px-3" :style="productAccessColumnMinWidth">
                                <span xs2 v-for="productID in ownedProducts" :key="student.item.sisID+'-'+productDisplayName(productID)" @click="productAccessClick(student.item, productID)">
                                    <v-tooltip bottom :key="student.item.sisID+'-'+productDisplayName(productID)+'-tootip'">
                                        <template #activator="{ on }">
                                            <img v-on="on"
                                                :src="productImgPath(productID)"
                                                :class="[
                                                    {'grey-product-indicator': !determineAssignment(student.item,productID).code},
                                                    productDisplayName(productID).toLowerCase()+'-leaf'
                                                ]"
                                                class="product-indicator"
                                                :alt="productDisplayName(productID)+' Logo'"
                                            />
                                        </template>
                                        <span v-if="determineAssignment(student.item,productID).code">
                                            {{determineAssignment(student.item,productID).label}}
                                        </span>
                                        <span v-else>
                                            {{$t('classMgmt.productAccessDisabled',{product:productDisplayName(productID)})}}
                                        </span>
                                    </v-tooltip>
                                </span>
                            </td>
                            <td v-if="isTabAssignment"
                                v-for="productID in ownedProducts"
                                @click="openProductSpecificAssignmentModal(productID,[student.item])"
                                :key="student.item.sisID+'-'+productID"
                                class="px-3 pointer"
                                :class="[determineAssignment(student.item,productID).code?productSettings[productID].colorClass:'grey--text text--darken-1']"
                            >
                                <span>
                                    <span v-if="determineAssignment(student.item,productID).code">
                                        <v-tooltip bottom :key="student.item.sisID+'-'+productDisplayName(productID)+'-assignment-tooltip'" :disabled="!checkProductUpgrade(productID, student.item)">
                                            <template #activator="{ on }">
                                                <v-icon v-on="on" v-if="checkProductUpgrade(productID, student.item)" size="14" color="warning">fa-circle-up</v-icon>
                                                <v-icon v-on="on" v-else small :class="productSettings[productID].colorClass">check_circle</v-icon>
                                            </template>
                                            <span>
                                                {{$t('classMgmt.fraxUpgradeAlert', {student:student.item.firstName+' '+student.item.lastName, sector:determineAssignment(student.item,productID).label})}}
                                            </span>
                                        </v-tooltip>
                                    </span>
                                    <span v-else>
                                        <v-tooltip bottom :key="student.item.sisID+'-'+productDisplayName(productID)+'-assignment-tooltip'">
                                            <template #activator="{ on }">
                                                <v-icon v-on="on" small class='grey--text'>remove_circle</v-icon>
                                            </template>
                                            <span>
                                                {{$t('classMgmt.noProductAccessAlert',{product:productDisplayName(productID), student:student.item.firstName+' '+student.item.lastName, missing:productSettings[productID].missing})}}
                                            </span>
                                        </v-tooltip>
                                    </span>
                                </span>
                                <span class="font-weight-medium" :class="{'grey--text text--darken-1':!determineAssignment(student.item,productID).code}">
                                    {{determineAssignment(student.item,productID).short}}&nbsp;&#9662;
                                </span>
                            </td>
                        </tr>
                    </template>
                    <template #pageText="props">
                        {{ props.pageStart }} - {{ props.pageStop }} of <span ref="studentsInTable">{{ props.itemsLength }}</span>
                    </template>
                </v-data-table>
            </v-flex>
            <!-- Empty Class Page -->
            <v-flex v-else-if="classes.length>1 && selectedClassID">
                <v-layout v-if="!selectedClassIsRostered" wrap class="text-xs-center" fill-height>
                    <v-flex
                        v-for="button in addStudentOptions"
                        :class="button.class"
                        class="emptyClassOption" 
                        :id="button.emptyId"
                        :key="button.emptyId"
                    >
                        <div class="btn" @click="openDialog(button.modal)" style="height:90%;">
                            <v-icon color="blue">{{button.icon}}</v-icon>
                            <div>{{button.label.toUpperCase()}}</div>
                        </div>
                    </v-flex>
                </v-layout>
                <v-layout v-else>
                    <v-alert
                        value=1
                        outline
                        color="info"
                        icon='info'
                        style="border-radius:4px;"
                    >
                        <p>{{$t('classMgmt.classConfiguredForAutomaticRostering')}}</p>
                        <p class="mb-0">{{$t('classMgmt.rosteringHelpPreLinkText')}} <a target="_blank" :href="getHelpCenterURL('topic/0TO0b000000UTwMGAW/rostering')">{{$t('classMgmt.rosteringHelpLink')}}</a>.</p>
                    </v-alert>
                </v-layout>
            </v-flex>
            <!-- No Classes Page -->
            <div v-else>
                <v-layout class="text-xs-center" >
                    <v-flex xs12 class="emptyClassOption" >
                        <div class="btn" @click="openDialog('createClassModal')">
                            <div class="mt-1">
                                <v-icon color="blue mt">school</v-icon>
                                <div>{{$t('classMgmt.createYourFirstClass')}}</div>
                            </div>
                        </div>
                    </v-flex>
                </v-layout>
            </div>
            <!-- Bottom Buttons -->
            <v-layout v-if="studentsInSelectedClass.length" wrap class="action-bar">
                <v-flex class="text-xs-right pr-1">
                <!-- Product Assignment Buttons -->
                    <v-btn
                        v-if="isTabAssignment"
                        v-for="button in assignmentAccessButtons"
                        v-bind:key="button.id"
                        color="primary"
                        class="elevation-0 ml-1"
                        :class="isNaN(button.id)? button.id: ''"
                        :disabled="!selectedStudents.length"
                        id="dynamicButtonMenu"
                        @click="!isNaN(button.id) ? openProductSpecificAssignmentModal(button.id, selectedStudents, true) : dynamicButtonMenu=true"
                    >
                        <v-icon>edit</v-icon>&nbsp;
                        <span v-if="!isNaN(button.id)"><span v-if="multiProduct">{{button.displayName}}&nbsp;</span>{{$tc('classMgmt.' + button.assignmentType, localizationConstants.SINGLE)}}</span>
                        <span v-else>{{button.bulkButtonLabel}}</span>
                        &nbsp;({{selectedStudents.length}})
                        <v-menu offset-y top v-model="dynamicButtonMenu" activator="#dynamicButtonMenu">
                            <v-card>
                                <v-list>
                                    <v-list-tile
                                        v-for="product in button.products"
                                        v-bind:key="product.id+'-btn'"
                                        @click="!isNaN(product.id) ? openProductSpecificAssignmentModal(product.id, selectedStudents, true) : doNothing()"
                                    >
                                        <v-list-tile-content class="blue--text">
                                            <span>
                                                <img :src="productImgPath(product.id)" align="center"/>&nbsp;
                                                {{product.displayName}}&nbsp;{{$tc('classMgmt.' + product.assignmentType, localizationConstants.SINGLE)}}
                                            </span>
                                        </v-list-tile-content>
                                    </v-list-tile>
                                </v-list>
                            </v-card>
                        </v-menu>
                    </v-btn>
                    <v-btn v-if="!selectedClassIsRostered && isTabRoster" color="primary" class="elevation-0 mr-2" :disabled="!selectedStudents.length || containsRosteredUser(selectedStudents)" @click="openDialog('editGradeLevelModal')">
                        <v-icon>edit</v-icon>&nbsp;{{$t('classMgmt.gradeLevel')}} ({{selectedStudents.length}})
                    </v-btn>
                    <v-btn v-if="!isAllClasses && !selectedClassIsRostered && isTabRoster" color="error" class="elevation-0" :disabled="!selectedStudents.length" @click="openDialog('deleteConfirmModal')">
                        <v-icon>cancel</v-icon>&nbsp;{{$t('classMgmt.remove')}} ({{selectedStudents.length}})
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-flex>
        <!-- No Subscriptions -->
        <v-flex v-else class="text-xs-center pt-3">
            <v-flex class="display-1 font-weight-thin grey--text">{{$t('classMgmt.youDoNotHaveAnyActiveSubscriptions')}}</v-flex>
            <v-flex class="display-1 font-weight-thin grey--text">{{$t('classMgmt.visitYour')}} <router-link :to="{name:'Subscriptions'}">{{$t('header.account')}}</router-link> {{$t('classMgmt.pageToLearnMore')}}</v-flex>
        </v-flex>
        <!-- Snakcbars / Toasts -->
        <v-snackbar
            v-model='successBar'
            color="success"
        >
            {{successMessage}}
            <v-btn dark flat @click="successBar = false">
                {{$t('navigation.close')}}
            </v-btn>
        </v-snackbar>
        <v-snackbar
            v-model='errorBar'
            color="error"
        >
            {{errorMessage}}
            <v-btn dark flat @click="errorBar = false">
                {{$t('navigation.close')}}
            </v-btn>
        </v-snackbar>
        <!--
            =====================================
            End Page Content, Begin Modal Content
            =====================================
        -->
        <div v-if="subscriptions.length">
<RemoveStudentModal
                @closeDialogs="closeDialogs"
                @deleteUsers="deleteUsers"
                @updateModel="updateModel"
                :allowStudentCreation="allowStudentCreation"
                :containsRosteredUser="containsRosteredUser"
                :forceDeleteMode="modalParameters.forceDeleteMode"
                :getIDs="getIDs"
                :institutionID="institutionID"
                :isClassCodeEnabledClass="isClassCodeEnabledClass"
                :selectedClassID="selectedClassID"
                :selectedClassName="selectedClassName"
                :selectedUsers="passedOrSelectedUsers"
                :userCountOrName="userCountOrName"
                :value="modalControl.deleteConfirmModal"
            ></RemoveStudentModal>

            <EditStudentModal
                @closeDialogs="closeDialogs"
                @openDialog="openDialog"
                @updateModel="updateModel"
                @openProductSpecificAssignmentModal="openProductSpecificAssignmentModal"
                :checkProductUpgrade="checkProductUpgrade"
                :determineAssignment="determineAssignment"
                :multiProduct="multiProduct"
                :institutionID="institutionID"
                :isAllClasses="isAllClasses"
                :isClassCodeEnabledClass="isClassCodeEnabledClass"
                :ownedProducts="ownedProducts"
                :selectedClassID="selectedClassID"
                :subscriptions="subscriptions"
                :student="passedOrSelectedUsers"
                :tenantID="tenantID"
                :value="modalControl.editStudentModal"
                :hasProductSubscription="hasProductSubscription"
                :key="'editStudentModal-'+componentKey"
            ></EditStudentModal>

            <EditAssignmentModal
                @closeDialogs="closeDialogs"
                @updateModel="updateModel"
                :determineAssignment="determineAssignment"
                :hasGradeLevelLessThan="hasGradeLevelLessThan"
                :institutionID="institutionID"
                :seatInfo="seatInfo"
                :selectedSchool="selectedSchool"
                :selectedUsers="passedOrSelectedUsers"
                :subscriptionId="subscriptionId"
                :tenantID="tenantID"
                :userCountOrName="userCountOrName"
                :value="modalControl.editAssignmentModal"
            ></EditAssignmentModal>

            <EditSectorModal
                v-if="hasProductSubscription(subscriptions,2)"
                @closeDialogs="closeDialogs"
                @updateSectors="updateSectors"
                :determineAssignment="determineAssignment"
                :hasGradeLevelLessThan="hasGradeLevelLessThan"
                :loading="loading"
                :seatInfo="seatInfo"
                :selectedSchool="selectedSchool"
                :selectedUsers="passedOrSelectedUsers"
                :studentSectorData="studentSectorData"
                :subMaxSector="maxSector"
                :userCountOrName="userCountOrName"
                :value="modalControl.editSectorModal"
            ></EditSectorModal>

            <EditAccessModal
                @closeDialogs="closeDialogs"
                @updateModel="updateModel"
                :determineAssignment="determineAssignment"
                :getIDs="getIDs"
                :institutionID="institutionID"
                :productID="modalParameters.productID"
                :seatInfo="seatInfo"
                :selectedStudents="passedOrSelectedUsers"
                :selectedSchool="selectedSchool"
                :subscriptionID="subscriptionId"
                :tenantID="tenantID"
                :userCountOrName="userCountOrName"
                :value="modalControl.editAccessModal"
            ></EditAccessModal>

            <EditGradeLevelModal
                @closeDialogs="closeDialogs"
                @updateModel="updateModel"
                :institutionID="institutionID"
                :selectedUsers="passedOrSelectedUsers"
                :tenantID="tenantID"
                :userCountOrName="userCountOrName"
                :value="modalControl.editGradeLevelModal"
            ></EditGradeLevelModal>

            <AddNewStudentModal
                @closeDialogs="closeDialogs"
                @openDialog="openDialog"
                @updateModel="updateModel"
                @updateSectors="updateSectors"
                @handleSuccess="handleSuccess"
                @trackAddStudents="trackAddStudents"
                :exceedsStudentCap="exceedsStudentCap"
                :hasGradeLevelLessThan="hasGradeLevelLessThan"
                :institutionID="institutionID"
                :loadingStudents="loadingStudents"
                :multiProduct="multiProduct"
                :paginationOptions="paginationOptions"
                :productId="productId"
                :seatInfo="seatInfo"
                :selectedClass="selectedClass"
                :selectedSchool="selectedSchool"
                :studentsInSelectedClass="studentsInSelectedClass"
                :allStudents="allStudents"
                :students="students"
                :subMaxSector="maxSector"
                :subscriptionId="subscriptionId"
                :subscriptions="subscriptions"
                :tenantID="tenantID"
                :value="modalControl.addStudentNewModal"
            ></AddNewStudentModal>

            <StudentRosterModal
                @closeDialogs="closeDialogs"
                @openDialog="openDialog"
                @updateModel="updateModel"
                @trackAddStudents="trackAddStudents"
                :allowStudentCreation="allowStudentCreation"
                :allStudents="allStudentsWithSearchableNames"
                :changeSort="changeSort"
                :classCap="studentCap"
                :containsRosteredUser="containsRosteredUser"
                :gradeLevelIdFilter="gradeLevelIdFilter"
                :institutionID="institutionID"
                :isSelectedSchoolRostered="isSelectedSchoolRostered"
                :loadingStudents="loadingStudents"
                :seatsUsed="studentsInSelectedClass.length"
                :selectedClass="selectedClass"
                :selectedClassID="selectedClassID"
                :selectedSchool="selectedSchool"
                :value="modalControl.addStudentRosterModal"
            ></StudentRosterModal>

            <CreateClassModal
                @closeDialogs="closeDialogs"
                @updateModel="updateModel"
                @switchClass="switchClass"
                @classCapReached="handleError($t('classMgmt.classCapReached',{cap:classCap}))"
                :allowCreation="!classCapReached"
                :auth="auth"
                :institutionID="institutionID"
                :isNewClassCCActive="isNewClassCCActive"
                :loadingTeachers="loadingTeachers"
                :otherTeachers="otherTeachers"
                :value="modalControl.createClassModal"
            ></CreateClassModal>

            <ClassSettingsModal
                @closeDialogs="closeDialogs"
                @handleSuccess="handleSuccess"
                @openDialog="openDialog"
                @updateModel="updateModel"
                :hasClassCap="hasClassCap"
                :isStandAloneTeacher="isStandAloneTeacher"
                :institutionID="institutionID"
                :loadingTeachers="loadingTeachers"
                :productId="productId"
                :selectedClass="selectedClass"
                :selectedSchool="selectedSchool"
                :teachers="teachers"
                :tenantID="tenantID"
                :universalSettingsPanel="modalParameters.universalSettingsPanel"
                :value="modalControl.classSettingsModal"
            ></ClassSettingsModal>

            <DeleteClassModal
                @closeDialogs="closeDialogs"
                @updateModel="updateModel"
                :institutionID="institutionID"
                :isRostered="isSelectedSchoolRostered"
                :classCap="classCap"
                :classObj="selectedClass"
                :numClasses="classesCountedTowardsClassCap.length"
                :value="modalControl.deleteClassModal"
            ></DeleteClassModal>

            <ImportStudentModal
                v-if="classes.length>1"
                @closeDialogs="closeDialogs"
                @handleSuccess="handleSuccess"
                @openDialog="openDialog"
                @trackAddStudents="trackAddStudents"
                :classObj="selectedClass"
                :institutionID="institutionID"
                :institutions="institutionList"
                :isSelectedSchoolRostered="isSelectedSchoolRostered"
                :loadingStudents="loadingStudents"
                :productId="productId"
                :rowsPerPageItems="rowsPerPageItems"
                :selectedClass="selectedClass"
                :selectedSchool="selectedSchool"
                :subscriptionId="subscriptionId"
                :students="allStudents"
                :tenantID="tenantID"
                :updateModel="updateModel"
                :value="modalControl.importStudentModal"            
            ></ImportStudentModal>

            <FormattingModal
                @closeDialogs="closeDialogs"
                :isSelectedSchoolRostered="isSelectedSchoolRostered"
                :isClassCodeEnabledClass="isClassCodeEnabledClass"
                :value="modalControl.formattingModal"
            ></FormattingModal>

            <ClassCodeModal
                @closeDialogs="closeDialogs"
                @handleSuccess="handleSuccess"
                @updateModel="updateModel"
                @handleError="handleError($t('error.snackbarError'))"
                :classInfo="selectedClass"
                :institutionID="institutionID"
                :productId="productId"
                :value="modalControl.classCodeModal"
            ></ClassCodeModal>
        </div>
    </v-flex>
</template>

<script>
    import APIs from 'mixins/APIs'
    import ClassMgmt from 'mixins/ClassMgmt'
    import Constants from 'mixins/Constants'
    import { Auth } from '@explorelearning/el-authenticator'
    import ElPlatformRestApi from '@explorelearning/platform-rest-api'
    import Forwarding from 'mixins/Forwarding'
    import Headline from 'components/layout/Headline'
    import LoginDetector from 'mixins/LoginDetector'
    import PrintHelper from 'mixins/PrintHelper'
    import ProductInfo from 'mixins/ProductInfo'
    import AddNewStudentModal from 'components/modals/AddNewStudentModal'
    import ClassCodeModal from 'components/modals/ClassCodeModal'
    import ClassSettingsModal from 'components/modals/ClassSettingsModal'
    import CreateClassModal from 'components/modals/CreateClassModal'
    import DeleteClassModal from 'components/modals/DeleteClassModal'
    import EditAccessModal from 'components/modals/EditAccessModal'
    import EditAssignmentModal from 'components/modals/EditAssignmentModal'
    import EditGradeLevelModal from 'components/modals/EditGradeLevelModal'
    import EditSectorModal from 'components/modals/EditSectorModal'
    import EditStudentModal from 'components/modals/EditStudentModal'
    import FormattingModal from 'components/modals/FormattingModal'
    import ImportStudentModal from 'components/modals/ImportStudentModal'
    import RemoveStudentModal from 'components/modals/RemoveStudentModal'
    import RosterModal from 'components/classMgmt/RosterModal'
    import StudentRosterModal from 'components/modals/StudentRosterModal'
    import EnvironmentDetector from 'mixins/EnvironmentDetector'
    import { mapGetters, mapActions, mapState } from 'vuex'

    export default {
        name: 'ClassManagement',
        metaInfo : {
            title : 'Class Management'
        },
        components: {
            AddNewStudentModal,
            ClassCodeModal,
            ClassSettingsModal,
            CreateClassModal,
            DeleteClassModal,
            EditAccessModal,
            EditAssignmentModal,
            EditGradeLevelModal,
            EditSectorModal,
            EditStudentModal,
            FormattingModal,
            Headline,
            ImportStudentModal,
            RemoveStudentModal,
            RosterModal,
            StudentRosterModal,
        },
        mixins: [APIs, ClassMgmt, Constants, Forwarding, LoginDetector, PrintHelper, EnvironmentDetector, ProductInfo],
        data () {
            return {
                experimentalMode : false,
                // Standup Data
                activeTab: 0,
                allStudents: [],
                classDropdown: false,
                dataLoaded: false,
                institutionDropdown: false,
                model: {},
                paginationOptions: {sortBy : 'name', rowsPerPage: -1},
                search: '',
                searchOpen: false,
                selectedClassID: null,
                selectedStudents: [],
                teachersLoaded: false,
                totalStudentsInTable: -1,
                // Async Data
                auth : null,
                subscriptionId : null,
                tenantID : null,
                loading : false,
                loadingStudents : false,
                loadingTeachers : false,
                // Modal Data
                modalControl : {
                    addStudentNewModal : false,
                    addStudentRosterModal : false,
                    classCodeModal : false,
                    classSettingsModal : false,
                    createClassModal : false,
                    deleteClassModal : false,
                    deleteConfirmModal : false,
                    editAccessModal : false,
                    editAssignmentModal : false,
                    editGradeLevelModal : false,
                    editSectorModal : false,
                    editStudentModal : false,
                    formattingModal: false,
                    importStudentModal: false,
                },
                // Add Student Menu
                addMenu : false,
                // Dynamic Access/Assignment Buttons (for use with opening dropdown selections)
                dynamicButtonMenu: false
            }
        },
        computed: {
            ...mapGetters('platformData/classes', ['classList', 'classCodeEnabledClassList', 'nonClassCodeEnabledClassList']),
            ...mapGetters('platformData/subscriptions', ['subscriptionList']),
            ...mapGetters('platformData/institutions', ['institutionList']),
            ...mapGetters('platformData/students', ['classCodeEnabledStudentList', 'nonClassCodeEnabledStudentList']),
            ...mapState('platformData/app', ['claims']),
            allowStudentCreation(){
                let institutionCanEnroll = false
                if(this.selectedSchool && this.selectedSchool.settings){
                    institutionCanEnroll = this.selectedSchool.settings.allowStudentEnrollmentByTeachers
                }
                return institutionCanEnroll || this.isStandAloneTeacher
            },
            allStudentsWithSearchableNames(){
                return this.allStudents.map(student => ({
                    ...student,
                    searchableName: student.firstName + ' ' + student.lastName + ' ' + student.firstName
                })); 
            },
            assignmentAccessButtons(){
                let products = []
                let buttons = []
                let combinedLabels = []
                this.ownedProducts.forEach(prodID => {
                    products.push(this.productSettings[prodID])
                    products[products.length-1].id = prodID
                })
                if (products.length < 3){
                    return products
                } else {
                    for(let i = 0; i < products.length; i++){
                        if (!combinedLabels.includes(products[i].assignmentType)){
                            combinedLabels.push(products[i].assignmentType)
                        }
                    }
                    let bulkButtonLabel = ''
                    for(let i = 0; i < combinedLabels.length; i++){
                        if (i!=0){
                            bulkButtonLabel += ' ' + this.$t('classMgmt.assignmentTypeConjunction') + ' '
                        }
                        bulkButtonLabel += this.$tc('classMgmt.' + combinedLabels[i], this.localizationConstants.SINGLE)
                    }
                    buttons.push({products: products, id: 'bulk', bulkButtonLabel: bulkButtonLabel})
                    return buttons
                }
            },
            classCap(){
                let cap = 0
                if (this.profile.classesPerTeacherCap) {
                    cap = this.profile.classesPerTeacherCap
                } else if (this.selectedSchool.settings) {
                    cap = this.selectedSchool.settings.classesPerTeacherCap
                }
                if(cap >= 0){
                    return cap
                } else {
                    return Number.POSITIVE_INFINITY
                }
            },
            classCapReached(){
                return this.classesCountedTowardsClassCap.length >= this.classCap
            },
            classes() {
                let classes
                if(this.classList){                    
                    if(this.isClassCodeEnabledSchool) {
                        if (this.currentEnvironment == 'el') {
                            classes = this.classList
                        } else if (this.currentEnvironment == 'gizmos') {
                            classes = this.classCodeEnabledClassList
                        } else {
                            classes = this.nonClassCodeEnabledClassList
                        }
                    } else {
                        classes = this.classList
                    }
                    let allClasses = classes.find(({id}) => id == -1)
                    if (!allClasses) {
                        classes.sort(function(a,b){
                            let aName  = a.name.toUpperCase()
                            let bName  = b.name.toUpperCase()
                            let comp = 0
                            if(aName>bName){
                                comp = 1
                            } else if (aName<bName){
                                comp=-1
                            }
                            return comp
                        })
                        classes.push({name:this.$t('classMgmt.all'),id:-1})      
                    }                            
                } else {
                    classes = []
                }
                return classes
            },
            classesCountedTowardsClassCap(){
                let loginId = this.currentUserID
                return this.classes.filter(function(classObj){
                    return classObj.ownerID == loginId && !classObj.isRostered
                })
            },
            // classesWithAllClass() {
            //     return this.classes.concat({name:this.$t('classMgmt.all'),id:-1})                             
            // },
            addStudentOptions(){
                let buttons = []
                if(!this.isClassCodeEnabledClass){
                    buttons.push( {id: 'addMenuRoster', emptyId:'emptyClassRoster', label: this.$tc('classMgmt.addStudentFromRoster', this.localizationConstants.MULTIPLE), icon: 'list', modal: 'addStudentRosterModal'} )
                }
                const allowExternalStudents = this.allowStudentCreation && !this.isSelectedSchoolRostered
                if(allowExternalStudents || this.isClassCodeEnabledClass){
                    buttons.push( {id: 'addMenuImport', emptyId:'emptyClassImport', label: this.$t('classMgmt.importStudentHeader'), icon: 'publish', modal: 'importStudentModal'} )
                    buttons.push( {id: 'addMenuManual', emptyId:'emptyClassAdd', label: this.$t('classMgmt.addANewStudent'), icon: 'person_add', modal: 'addStudentNewModal'} )
                }
                if(this.isClassCodeActiveClass){
                    buttons.push( {id: 'addMenuCode', emptyId:'emptyClassCode', label: this.$t('classMgmt.studentSelfEnrollment'), icon: 'share', modal: 'classCodeModal'} )
                }
                const baseSize = 12/buttons.length
                let classes = 'xs12 sm6 md'+baseSize
                for(let i=0; i<buttons.length; ++i){
                    // Treat the last button differently if there are an odd number of buttons
                    if(i==buttons.length-1 && Boolean(buttons.length%2)){
                        buttons[i].class = classes.replace('sm6','sm12')
                    } else {
                        buttons[i].class = classes
                    }
                }
                return buttons
            },
            exceedsStudentCap(){
                return this.studentsInSelectedClass.length >= this.studentCap
            },
            filterMsg(){
                return `Showing ${this.totalStudentsInTable} of ${this.studentsInSelectedClass.length} Students`
            },
            hasClassCap(){
                return Number.isInteger(this.classCap)
            },
            headers(){
                let headers =  []
                if(this.isSelectedSchoolRostered){
                    headers.push({key:'rostered', icon:'sync_alt', value:'isRostered', sortable: true, align:'left'})
                }
                headers.push({key:'name', text: this.$t('classMgmt.name'), value:'searchableName', sortable:true, align: 'left'})
                if(this.isTabRoster){
                    this.isClassCodeEnabledClassWithAllStudents
                        ? headers.push({key:'username', text: this.$t('form.userName'), value:'username', sortable:true, align: 'left'})
                        : headers.push({key:'studentID', text: this.$t('classMgmt.studentID'), value:'sisID', sortable:true, align: 'left'})
                    headers.push({key:'password', text: this.$t('classMgmt.password'), value:'password', sortable:false, align: 'center'})
                    headers.push({key:'products', text: this.$t('classMgmt.productAccess'), value:'products', sortable:false, align: 'right'})

                }
                if(this.isTabAssignment){
                    this.ownedProducts.forEach(productID => {
                        let productHeader = {productID, value: this.productSettings[productID].assignmentValue, sortable:true, align:'left'}
                        productHeader.text = this.productSettings[productID].assignmentType=="access"?this.$t('classMgmt.access'):this.$tc('classMgmt.assignment')
                        productHeader.key = this.productDisplayName(productID)
                        headers.push(productHeader)
                    })
                }
                return headers
            },
            isAllClasses() {
                return this.selectedClassID === -1
            },
            isClassCodeActiveClass(){
                return this.isClassCodeEnabledClass && this.selectedClass.isClassCodeActive
            },
            isClassCodeEnabledClass(){
                return this.selectedClass.isClassCodeEnabled && this.isClassCodeEnabledSchool
            },
            isClassCodeEnabledClassWithAllStudents(){
                return this.selectedClass.id === -1 && this.isClassCodeEnabledSchool 
                    ? this.currentEnvironment == 'gizmos' 
                    : this.selectedClass.isClassCodeEnabled && this.isClassCodeEnabledSchool
            },
            isNewClassCCActive(){
                return this.isClassCodeEnabledSchool && this.currentEnvironment == 'gizmos'
            },
            isSelectedSchoolRostered() {
                return this.selectedSchool.isRosterEnabled
            },
            isStandAloneTeacher(){
                let nonStandAloneTeacherTypeIDs = [1,5,7] // Site, Pilot, Employee Seed Program
                let isStandAloneTeacher = true
                this.subscriptions.forEach(sub => {
                    isStandAloneTeacher = isStandAloneTeacher && !nonStandAloneTeacherTypeIDs.includes(sub.licensingTypeId)
                })
                return isStandAloneTeacher
            },
            localizationConstants() {
                return {
                    NONE: 0,
                    SINGLE: 1,
                    MULTIPLE: 2
                }
            },
            isTabAssignment(){
                return this.activeTab == 1
            },
            isTabRoster(){
                return this.activeTab == 0
                
            },
            maxSector(){
                if(this.hasProductSubscription(this.subscriptions,2)){
                    return this.productData[2].sectorMax
                } else {
                    return 0
                }
            },
            myClassesText() {
                if (this.currentEnvironment == 'el' || !this.isClassCodeEnabledSchool) {
                    return 'My Classes'
                } else if (this.currentEnvironment == 'gizmos') {
                    return 'My Gizmos Classes'
                } else {
                    const compareProducts = (a, b) => {
                        const productIdA = this.productDisplayOrder.indexOf(a.productId);
                        const productIdB = this.productDisplayOrder.indexOf(b.productId);
                        return productIdA - productIdB;
                    };
                    let productString = 'My '
                    const productsArray = this.selectedSchool.subscriptions.filter((sub) => {
                        return !this.classCodeEnabledProductIDs.includes(sub.productId) && sub.isActive
                    });
                    productsArray.sort(compareProducts);
                    productsArray.forEach(function(prod) {
                        productString += prod.displayName + "/";
                    });
                    return productString.slice(0, -1) + ' Classes';
                }
            },
            otherTeachers(){
                let username = this.profile.username
                return this.teachers.filter(function(teacher){
                    return teacher.username!=username
                })
            },
            otherTeachersString(){
                let msg
                if (this.selectedClass.teacherIDs && this.selectedClass.teacherIDs.length > 1) {
                    let owner = this.getTeachers([this.selectedClass.ownerID])[0]
                    if (owner) {
                        msg = `Teachers: ${owner.firstName} ${owner.lastName}`
                        let allTeachersBesidesOwner = this.teachers.filter(teacher => {
                            return teacher.id != this.selectedClass.ownerID
                        })

                        for (let x= 0; x < this.selectedClass.teacherIDs.length; x++) {
                            for (let i = 0; i< allTeachersBesidesOwner.length; i++) {                            
                                if (allTeachersBesidesOwner[i].id == this.selectedClass.teacherIDs[x]){
                                    let teacherName = `, ${allTeachersBesidesOwner[i].firstName} ${allTeachersBesidesOwner[i].lastName}`
                                    msg = msg.concat(teacherName)
                                }
                            }
                        }  
                    }             
                }
                return msg
            },
            selectedClass() {
                if(Array.isArray(this.classes)){
                    if (this.selectedClassID == null) {
                        this.chooseFirstClass(this.classes)
                    }
                    let selectedID = this.selectedClassID
                    if (selectedID >= 0) {
                        return this.classes.find(function(c){
                            return selectedID == c.id
                        }) || {}
                    } else {
                        return {name:this.$t('classMgmt.all'),id:-1} 
                    }

                } else {
                    return {}
                }
            },
            selectedClassName() {
                if(this.selectedClass){
                    return this.selectedClass.name
                } else {
                    return ''
                }
            },
            selectedClassIsRostered() {
                if(this.selectedClass){
                    return this.selectedClass.isRostered
                } else {
                    return true
                }
            },
            settingsBarText(){
                if(this.selectedClass.id>=0){
                    let owner = this.getTeachers([this.selectedClass.ownerID])[0]
                    let otherTeacherCount = this.selectedClass.teacherIDs.length-1
                    let txt = `Students: ${this.selectedClass.studentIDs.length}`
                    if(otherTeacherCount > 0 && owner) {
                        txt += ` • Teachers: ${owner.firstName} ${owner.lastName} (Owner) + ${otherTeacherCount} additional`
                    } else if (owner) {
                        txt += ` • Teacher: ${owner.firstName} ${owner.lastName}`
                    }
                    return txt
                } else {
                    return null
                }
            },
            showFilterMsg(){
                if (this.search) {
                    return true
                } else {
                    return false
                }
            },
            showStudentCap(){
                return this.studentCap != Number.POSITIVE_INFINITY
            },
            studentsInSelectedClass() {
                if(Object.keys(this.selectedClass).length>0){
                    if(this.selectedClass.id<0){
                        return this.studentsWithAssignments
                    } else {
                        let studentIdsInClass = this.selectedClass.studentIDs
                        return this.studentsWithAssignments.filter(function(student){
                            return studentIdsInClass.includes(student.id)
                        })
                    }
                } else {
                    return []
                }
            },
            studentCap(){
                return this.profile.studentsPerClassCap
            },
            studentSectorData(){
                if(this.hasProductSubscription(this.subscriptions,2)){
                    return this.productData[2].studentsSectorLimit
                } else {
                    return []
                }
            }
        },
        methods: {
            ...mapActions('platformData/teachers', ['fetchTeachers']),
            ...mapActions('platformData/students', ['fetchStudents']),
            chooseFirstClass(val) {
                if(this.selectedClassID===null && val[0].id!=-1){
                    this.selectedClassID = val[0].id
                } else {
                    let classExists = false
                    val.forEach(c => {
                        if(c.id==this.selectedClassID){
                            classExists = true
                        }
                    })
                    if(!classExists && val[0].id!=-1){
                        this.selectedClassID = val[0].id
                    }
                }
            },
            displayAssignmentBtn(productId){
                return this.isTabAssignment && this.hasProductSubscription(this.subscriptions,productId)
            },
            getTeachers(IDs){
                let teachers = this.teachers.filter(function(teacher){
                    return IDs.includes(teacher.id)
                })
                if(!this.teachers.length){
                    teachers = [{}]
                }
                return teachers
            },
            getTotalStudentsInTable() {
                this.$nextTick(()=>{
                    if (this.$refs.studentsInTable) {
                        this.totalStudentsInTable = this.$refs.studentsInTable.innerHTML
                    } else {
                        this.totalStudentsInTable = 0;
                    }
                    
                })                   
            },
            handleDeepLink(){
                // Tab Linking
                let tab = this.$route.query.tab
                if(tab=='assignments'){
                    this.activeTab = 1
                }
                // Class & Student Linking
                let queryClassID = this.$route.query.class
                let queryClass = this.classes.find(function(classObject){
                    return classObject.id == queryClassID
                })
                if(queryClass){
                    this.switchClass(queryClass.id)
                    let queryStudentID = this.$route.query.student
                    let queryStudent  = this.studentsInSelectedClass.find(function(student){
                        return student.id == queryStudentID
                    })
                    if(queryStudent){
                        this.openDialog('editStudentModal', {users:queryStudent})
                    }
                }
                // Other Modal Controls
                let modal = this.$route.query.modal
                if(modal == 'newStudent'){
                    this.openDialog('addStudentNewModal')
                } else if(modal == 'newClass'){
                    if(this.classCapReached){
                        this.handleError(this.$t('classMgmt.classCapReached',{cap:this.classCap}))
                    } else {
                        this.openDialog('createClassModal')
                    }
                } else if(modal=='settings'){
                    let modalParameters = {}
                    if(this.$route.query.expanded){
                        modalParameters.universalSettingsPanel = true
                    }
                    this.openDialog('classSettingsModal', modalParameters)
                } else if(modal == 'import'){
                    this.openDialog('importStudentModal')
                }
                // Experimental Mode
                if(this.$route.query.experimental){
                    this.experimentalMode = true
                }
                // Clean Up
                // if(Object.keys(this.$route.query).length){
                //     this.$router.replace({query:{}, params: this.$route.params})
                // }
            },
            openProductSpecificAssignmentModal(productID, users, closeDialogs){
                if(this.productSettings[productID].assignmentType=='access'){
                    this.openDialog('editAccessModal', {productID, users}, closeDialogs)
                } else {
                    if(productID==1){
                        this.openDialog('editAssignmentModal', {users}, closeDialogs)
                    } else if(productID==2){
                        this.openDialog('editSectorModal', {users}, closeDialogs)
                    }
                }
            },
            async productAccessClick(student, productId){
                if (this.isClassCodeActiveClass && productId == 3) {
                    this.openDialog('editStudentModal', {users: student})
                } else {
                    this.activeTab = 1
                }
            },
            searchToggle(){
                this.searchOpen = !this.searchOpen
                this.search = ''
            },
            switchClass(classID){
                this.selectedClassID=classID
            },
            async switchInstitutions(institutionId){
                this.selectedClassID = null
                await this.updateModel(institutionId)
                await this.fetchTeachers({institutionId})
                this.selectedSchoolID = institutionId
            }    
        },
        watch: {
            classes : function(val){
                if (val[0]) {
                    this.chooseFirstClass(val)
                }
            },
            selectedClassID : function(){
                this.search = '';
            },
            search() {
                this.getTotalStudentsInTable()
            },
            activeTab() {
                this.getTotalStudentsInTable()
                this.search = '';
            },
            modalControl: {
                async handler() {
                    if (!this.allStudents.length && (this.modalControl['addStudentNewModal'] || this.modalControl['addStudentRosterModal'] || this.modalControl['importStudentModal'])) {
                        this.loadingStudents = true
                        this.allStudents = await this.platform.fetchStudentsByInstitution({institutionId: this.selectedSchoolID})
                        this.loadingStudents = false
                    } else if (!this.teachersLoaded && (this.modalControl['classSettingsModal'] || this.modalControl['createClassModal'])) {
                        this.loadingTeachers = true
                        await this.fetchTeachers({institutionId: this.selectedSchoolID})
                        this.teachersLoaded = true
                        this.loadingTeachers = false
                    }
                },
                deep: true // This enables deep watching
            }
        },
        async created(){
            const auth = new Auth({
                auth: this.getClaimsAPI(),
                refresh: this.getRefreshAPI(),
                logging: this.getClientLogAPI()
            })
            auth.setClaims(this.claims)
            const platform = new ElPlatformRestApi({
                baseURL: this.getPlatformURL(),
                auth
            })
            this.auth = auth
            this.$store.commit('setPlatform', platform)
            let self = this
            let subs = this.subscriptionList;
            let activeSubs = subs.filter(function(sub){
                let isActive = sub.isActive && !self.productExclusionList.includes(sub.productId)
                if(!self.licenseTypeIsExpiredExempted(sub.productId, sub.licensingTypeId)){
                    isActive = isActive && !sub.isExpired
                }
                return isActive
            })
            let currentProductID = this.getProductCode()
            let defaultSub = activeSubs.find(function(sub){
                return currentProductID == sub.productId
            })
            if(!defaultSub){
                defaultSub = activeSubs[0]
            }
            if(defaultSub){
                // has at least one active sub
                this.productId = defaultSub.productId
                let subscriptionId = defaultSub.id
                this.subscriptionId = subscriptionId
                this.tenantID = defaultSub.tenantId
                let institutionId = this.$route.query.institution || null;
                
                (async function() {
                    await self.getProductData()
                    if(self.subscriptionList.length && self.subscriptionList[0].sourceId==2){
                        // Time 4 learning / Time 4 Math Facts User
                        self.forward(self.getReportingAppURL())
                    }
                    //assign a selected school
                    if (institutionId) {
                        //if instID is in url
                        self.selectedSchoolID = institutionId
                        //if multi-inst teacher we need to fetch the right model and classes so they match the url
                        if(self.institutionList.length > 1 && self.classes[0] && self.classes[0].institutionID !== institutionId) {
                            await self.switchInstitutions(institutionId)
                        }
                    } else if (self.institutionList.length > 1 && self.classes.filter(c => c.id != -1).length && self.classes.filter(c => c.id != -1)[0].institutionID) {
                        //if a multi-inst teacher, select school that is featured in classList
                        self.selectedSchoolID = self.classes.filter(c => c.id != -1)[0].institutionID
                    } else {
                        //else select first in institutionList
                        self.selectedSchoolID = self.institutionList[0].id
                    }
                    
                    institutionId = self.selectedSchoolID          
                    self.handleDeepLink()
                    //set class ID (we choose the first class if there is none in the URL)
                    parseInt(self.$route.query.class) && institutionId ? self.selectedClassID = parseInt(self.$route.query.class) : self.chooseFirstClass(self.classes)
                    self.studentsInSelectedClass.length < 51 ? self.paginationOptions.rowsPerPage = -1 : self.paginationOptions.rowsPerPage = 25
                    self.dataLoaded = true
                })()
            } else {
                // no active subs
                // this.chooseFirstClass(this.classes);
                this.dataLoaded = true
            }
        }
    }
</script>

<style scoped lang="less">
    .v-datatable thead th .header-icon {
        transform: none !important;
        opacity: 1 !important;
    }
    #headline {
        .display-1 {
            font-size: 1.2em !important;
        }
        .subheading {
            color: #9e9e9e;
            font-size: .6em !important;
        }
    }
    .whiteBG {
        background-color: white;
    }
    .fullwidth {
        width: 100%;
    }
    .helpCursor {
        cursor: help;
    }
    .modal {
        .headline {
            background: #F5F5F5;
            border-bottom: solid 1px #BDBDBD;
        }
    }
    .passwordSheild {
        font-size: 20px;
        padding: 2px 0 0;
    }
    .pointer {
        cursor: pointer;
    }
    .product-header {
        vertical-align : middle;
        img {
            height:20px;
        }
        i {
            margin: 2px 0 0 4px;
        }
    }
    .roundedCorners {
        border-radius: 10px;
    }
    .selectedClass{
        background: #e4f2fe;
    }
    .searchTitle{
        padding-right: 0px;
        padding-bottom: 4px;
    }
    .userSearch {
        margin-top: 0px;
        padding-top: 0px;
        max-width: 425px;
    }
    .v-alert {
        border-radius: 5px;
    }
    .action-bar {
        button {
            margin-left: 0px;
            margin-right: 0px;
        }
    }
    .code {
        font-family: monospace;
        color: firebrick;
        font-weight: bold;
        background-color: #f5f5f5;
        padding: 4px 6px;
        border-radius: 5px;
    }

    .emptyClassOption {
        color: #2196f3 !important;
        font-size: 16px;
        font-weight: 500;
        padding: 0 10px !important;
        .btn {
            margin: 10px 20px;
            background: #cbeaff;
            border-radius: 40px;
            padding: 20px 40px;
            min-height: 172px;
            .v-icon {
                font-size: 80px;
            }
        }
        :hover {
            background: #90CAF9;
            cursor: pointer;
        }
    }
    .leaf-avatar {
        border-radius: 0px;
        width: 300px;
        height: 30px;
    }
    .class-code-link:hover {
        text-decoration: underline;
    }
    .roster-box-bg {
        background-color: #f5f5f5;
        font-weight: 500;
        height: 36px
    }
</style>
