<template>
	<div id="gizmos">
		<div id="gizmos-bg"></div>
		<v-container grid-list-md>
			<v-layout v-if="halfCard" align-center>
				<v-flex sm12 md6 offset-md3 xl4 offset-xl4>
					<v-card class="elevation-24">
						<v-card-text>
							<img v-if="includeLogo" id="logo" src="../../assets/gizmos/img/logo.svg" alt="Explore Learning Reflex Logo">
							<router-view></router-view>
						</v-card-text>
					</v-card>
				</v-flex>
			</v-layout>
			<v-layout v-else-if="fullCard">
				<v-flex xl8 offset-xl2>
					<v-card class="elevation-24">
						<v-card-text>
							<router-view></router-view>
						</v-card-text>
					</v-card>
				</v-flex>
			</v-layout>
			<v-layout v-else>
				<!-- Cardless/Student View -->
				<v-flex sm10 offset-sm1 lg8 offset-lg2>
					<img v-if="includeLogo" id="logo" src="../../assets/reflex/img/logo-white.svg" alt="Explore Learning Reflex Logo">
					<router-view></router-view>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>

<script>
	export default {
		name: 'GizmosTheme',
		metaInfo: {
		titleTemplate: function(pageName){
			let environment = 'Gizmos'
			let title = ''
			if(this.$route.meta.flow && !this.$route.meta.hideFlowInTitle){
				let flow = this.$t('flow.'+this.$route.meta.flow)
				title = flow
			}
			if(pageName){
				if(title){
					title = title + ' - '
				}
				title = title + pageName
			}
			if(title){
				title = title + ' | '
			}
			title = title + environment
			return title
		},
		meta : [
			// Progressive Web App
			{ name: 'theme-color', content: '#000000' },
			{ name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
			// iOS
			{ name: 'apple-mobile-web-app-title', content: 'Gizmos', },
				{ name: 'apple-mobile-web-app-capable', content: 'yes' },
				{ name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
				// Microsoft
				{ name: 'application-name', content: 'Gizmos' },
				{ name: 'msapplication-TileColor', content: '#000000' },
				{ name: 'msapplication-navbutton-color', content: '#FFFFFF' },
				// { name: 'msapplication-square64x64logo', content: '/reporting/assets/icons/reflex-64x64.png' },
				// { name: 'msapplication-square70x70logo', content: '/reporting/assets/icons/reflex-70x70.png' },
				// { name: 'msapplication-TileImage', content: '/reporting/assets/icons/reflex-144x144.png' },
				// { name: 'msapplication-square150x150logo', content: '/reporting/assets/icons/reflex-150x150.png' },
				// { name: 'msapplication-square310x310logo', content: '/reporting/assets/icons/reflex-310x310.png' }
		],
		link: [
			// Favicons
			{ rel: 'icon', type:'image/x-icon', href:'/account/assets/img/gizmos/favicon.ico' },
			// { rel: 'icon', type:'image/x-icon', href:'/account/assets/img/reflex/favicon-32x32.ico' },
			// { rel: 'icon', type:'image/x-icon', href:'/account/assets/img/reflex/favicon-16x16.ico' },
			// Shortcuts
			//  { rel: 'shortcut icon', type: 'image/png', href: '/account/assets/img/reflex/reflex-16x16.png', sizes: '16x16' },
				// { rel: 'shortcut icon', type: 'image/png', href: '/account/assets/img/reflex/reflex-24x24.png', sizes: '24x24' },
				// { rel: 'shortcut icon', type: 'image/png', href: '/account/assets/img/reflex/reflex-32x32.png', sizes: '32x32' },
				// { rel: 'shortcut icon', type: 'image/png', href: '/account/assets/img/reflex/reflex-48x48.png', sizes: '48x48' },
				// { rel: 'shortcut icon', type: 'image/png', href: '/account/assets/img/reflex/reflex-96x96.png', sizes: '96x96' },
				// { rel: 'shortcut icon', type: 'image/png', href: '/account/assets/img/reflex/reflex-128x128.png', sizes: '128x128' },
				// { rel: 'shortcut icon', type: 'image/png', href: '/account/assets/img/reflex/reflex-196x196.png', sizes: '196x196' },
				// Progressive Web App
				// { rel: 'manifest', href: '/account/assets/manifest/reflex.json' },
			// iOS Specific Progressive Web App
			//  { rel: 'apple-touch-startup-image', href: '/account/assets/img/reflex/reflex-310x310.png', sizes: '310x310' },
				// { rel: 'apple-touch-icon', href: '/account/assets/img/reflex/reflex-57x57.png', sizes: '57x57' },
				// { rel: 'apple-touch-icon', href: '/account/assets/img/reflex/reflex-60x60.png', sizes: '60x60' },
				// { rel: 'apple-touch-icon', href: '/account/assets/img/reflex/reflex-72x72.png', sizes: '72x72' },
				// { rel: 'apple-touch-icon', href: '/account/assets/img/reflex/reflex-76x76.png', sizes: '76x76' },
				// { rel: 'apple-touch-icon', href: '/account/assets/img/reflex/reflex-114x114.png', sizes: '114x114' },
				// { rel: 'apple-touch-icon', href: '/account/assets/img/reflex/reflex-120x120.png', sizes: '120x120' },
				// { rel: 'apple-touch-icon', href: '/account/assets/img/reflex/reflex-144x144.png', sizes: '144x144' },
				// { rel: 'apple-touch-icon', href: '/account/assets/img/reflex/reflex-152x152.png', sizes: '152x152' },
				// { rel: 'apple-touch-startup-image', href: '/account/assets/img/reflex/reflex-310x310.png', sizes: '310x310' },
				// { rel: 'apple-touch-startup-image', href: '/account/assets/img/reflex/reflex-310x310.png', media: '(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' },
				// { rel: 'apple-touch-startup-image', href: '/account/assets/img/reflex/reflex-310x310.png', media: '(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' },
				// { rel: 'apple-touch-startup-image', href: '/account/assets/img/reflex/reflex-310x310.png', media: '(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)' },
				// { rel: 'apple-touch-startup-image', href: '/account/assets/img/reflex/reflex-310x310.png', media: '(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)' },
				// { rel: 'apple-touch-startup-image', href: '/account/assets/img/reflex/reflex-310x310.png', media: '(min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)' },
				// { rel: 'apple-touch-startup-image', href: '/account/assets/img/reflex/reflex-310x310.png', media: '(min-device-width: 834px) and (max-device-width: 834px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)' },
				// { rel: 'apple-touch-startup-image', href: '/account/assets/img/reflex/reflex-310x310.png', media: '(min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)' }
			]
		},
		computed: {
			fullCard: function(){
				return this.$route.meta.fullCard
			},
				includeLogo: function(){
				return this.$route.meta.logo && this.$store.state.displayOverrides.logo
			},
			halfCard: function(){
				return this.$route.meta.halfCard
			},
		},
		created(){
			let gizmosConfig = {
				enableHomeUsers: false
			}
			this.$store.commit('setProductConfig', gizmosConfig)
		}
	}
</script>

<style lang="less">
	// Extra Large
  #gizmos {
  	background: #00a0e3;
		padding: 80px 0;
		position: relative;
		#gizmos-bg {
			background: url("../../assets/gizmos/img/background.png") top center repeat;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0px;
			z-index: 0;
			opacity: .1;
		}
		#logo {
			margin: 0 0 40px;
			display: block;
		}
		.btn {
			margin-left: 0;
			margin-right: 0;
		}
		.v-card {
			border-radius: 10px;
			padding: 25px;
			footer {
		  	border-radius: 0 0 10px 10px;
		  	padding: 10px;
		  	line-height: 25px;
		  }
		}
		.checkbox > label {
			color: black;
		}
  }

  // Large
  @media only screen and (max-width: 1904px){
  	#gizmos{
			padding: 40px 0;
  	}
	}

	// Extra Small
  @media only screen and (max-width: 600px){
		#gizmos{
			padding: 0px;
			.container {
				padding: 0px;
			}
			.v-card {
				border-radius: 0px;
				width: 100%;
			}
		}
  }
</style>
