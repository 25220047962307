<script>
	import APIs from 'mixins/APIs'
	import Headline from 'components/layout/Headline'
	import EnvironmentDetector from 'mixins/EnvironmentDetector'
	import LoginDetector from 'mixins/LoginDetector'
	import { mapActions } from 'vuex'
	export default {
		name: 'GrantCreated',
		metaInfo : {
				title : 'Success'
			},
		components: {Headline},
		mixins: [APIs, EnvironmentDetector, LoginDetector],
		methods: {
			...mapActions('platformData/app', ['fetchModel']),
			...mapActions('platformData/profile', ['fetchProfile']),
		},
		created(){
			let grantCode = this.$store.state.grant.regCode
			let productID = this.getProductCode()
			if(grantCode){
				this.$http.post(this.getGrantCreationAPI(grantCode),null,this.jtiHeader).then(async response => {
					this.$store.commit('setJTI', this.decodeXclaims(response).jti)
					await this.fetchProfile()
					await this.fetchModel()
					this.$router.push({name:'Home', params:{newSub:{type:'grant',productIDs:[productID]}}})
				}, error => {
					let errorStatus = error.response.status
					let errorMessage = this.$t('error.unknown')
					if(error.response){
						if(errorStatus == 400){
						errorMessage = this.$t('error.invalidGrantCode')
						} else if(errorStatus == 404){
							errorMessage = this.$t('error.404')
						} else if(errorStatus == 403 || errorStatus == 406){
							errorMessage = this.$t('error.ineligibleAccount')
						}
					} else {
						errorMessage = this.$t('error.404')
					}
					this.$store.commit('setForwardingURL', {name:'GrantActivation', params:{grantCode}})
					this.$router.push({name:'Logout', query:{e:errorMessage}})
				})
			} else {
				this.$router.push({name:'GrantActivation'})
			}
		}
	}
</script>
