<template>
	<v-flex xs12 class="messaging" :class="classes">
		<v-alert :value="error" type="error" icon="warning" :outline="outline"><component :is="error" /></v-alert>
		<v-alert :value="success" type="success" icon="check_circle" :outline="outline"><component :is="success" /></v-alert>
		<v-alert :value="warning" type="warning" icon="priority_high" :outline="outline"><component :is="warning" /></v-alert>
		<v-alert :value="info" type="info" icon="info" :outline="outline"><component :is="info" /></v-alert>
	</v-flex>
</template>


<script>
	export default {
		name: 'Messaging',
		props: {
			classes: String,
			outline: {
				type: Boolean,
				default: false
			}
		},
		data(){
			return {
				queryStringOverride : 'HIDE'
			}
		},
		computed: {
			error() {
				let msg = null
				if(this.$store.state.messaging.error != this.queryStringOverride){
					msg = this.$route.query.e || this.$store.state.messaging.error
				}
				if (msg) {
					if(this.$i18n.messages[this.$i18n.locale].error[msg]){
						msg = this.$i18n.messages[this.$i18n.locale].error[msg]
					}
					return {template:'<span>'+msg+'</span>'}
				} else {
					return false
				}
			},
			success() {
				let msg = null
				if(this.$store.state.messaging.success != this.queryStringOverride){
					msg = this.$route.query.s || this.$store.state.messaging.success
				}
				if (msg) {
					if(this.$i18n.messages[this.$i18n.locale].success[msg]){
						msg = this.$i18n.messages[this.$i18n.locale].success[msg]
					}
					return {template:'<span>'+msg+'</span>'}
				} else {
					return false
				}
			},
			warning() {
				let msg = null
				if(this.$store.state.messaging.warning != this.queryStringOverride){
					msg = this.$route.query.w || this.$store.state.messaging.warning
				}
				if (msg) {
					if(this.$i18n.messages[this.$i18n.locale].warning[msg]){
						msg = this.$i18n.messages[this.$i18n.locale].warning[msg]
					}
					return {template:'<span>'+msg+'</span>'}
				} else {
					return false
				}
			},
			info() {
				let msg = null
				if(this.$store.state.messaging.info != this.queryStringOverride){
					msg = this.$route.query.i || this.$store.state.messaging.info
				}
				if (msg) {
					if(this.$i18n.messages[this.$i18n.locale].info[msg]){
						msg = this.$i18n.messages[this.$i18n.locale].info[msg]
					}
					return {template:'<span>'+msg+'</span>'}
				} else {
					return false
				}
			}
		},
		watch: {
			$route : function(){
				this.clearMessages()
			}
		},
		destroyed(){
			this.clearMessages()
		},
		methods: {
			clearMessages(){
				this.$store.commit('setMessageError', null)
				this.$store.commit('setMessageSuccess', null)
				this.$store.commit('setMessageWarning', null)
				this.$store.commit('setMessageInfo', null)
			}
		}
	}
</script>

<style scoped lang="less">
	.messaging {
		width: 100%;
	}
	.v-alert{
		border-radius: 5px;
	}
	
</style>
