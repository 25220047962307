export default {
  // Common
  form : {
    username : 'Nom d\'utilisateur', //Username
    teacherUsername : '', //Teacher Username
    password : 'Mot de passe', //Password
    passwordConfirm : '', //Confirm Password
    email : '', //Email
    passwordNew : '', //New Password
    forgotPassword : '', //Forget your password?
    submit : 'Soumettre' //Submit
  },
  navigation : {
    back : '' //Back
  },
  error : {
    404 : 'Pas d\'accès à Internet trouvé', //Unable to connect to server. Check Internet connection.
    unknown : 'Erreur Inconnue', //Unknown Error
    invalidCredentials : 'Nom dutilisateur ou mot de passe non valide', //Invalid Username or Password
    invalidPassword : 'Mot de passe incorrect.', //Invalid Password
    invalidUsername : 'Utilisateur introuvable.', //User Not Found
    invalidEmail : '', //No Account Associated With That Email
    invalidCode: '', //Invalid Reset Code
    unmatchingPasswords : '' //Password Fields Do Not Match
  },
  success : {
    emailSent : '', //Check your email for further information about recovering/resetting your password.
    passwordReset : '' //Password successfully reset.
  },
  // Component Specific
  loginChoice : {
    students : 'Élèves', //Students
    educators : 'Éducateurs et Parents', //Educators and Parents
    educatorsSub : 'Rapports et Administration' //Reports and Administration
  },
  teacherLogin : {
    header : 'Connexion' //Educator/Parent Login
  },
  chooseAccount : {
    header : '', //Password Reset
    instruction : '' //Select Account
  },
  resetPassword: {
    back : '' //Wrong Account
  },
  studentLogin : {
    header : 'Connexion' //Student Login
  },
  chooseClass : {
    header : 'Choisissez votre classe', //Select Your Class
    subHeader : 'Enseignant:', //Teacher:
    back : 'Ce n\'est pas mon enseignant' //Wrong Teacher?
  },
  chooseStudent : {
    header : 'Sélectionnez votre nom', //Select Your Name
    subHeader : 'Classe', //Class:
    back : 'Ce n\'est pas ma classe' //Wrong Class?
  },
  studentPassword : {
    header : 'Entrez le mot de passe', //Enter Password
    subHeader : 'Étudiant', //Student:
    back : 'Ce n\'est pas moi' //Not You?
  }
}
