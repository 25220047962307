import Vue from 'vue'
import Vuex from 'vuex'
import ProductInfo from 'mixins/ProductInfo'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		productConfig: {
			enableHomeUsers: true,
		},
		displayOverrides: {
			logo: true,
			passwordResetBackBtn: true,
		},
		formData: {
			contactTime: null,
			email: null,
			firstName: null,
			lastName: null,
			mobile: null,
			phone: null,
			phoneExt: null,
			productsRegistered: [],
			regCode: null,
			regCodeType: null,
			resetAccount: null,
			titleID: null,
			trialPromo: null,
			username: null,			
		},
		grant: {
			principalName: null,
			principalEmail: null,
			referrer: null,
			priorGrant: null,
			questions: [],
			regCode: null,
			international: false,
			grantOpen: true
		},
		jti: null,
    	messaging: {
			error: null,
			success: null,
			warning: null,
			info: null,
		},
		navigation: {
			forwardingURL: null,
			allowConfirm: false,
			disableLogin: false,
			ignoreReferrer: false,
			accountType: '',
		},
		paymentInfo: {
			billingAddress: {
			address1 : '',
			address2 : '',
			city : '',
			postalCode : '',
			state : '',
			countryCode : '',
			countryName : ''
			},
			cardInfo: {
				firstName : '',
				lastName : '',
				middleInitial : '',
				cardNumber : '',
				cardCode : '',
				expMonth : '',
				expYear : '',
				cardType : ''
			},
			sameAddress: true,
			shippingAddress: {
				address1 : '',
				address2 : '',
				city : '',
				postalCode : '',
				state : '',
				countryCode : '',
				countryName : ''
			}
		},
		schoolInfo: {
			countryCode: null,
			gradeLevels: [],
			parentPid: null,
			pid: null,
			schoolCount: null,
			schoolType: null,
			stateCode: null,
			studentCount: null,
			teacherCount: null,
			unknownName: null,
			titles: [],
			zip: null
		},
		servicesForm: {
			comments: null,
			contactTime: null,
			email: null,
			firstName: null,
			lastName: null,
			mobile: null,
			phone: null,
			phoneExt: null,
			product: null,
			titleID: null,
		},
		store: {
			orderHistory: null,
			subscriptions: null,
			nonProductSubscriptions: [],
			subscriptionsLoaded: false
		},
		studentLogin: {
			class: {},
			classes: [],
			productId: null,
			responseData: null,
			students: [],
			student: {},
			teacherName: null,
		},
		tracking : {
			mktTrk : null,
			munchkinID : null,
			utm_campaign : null,
			utm_content : null,
			utm_medium : null,
			utm_source : null,
			utm_term : null,
			utm_type : null,
			fbclid : null,
			gclid : null,
			isPendoInitialized: false,
		},
		platform: {},
		classMgmt: {
			isAdmin: null,
			modelLoaded: false
		}
	},
	mutations: {
		setAllowConfirm(state, allow){
			state.navigation.allowConfirm = allow
		},
		setBillingAddress(state, address){
			state.paymentInfo.billingAddress = address
		},
		setCardInfo(state, cardInfo){
			state.paymentInfo.cardInfo = cardInfo
		},
		setClass(state, c){
			state.studentLogin.class = c
			state.studentLogin.students = c.studentList.sort(function(a,b){
				if (a.name.toLowerCase() > b.name.toLowerCase()){
					return 1
				}
				if (a.name.toLowerCase() < b.name.toLowerCase()){
					return -1
				}
				return 0
			})
			// state.studentLogin.students.forEach(student => {
			// 	if(student.name.length-student.name.lastIndexOf(' ')>3){
			// 		student.name=student.name.substring(0,student.name.lastIndexOf(' ')+2)+'.'
			// 	}
			// })
		},
		setContactTime(state, contactTime){
			state.formData.contactTime = contactTime
		},
		setCountry(state, countryCode){
			state.schoolInfo.countryCode = countryCode
		},
		setDisableLogin(state, flag){
			state.navigation.disableLogin = flag
		},
		setDisplayLogo(state, flag){
			state.displayOverrides.logo = flag
		},
		setEmail(state, email){
			if(email){
			state.formData.email = email.toLowerCase().trim()
			}else{
				state.formData.email = null
			}
		},
		setFBCLID(state, fbclid){
			state.tracking.fbclid = fbclid
			state.tracking.utm_source = 'facebook'
		},
		setFirstName(state, firstName){
			state.formData.firstName = firstName
		},
		setForwardingURL(state, URL){
			state.navigation.forwardingURL = URL
		},
		setGCLID(state, gclid){
			state.tracking.gclid = gclid
			state.tracking.utm_source = 'google'
		},
		setGradeLevels(state, gradeLevels){
			state.schoolInfo.gradeLevels = gradeLevels
		},
		setGrantOpen(state, open){
			state.grant.grantOpen = open
		},
		setGrantQuestion(state, questionObject){
			state.grant.questions[questionObject.id-1] = questionObject
		},
		setGrantReferrer(state, referrer){
			state.grant.referrer = referrer
		},
		setGrantRegCode(state, regCode){
			state.grant.regCode = regCode
		},
		setIgnoreReferrer(state, ignore){
			state.navigation.ignoreReferrer = ignore
		},
		setInternationalGrant(state, international){
			state.grant.international = international
		},
		setIsAdmin(state, isAdmin) {
			state.classMgmt.isAdmin = isAdmin
		},
		setIsPendoInitialized(state, isPendoInitialized){
			state.tracking.isPendoInitialized = isPendoInitialized
		},
		setJTI(state, jti){
			state.jti = jti
		},
		setLastName(state, lastName){
			state.formData.lastName = lastName
		},
		setMktTrk(state, mktTrk){
			state.tracking.mktTrk = mktTrk
		},
		setMobile(state, mobile){
			state.formData.mobile = mobile
		},
		setModelLoaded(state, modelLoaded){
			state.classMgmt.modelLoaded = modelLoaded
		},
		setMunchkinID(state, munchkinID){
			state.tracking.munchkinID = munchkinID
		},
		setMessageError(state, error){
			state.messaging.error = error
		},
		setMessageInfo(state, info){
			state.messaging.info = info
		},
		setMessageSuccess(state, success){
			state.messaging.success = success
		},
		setMessageWarning(state, warning){
			state.messaging.warning = warning
		},
		setMigrationStatus(state, migrationObject){
			state.formData.migrationStatus = migrationObject
		},
		setNavAccountType(state, accountType){
			if(accountType == 't'){
				accountType = 'teacher'
			} else if(accountType == 'h'){
				accountType = 'home'
			}
			state.navigation.accountType = accountType
		},
		setStudent(state,student){
			state.studentLogin.student = student
		},
		setStudentCount(state, studentCount){
			state.schoolInfo.studentCount = studentCount
		},
		setOrderHistory(state, orderHistory){
			state.store.orderHistory = orderHistory
		},
		setParentPID(state, pid){
			state.schoolInfo.parentPid = pid
		},
		setPasswordResetBackBtn(state, backBtn){
			state.displayOverrides.passwordResetBackBtn = backBtn
		},
		setPID(state, pid){
			state.schoolInfo.pid = pid
		},
		setPhone(state, phone){
		state.formData.phone = phone
		},
		setPhoneExt(state, phoneExt){
		state.formData.phoneExt = phoneExt
		},
		setPlatform(state, platform){
			state.platform = platform
		},
		setPrincipalEmail(state, email){
			state.grant.principalEmail = email
		},
		setPrincipalName(state, name){
			state.grant.principalName = name
		},
		setPriorGrant(state, priorGrant){
			state.grant.priorGrant = priorGrant
		},
		setProductConfig(state, configObject){
			state.productConfig = configObject
		},
		setProductId(state, productId){
			state.studentLogin.productId = productId
		},
		setProductsRegisteredList(state, list){
			state.formData.productsRegistered = list
		},
		setSchoolType(state, schoolType){
			state.schoolInfo.schoolType = schoolType.toLowerCase()
		},
		setRegCode(state, regCode){
			state.formData.regCode = regCode
		},
		setRegCodeType(state, regCodeType){
			state.formData.regCodeType = regCodeType
		},
		setResetAccount(state, account){
			state.formData.resetAccount = account
		},
		setResponseData(state, data){
			state.studentLogin.responseData = data
			state.studentLogin.teacherName = data.teacherDisplayName
			let classes = []
			for (let classID in data.classes ){
				if(data.classes[classID].studentList.length){
					classes.push(data.classes[classID])
				}
			}
			classes.sort(function(a,b){
				if (a.className.toLowerCase().trim() > b.className.toLowerCase().trim()){
					return 1
				}
				if (a.className.toLowerCase().trim() < b.className.toLowerCase().trim()){
					return -1
				}
				return 0
			})
			state.studentLogin.classes = classes
		},
		setSameAddress(state, sameAddress){
			state.paymentInfo.sameAddress = sameAddress
		},
		setServicesFormComments(state,comments){
			state.servicesForm.comments = comments
		},
		setServicesFormContactTime(state, contactTime){
			state.servicesForm.contactTime = contactTime
		},
		setServicesFormEmail(state, email){
			state.servicesForm.email = email
		},
		setServicesFormFirstName(state, firstname){
			state.servicesForm.firstName = firstname
		},
		setServicesFormLastName(state, lastname){
			state.servicesForm.lastName = lastname
		},
		setServicesFormMobile(state, mobile){
			state.servicesForm.mobile = mobile
		},
		setServicesFormPhone(state, phone){
			state.servicesForm.phone = phone
		},
		setServicesFormPhoneExt(state, phoneExt){
			state.servicesForm.phoneExt = phoneExt
		},
		setServicesFormProduct(state, product){
			state.servicesForm.product = product
		},
		setServicesFormTitleID(state, titleID){
			state.servicesForm.titleID = titleID
		},
		setShippingAddress(state, address){
			state.paymentInfo.shippingAddress = address
		},
		setState(state, stateCode){
			state.schoolInfo.stateCode = stateCode
		},
		setSubscriptions(state, subscriptions){
			state.store.subscriptions = subscriptions
			subscriptions.forEach(function(sub){
				const productExclusionList = [10,100,101]
				if(sub.active && !productExclusionList.includes(sub.productID)){
					let product = ProductInfo.data().productSettings[sub.productID].displayName.toLowerCase()
				} else if(productExclusionList.includes(sub.productID)){
					if(!state.store.nonProductSubscriptions.includes(sub.productID)){
						state.store.nonProductSubscriptions.push(sub.productID)
					}
				}
			})
			state.store.subscriptionsLoaded = true
		},
		setTitleID(state, titleID){
			state.formData.titleID = titleID
		},
		setTitles(state, titles){
			state.schoolInfo.titles = titles
		},
		setTrialPromoCode(state, promoCode){
			state.formData.trialPromo = promoCode
		},
		setUnknownName(state, name){
			state.schoolInfo.unknownName = name
		},
		setUserHash(state, hash){
			state.formData.userHash = hash
		},
		setUsername(state, username){
			state.formData.username = username
		},
		setUtmCampaign(state, utm_campaign){
			state.tracking.utm_campaign = utm_campaign
		},
		setUtmContent(state, utm_content){
			state.tracking.utm_content = utm_content
		},
		setUtmMedium(state, utm_medium){
			state.tracking.utm_medium = utm_medium
		},
		setUtmSource(state, utm_source){
			state.tracking.utm_source = utm_source
		},
		setUtmTerm(state, utm_term){
			state.tracking.utm_term = utm_term
		},
		setUtmType(state, utm_type){
			state.tracking.utm_type = utm_type
		},
		setZip(state, zip){
			state.schoolInfo.zip = zip
		}
	},
	getters : {
		getGoogleTracking(state){
			let tracking = {}
			for(let key in state.tracking){
				if(key.indexOf('utm_')==0 && state.tracking[key]){
					tracking[key] = state.tracking[key]
				}
			}
			return tracking
		},
		getIsClassCodeEnabledUser(state){
			let ccEnabled = false
			let subCCEnabled = false
			let hasCCEnabledProduct = false
			for (const institution in state.platformData.institutions.institutions) {				
				ccEnabled = ccEnabled || state.platformData.institutions.institutions[institution].isClassCodeEnabled
			}
			for (const sub in state.platformData.subscriptions.subscriptions) {
				let settings = state.platformData.subscriptions.subscriptions[sub].settings
				subCCEnabled = !settings.isRosterEnabled && !settings.isSsoLtiEnabled && !settings.isSsoSamlEnabled && !settings.isSsoCleverEnabled
				const IDArray = Object.keys(ProductInfo.data().productSettings).filter(key => ProductInfo.data().productSettings[key].autoEnroll == 1).map(([key]) => +key);
				hasCCEnabledProduct = hasCCEnabledProduct || IDArray.includes(state.platformData.subscriptions.subscriptions[sub].productId)
			}
			return ccEnabled && subCCEnabled && hasCCEnabledProduct
		}
	}
})

export default store
