<template>
	<div id="reflex" v-bind:class="{fullPage}">
		<v-container grid-list-md>
			<v-layout v-if="halfCard" align-center>
				<v-flex sm12 md6 lg5 xl3 offset-xl2>
					<v-card class="elevation-24">
						<v-card-text>
							<img v-if="includeLogo" id="logo" src="../../assets/reflex/img/logo.svg" alt="Explore Learning Reflex Logo">
							<router-view></router-view>
						</v-card-text>
					</v-card>
				</v-flex>
				<v-flex md6 lg7 xl4 offset-xl1 fill-height class="text-xs-right hidden-sm-and-down">
					<img id="white-logo" src="../../assets/reflex/img/splash.png" alt="Reflex Logo">
				</v-flex>
			</v-layout>
			<v-layout v-else-if="fullCard">
				<v-flex xl8 offset-xl2>
					<v-card class="elevation-24">
						<v-card-text>
							<router-view></router-view>
						</v-card-text>
					</v-card>
				</v-flex>
			</v-layout>
			<v-layout v-else>
				<!-- Cardless/Student View -->
				<v-flex sm10 offset-sm1 lg8 offset-lg2>
					<img v-if="includeLogo" id="logo" src="../../assets/reflex/img/logo-white.svg" alt="Explore Learning Reflex Logo">
					<router-view></router-view>
				</v-flex>
			</v-layout>
		</v-container>
	</div>
</template>

<script>
	export default {
		name: 'ReflexTheme',
		metaInfo: {
			titleTemplate: function(pageName){
				let environment = 'Reflex'
				let title = ''
				if(this.$route.meta.flow && !this.$route.meta.hideFlowInTitle){
					let flow = this.$t('flow.'+this.$route.meta.flow)
					title = flow
				}
				if(pageName){
					if(title){
						title = title + ' - '
					}
					title = title + pageName
				}
				if(title){
					title = title + ' | '
				}
				title = title + environment
				return title
			},
			meta : [
				// Progressive Web App
				{ name: 'theme-color', content: '#000000' },
				{ name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
				// iOS
				{ name: 'apple-mobile-web-app-title', content: 'Reflex', },
				{ name: 'apple-mobile-web-app-capable', content: 'yes' },
				{ name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
				// Microsoft
				{ name: 'application-name', content: 'Reflex' },
				{ name: 'msapplication-TileColor', content: '#000000' },
				{ name: 'msapplication-navbutton-color', content: '#FFFFFF' },
				{ name: 'msapplication-square64x64logo', content: '/account/assets/img/reflex/reflex-64x64.png' },
				{ name: 'msapplication-square70x70logo', content: '/account/assets/img/reflex/reflex-70x70.png' },
				{ name: 'msapplication-TileImage', content: '/account/assets/img/reflex/reflex-144x144.png' },
				{ name: 'msapplication-square150x150logo', content: '/account/assets/img/reflex/reflex-150x150.png' },
				{ name: 'msapplication-square310x310logo', content: '/account/assets/img/reflex/reflex-310x310.png' }
			],
			link: [
				// Favicons
				{ vmid: 'favicon-l', rel: 'icon', type:'image/x-icon', href:'/account/assets/img/reflex/favicon-48x48.ico' },
				{ vmid: 'favicon-m', rel: 'icon', type:'image/x-icon', href:'/account/assets/img/reflex/favicon-32x32.ico' },
				{ vmid: 'favicon-s', rel: 'icon', type:'image/x-icon', href:'/account/assets/img/reflex/favicon-16x16.ico' },
				// Shortcuts
				{ rel: 'shortcut icon', type: 'image/png', href: '/account/assets/img/reflex/reflex-16x16.png', sizes: '16x16' },
				{ rel: 'shortcut icon', type: 'image/png', href: '/account/assets/img/reflex/reflex-24x24.png', sizes: '24x24' },
				{ rel: 'shortcut icon', type: 'image/png', href: '/account/assets/img/reflex/reflex-32x32.png', sizes: '32x32' },
				{ rel: 'shortcut icon', type: 'image/png', href: '/account/assets/img/reflex/reflex-48x48.png', sizes: '48x48' },
				{ rel: 'shortcut icon', type: 'image/png', href: '/account/assets/img/reflex/reflex-96x96.png', sizes: '96x96' },
				{ rel: 'shortcut icon', type: 'image/png', href: '/account/assets/img/reflex/reflex-128x128.png', sizes: '128x128' },
				{ rel: 'shortcut icon', type: 'image/png', href: '/account/assets/img/reflex/reflex-196x196.png', sizes: '196x196' },
				// Progressive Web App
				{ rel: 'manifest', href: '/account/assets/manifest/reflex.json' },
				// iOS Specific Progressive Web App
				{ rel: 'apple-touch-icon', href: '/account/assets/img/reflex/reflex-57x57.png', sizes: '57x57' },
				{ rel: 'apple-touch-icon', href: '/account/assets/img/reflex/reflex-60x60.png', sizes: '60x60' },
				{ rel: 'apple-touch-icon', href: '/account/assets/img/reflex/reflex-72x72.png', sizes: '72x72' },
				{ rel: 'apple-touch-icon', href: '/account/assets/img/reflex/reflex-76x76.png', sizes: '76x76' },
				{ rel: 'apple-touch-icon', href: '/account/assets/img/reflex/reflex-114x114.png', sizes: '114x114' },
				{ rel: 'apple-touch-icon', href: '/account/assets/img/reflex/reflex-120x120.png', sizes: '120x120' },
				{ rel: 'apple-touch-icon', href: '/account/assets/img/reflex/reflex-144x144.png', sizes: '144x144' },
				{ rel: 'apple-touch-icon', href: '/account/assets/img/reflex/reflex-152x152.png', sizes: '152x152' },
				{ rel: 'apple-touch-startup-image', href: '/account/assets/img/reflex/reflex-310x310.png', sizes: '310x310' },
				{ rel: 'apple-touch-startup-image', href: '/account/assets/img/reflex/reflex-310x310.png', media: '(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' },
				{ rel: 'apple-touch-startup-image', href: '/account/assets/img/reflex/reflex-310x310.png', media: '(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)' },
				{ rel: 'apple-touch-startup-image', href: '/account/assets/img/reflex/reflex-310x310.png', media: '(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)' },
				{ rel: 'apple-touch-startup-image', href: '/account/assets/img/reflex/reflex-310x310.png', media: '(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)' },
				{ rel: 'apple-touch-startup-image', href: '/account/assets/img/reflex/reflex-310x310.png', media: '(min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)' },
				{ rel: 'apple-touch-startup-image', href: '/account/assets/img/reflex/reflex-310x310.png', media: '(min-device-width: 834px) and (max-device-width: 834px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)' },
				{ rel: 'apple-touch-startup-image', href: '/account/assets/img/reflex/reflex-310x310.png', media: '(min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)' }
			]
		},
		computed: {
			fullCard: function(){
				return this.$route.meta.fullCard
			},
			fullPage: function(){
				return this.$route.meta.fullPage
			},
			includeLogo: function(){
				return this.$route.meta.logo && this.$store.state.displayOverrides.logo
			},
			halfCard: function(){
				return this.$route.meta.halfCard
			}
		}
	}
</script>

<style lang="less">
	// Extra Large
  #reflex {
		background: #6BB74C;
		padding: 80px 0;
		&.fullPage {
			height: 100%;
			text-align: center;
			color: white;
			button.white {
				color: #6BB74C;
			}
			.btn--flat {
				background-color: rgba(255,255,255,.2) !important;
			}
			a {
				text-decoration: none;
			}
			.headline, .subheading{
				color: white !important;
				border-bottom: solid 1px rgba(255, 255, 255, .3);
				text-align: left;
				padding-bottom: 4px;
				&>.headline,.subheading {
					border-bottom: none;
				}
				&>.slash {
					display: none;
				}
				&.subheading {
					float: right;
					padding-top: 12px;
				}
			}
			.border-free {
				border: none !important;
				*{
					border: none !important;
				}
			}
			#logo{
				margin: 20px auto 60px;
			}
			.v-alert {
				text-align: left;
			}
		}
		#logo {
			margin: 0 0 40px;
			display: block;
		}
		#white-logo {
			max-width: 90%;
		}
		.btn {
			margin-left: 0;
			margin-right: 0;
		}
		.v-card {
			border-radius: 10px;
			padding: 25px;
			overflow: hidden;
			footer {
				border-radius: 0 0 10px 10px;
				padding: 10px;
				line-height: 25px;
			}
		}
		.checkbox > label {
			color: black;
		}
  }

  // Large
  @media only screen and (max-width: 1904px){
  	#reflex{
			padding: 40px 0;
  		}
	}

	// Extra Small
  @media only screen and (max-width: 600px){
		#reflex{
			padding: 0px;
			&.fullPage {
				padding: 40px;
			}
			.container {
				padding: 0px;
			}
			.v-card {
				border-radius: 0px;
				width: 100%;
			}
		}
  }
</style>
