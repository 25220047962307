import PDFGenerator from 'utils/PDFGenerator'

export default {
  data() {
    const vm = this;
    const ReportPrinter = {
        doc: null,
        rendering: false,
        printing: false,
        downloading: false,
    };

    ReportPrinter.renderDoc = async function (name, options) {
        this.rendering = true;
        this.doc = await PDFGenerator[name](options);
        this.rendering = false;
    };

    ReportPrinter.print = async function () {
        this.printing = true;

        if (document.documentMode || /Edge/.test(navigator.userAgent)) {
            await ReportPrinter.download();
        } else {
            await this.doc.print().catch(e => {
            if (e.message === 'print method not supported') {
                let message = vm.$t('toast.print_warning');
                vm.$store.dispatch('addToast', { message, type: 'warning' });
            }
            });
        }

        this.printing = false;
    };

    ReportPrinter.download = async function (filename) {
        this.downloading = true;
        await this.doc.download(filename || 'Reflex Report.pdf');
        this.downloading = false;
    };

    return { ReportPrinter };
  }
}
