<template>
	<v-layout wrap>
    <Headline headline="Grant Created"></Headline>
  	<v-flex xs12 mb-3>You have successfully created a grant for {{profile.firstName}} {{profile.lastName}} ({{profile.email}}).</v-flex>
		<v-flex xs12 text-xs-center>
  		<v-btn large color="primary" class="mx-1" :to="{name:'Home'}">
				Done
			</v-btn>
		</v-flex>
   </v-layout>
</template>

<script>
	import APIs from 'mixins/APIs'
  	import Headline from 'components/layout/Headline'
	import { mapGetters } from 'vuex'
	export default {
		name: 'PreAprovedGrantCreated',
		metaInfo : {
			title : 'Success'
		},
		components: {Headline},
		mixins: [APIs],
		computed: {
			...mapGetters('platformData/profile', ['profile']),
		},
		created(){
			if(!this.$store.state.navigation.allowConfirm){
				this.$router.replace({name:'PreApprovedSchoolInfo'})
			}
		}
	}
</script>
