<template>
    <UserManagmentModal
        :value="value"
        :headline="$t('classMgmt.filterHeader')"
        @closeDialogs="closeDialogs()"
    > 
        <v-card-text>
            <p>{{$t('classMgmt.onlyDisplayStudentsMeeting')}}</p>
            <v-layout wrap>
                <v-flex xs11>
                    <v-select
                        v-model="filterGender"
                        label="Gender"
                        :items="genders"
                        item-text="label"
                        item-value="id"
                    ></v-select>
                </v-flex>
                <v-flex xs1 class="cancelFilter">
                    <v-btn flat icon color="error" :disabled="!Number.isInteger(filterGender)" @click="clearFilters(['filterControl.gender'])">
                        <v-icon>cancel</v-icon>
                    </v-btn>
                </v-flex>
                <v-flex xs11>
                    <v-select
                        v-model="filterEngPro"
                        label="Limited English Proficiency"
                        :items="yesNoNA"
                        item-text="label"
                        item-value="id"
                    ></v-select>
                </v-flex>
                <v-flex xs1 class="cancelFilter">
                    <v-btn flat icon color="error" :disabled="!Number.isInteger(filterEngPro)" @click="clearFilters(['filterControl.engPro'])">
                        <v-icon>cancel</v-icon>
                    </v-btn>
                </v-flex>
                <v-flex xs11>
                    <v-select
                        v-model="filterEconDis"
                        label="Economically Disadvantaged"
                        :items="yesNoNA"
                        item-text="label"
                        item-value="id"
                    ></v-select>
                </v-flex>
                <v-flex xs1 class="cancelFilter">
                    <v-btn flat icon color="error" :disabled="!Number.isInteger(filterEconDis)" @click="clearFilters(['filterControl.econDis'])">
                        <v-icon>cancel</v-icon>
                    </v-btn>
                </v-flex>
                <v-flex xs11>
                    <v-select
                        v-model="filterSpecEd"
                        label="Special Ed Status"
                        :items="yesNoNA"
                        item-text="label"
                        item-value="id"
                    ></v-select>
                </v-flex>
                <v-flex xs1 class="cancelFilter">
                    <v-btn flat icon color="error" :disabled="!Number.isInteger(filterSpecEd)" @click="clearFilters(['filterControl.specEd'])">
                        <v-icon>cancel</v-icon>
                    </v-btn>
                </v-flex>
                <v-flex xs11>
                    <v-select
                        v-model="filterEthnicity"
                        label="Race / Ethnicity"
                        :items="ethnicities"
                        item-text="label"
                        item-value="id"
                        multiple
                        chips
                    ></v-select>
                </v-flex>
                <v-flex xs1 class="cancelFilter">
                    <v-btn flat icon color="error" :disabled="!Array.isArray(filterEthnicity) || !filterEthnicity.length" @click="clearFilters(['filterControl.ethnicity'])">
                        <v-icon>cancel</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-card-text>
        <v-card-actions>
            <v-btn @click="clearFilters()" class="elevation-0" flat>
                <v-icon>cancel</v-icon>&nbsp;{{$t('classMgmt.clearAllFilters')}}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="closeDialogs()" class="elevation-0" color="primary">
                <v-icon>check</v-icon>&nbsp;{{$t('classMgmt.accept')}}
            </v-btn>
        </v-card-actions>
</UserManagmentModal>
</template>

<script>
    import Constants from 'mixins/Constants'
    import UserManagmentModal from 'components/layout/UserManagmentModal'

	export default {
        name: 'FilterModal',
        components: { UserManagmentModal },
        mixins: [Constants],
        props: {
            value: Boolean,
        },
        data() {
            return {
                filterEconDis: null,
                filterEngPro: null,
                filterEthnicity: null,
                filterGender: null,
                filterSpecEd: null,
            }
        },
		computed : {
             platform(){
                 return this.$store.state.platform
            }
        },
        watch: {
            filterEconDis : function(val){
                this.addFilter('filterControl.econDis', val)
            },
            filterEngPro : function(val){
                this.addFilter('filterControl.engPro', val)
            },
            filterEthnicity : function(val){
                this.addFilter('filterControl.ethnicity', val)
            },
            filterGender : function(val){
                this.addFilter('filterControl.gender', val)
            },
            filterSpecEd : function(val){
                this.addFilter('filterControl.specEd', val)
            },
        },
        methods: {
            addFilter(name, value){
                this.$emit('addFilter', {name: name, value: value})
            }, 
            clearFilters(filters){
                //leave null to clear all filters
                this.$emit('clearFilters', filters)
                
                if (!filters)
                {
                    this.filterEconDis = null
                    this.filterEngPro = null
                    this.filterEthnicity = null
                    this.filterGender = null
                    this.filterSpecEd = null
                }
                else
                {
                    this.filterEconDis = filters.includes('filterControl.econDis') ? null : this.filterEconDis
                    this.filterEngPro = filters.includes('filterControl.engPro') ? null : this.filterEngPro
                    this.filterEthnicity = filters.includes('filterControl.ethnicity') ? null : this.filterEthnicity
                    this.filterGender = filters.includes('filterControl.gender') ? null : this.filterGender
                    this.filterSpecEd = filters.includes('filterControl.specEd') ? null : this.filterSpecEd
                }
            },
            closeDialogs() {
                this.$emit('closeDialogs')
            },
        }
	}
</script>

<style lang="less" scoped>
    .cancelFilter{
        padding-top: 10px;
    }
</style>