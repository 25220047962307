<template>
    <UserManagmentModal
        :value="value"
        :headline="$tc('classMgmt.addStudentFromRoster', 2)"
        id="StudentRosterModal"
        size="650"
        @closeDialogs="closeDialogs()"
    > 
        <v-card-text id="StudentRosterModalRosterPane">
            <Messaging outline/>
            <v-layout>
                <v-flex xs6>
                    <v-text-field
                        v-model="searchAllStudents"
                        prepend-icon="search"
                        clearable
                        label="Search"
                    ></v-text-field>
                </v-flex>
                <v-flex xs6 class="pl-2">
                    <v-select
                        v-model="rosterGradeLevelFilterID"
                        :items="gradeLevelIdFilter"
                        item-text="label"
                        item-value="id"
                        :label="'Filter by '+$t('classMgmt.gradeLevel')"
                    ></v-select>
                </v-flex>
            </v-layout>
            <v-data-table 
                v-model="rosterSelectedStudents"
                :headers="allStudentsHeaders"
                :items="studentsNotInSelectedClassFilterByGradeLevel"
                item-key="id"
                :search="searchAllStudents"
                :pagination.sync="paginationOptions"
                :rows-per-page-items="rowsPerPageItems('modal')"
                :hide-actions="filterByGradeLevel(studentsNotInSelectedClass, rosterGradeLevelFilterID).length<rowsPerPageItems('modal')[0]"
                select-all
                :no-data-text="$t('classMgmt.noStudentRosterData')"
                class="elevation-2 mb-3 roundedCorners"
                must-sort
                dense
                ref="rosterTable"
            >
                <template #headers="props">
                    <tr>
                        <th>
                            <v-checkbox
                                :input-value="props.all"
                                :indeterminate="props.indeterminate"
                                primary
                                hide-details
                                @click.stop="toggleAll('rosterSelectedStudents',filteredTableData('rosterTable'))"
                            ></v-checkbox>
                        </th>
                        <th
                            v-for="header in props.headers"
                            :key="header.text"
                            :class="['column',
                                header.sortable ? 'sortable':'', 
                                paginationOptions.descending ? 'desc' : 'asc', 
                                header.sortable && header.value === paginationOptions.sortBy ? 'active' : '',
                                'text-xs-'+header.align,
                                header.icon ? 'px-0':'']"
                            @click="if(header.sortable)changeSort(header.value,paginationOptions)"
                        >
                            <span v-if="header.icon">
                                <v-icon small class="header-icon pt-1">{{header.icon}}</v-icon>
                            </span>
                            <span v-else>{{ header.text }}</span>
                            <v-icon v-if="header.sortable" small>arrow_upward</v-icon>
                        </th>
                    </tr>
                </template>
                <template #items="student">
                    <tr>
                        <td>
                            <v-checkbox
                                v-model="student.selected"
                                primary
                                hide-details
                            ></v-checkbox>
                        </td>
                        <td v-if="isSelectedSchoolRostered" class="px-0">
                            <v-icon small v-if="student.item.isRostered">sync_alt</v-icon>
                        </td>
                        <td class="blue--text pointer" >
                            {{student.item.lastName}}, {{student.item.firstName}}
                        </td>
                        <td class="pointer">
                            {{student.item.sisUserId}}
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card-text>
        <v-card-actions>
            <v-btn v-if="allowStudentCreation"
                @click="openDeleteUserDialog(rosterSelectedStudents,true)"
                id="StudentRosterModalDeleteBtn"
                color="error" 
                flat 
                :disabled="rosterSelectedStudents.length<1 || containsRosteredUser(rosterSelectedStudents)"
            >
                <v-icon>delete</v-icon> Delete From Roster ({{rosterSelectedStudents.length}})
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" 
                class="elevation-0"
                id="StudentRosterModalAddBtn"
                :disabled="rosterSelectedStudents.length<1 || selectedStudentsExceedAvailableSeats || loadingStudents" 
                @click="callAddStudentsToClass(rosterSelectedStudents)"
            >
                <v-icon v-show="!loadingStudents">check</v-icon>
                <v-icon v-show="loadingStudents" class="mr-2">fas fa-circle-notch fa-spin</v-icon>
                Add To Class ({{rosterSelectedStudents.length}})
            </v-btn>
        </v-card-actions>
    </UserManagmentModal>
</template>

<script>
    import Constants from 'mixins/Constants'
    import Messaging from 'components/layout/Messaging'
    import UserManagmentModal from 'components/layout/UserManagmentModal'
    import TableHelper from 'mixins/TableHelper'
    import { mapActions } from 'vuex'

	export default {
        name: 'StudentRosterModal',
        components: { Messaging, UserManagmentModal },
        mixins: [Constants, TableHelper],
        props: {
            allowStudentCreation: Boolean,
            allStudents: Array,
            changeSort: Function,
            classCap: Number,
            containsRosteredUser: Function,
            gradeLevelIdFilter: Array,
            institutionID: Number,
            isSelectedSchoolRostered: Boolean,
            loadingStudents: Boolean,
            seatsUsed: Number,
            selectedClass: Object,
            selectedClassID: Number,
            selectedSchool: Object,
            value: Boolean,
        },
        data() {
            return {
                paginationOptions: {sortBy : 'name'},
                rosterGradeLevelFilterID: null,
                rosterSelectedStudents: [],
                searchAllStudents: '',
            }
        },
		computed : {
            allStudentsHeaders(){
                let headers = [
                    {text: this.$t('classMgmt.name'), value:'searchableName', sortable:true, align: 'left'},
                    {text: this.$t('classMgmt.studentID'), value:'sisUserId', sortable:true, align: 'left'}
                ]
                if(this.isSelectedSchoolRostered){
                    headers.unshift({icon:'sync_alt', value:'isRostered', sortable: true, align:'left'})
                }
                return headers
            },
            platform(){
                 return this.$store.state.platform
            },
            numSelectedStudents(){
                return  this.rosterSelectedStudents.length
            },
            seatsAvailable(){
                return this.classCap - this.seatsUsed
            },
            selectedStudentsExceedAvailableSeats(){
                return this.rosterSelectedStudents.length > this.seatsAvailable
            },
            studentLimitExceededMessage(){
                let data = {
                    cap: this.classCap,
                    seatsUsed: this.seatsUsed,
                    count: this.rosterSelectedStudents.length
                }
                let message = this.$t('classMgmt.studentCapExplained',data)
                message += ' ' + this.$t('classMgmt.studentCapExplainedMore',data)
                return message
            },
            studentsNotInSelectedClass() {
                if(this.selectedClass){
                    if(this.selectedClass.id<0){
                        return this.allStudents
                    } else {
                        let studentIdsInClass = this.selectedClass.studentIDs || []
                        let studentsNotInClass =  this.allStudents.filter(function(student){
                            return !studentIdsInClass.includes(student.id)
                        })
                        return this.selectedSchool.isClassCodeEnabled && !this.selectedClass.isClassCodeEnabled
                            ? studentsNotInClass.filter(s => !s.isClassCodeEnabled)
                            : studentsNotInClass
                    }
                } else {
                    return []
                }
            },
            studentsNotInSelectedClassFilterByGradeLevel(){
                return this.filterByGradeLevel(this.studentsNotInSelectedClass, this.rosterGradeLevelFilterID)
            },
        },
        methods: {
            ...mapActions('platformData/classes', ['addStudentsToClass']),
            callAddStudentsToClass(students){
                let studentIds = []
                students.forEach(function(student){
                    studentIds.push(student.id)
                })
                let institutionId = this.institutionID
                let classId = this.selectedClassID
                let skipAutoLicensing = this.selectedSchool.isClassCodeEnabled && !this.selectedClass.isClassCodeEnabled
                let self = this;
                (async function() {
                    await self.addStudentsToClass({institutionId,classId,studentIds, skipAutoLicensing})
                    self.$emit('trackAddStudents', 'from_roster', 'roster', studentIds.length)
                    await self.$emit('updateModel')
                    self.closeDialogs()
                })()
            },
            closeDialogs() {
                this.$emit('closeDialogs')
            },
            filterByGradeLevel(students, gradeLevelID){
                if(gradeLevelID == null || gradeLevelID < 0){
                    return students
                } else {
                    return students.filter(function(student){
                        return student.gradeId == gradeLevelID
                    })
                }
            },
            openDeleteUserDialog(users, forceDeleteMode){
                this.$emit('openDialog', 'deleteConfirmModal', {users, forceDeleteMode})
            },
            rowsPerPageItems(tableCount){
                if(tableCount=="modal"){
                    return [5,10,25]
                }
                let options = [10]
                if(tableCount>25){
                    options.push(25)
                }
                if(tableCount>100){
                    options.push(100)
                }
                if(tableCount>500){
                    options.push(500)
                } else {
                    options.push({"text":"$vuetify.dataIterator.rowsPerPageAll","value":500})
                }
                return options
            },
        },
        watch: {
            numSelectedStudents(val) {
                if(this.selectedStudentsExceedAvailableSeats) {
                    this.$store.commit('setMessageWarning', this.studentLimitExceededMessage)
                } else {
                    this.$store.commit('setMessageWarning', null)
                }
            }, 
            value(val) {
                if (val) {
                    this.searchAllStudents = ''
                    this.rosterGradeLevelFilterID = null
                    this.rosterSelectedStudents = []
                }
            }
        }
	}
</script>

<style lang="less" scoped>
</style>