
export default {
    data(){
        return {
            specialChars : "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~",
            passwordRules : "length:8,30|minLowercaseChars:1|minUppercaseChars:1|minDigitChars:1|minSpecialChars:1"
        }
    },
	computed: {
		veeValidatePasswordRules(){
            return 'required|' + this.passwordRules + '|noDiacritics'
        }
	}
}
