import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

import english from './en.js'
import spanish from './es.js'
import french from './fr.js'
import hodor from './ho.js'

var messages = {
	en : english,
	es : spanish,
	fr : french,
  ho : hodor
}

const i18n = new VueI18n({
	locale: 'en',
	fallbackLocale: 'en',
	messages
})

Vue.prototype.$locale = {
	change (lang) {
		i18n.locale = lang
	},
	current () {
		return i18n.locale
	}
}

export default i18n
