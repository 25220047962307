import APIs from 'mixins/APIs'
import AutoFill from 'mixins/AutoFill'

export default {
	mixins: [APIs, AutoFill],
	methods: {
		async loadSubscriptions(){
			this.$http.get(this.getSubscriptionsAPI(),this.jtiHeader).then(response => {
                this.$store.commit('setSubscriptions', response.data.ArrayList)
            }, error => {
				console.log(error)
				this.$store.commit('setMessageError', 'Failed to load subscriptions.')
            })
		}
	}
}
