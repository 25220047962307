import APIs from 'mixins/APIs'
import LoginDetector from 'mixins/LoginDetector'
import { mapState } from 'vuex'
export default {
	mixins: [APIs, LoginDetector],
	computed: {
		...mapState('platformData/app', ['jti']),
	},
	methods: {
		getCountries(varName, noDomestic){
			this.$http.get(this.getCountriesAPI(), this.jtiHeader).then(response => {
				let countries = [
						{code:'---',name:'FREQUENTLY SELECTED COUNTRIES', disabled:true},
						{code:'USA',name:'United States'},
						{code:'CAN',name:'Canada'},
						{code:'***',name:'ALL COUNTRIES', disabled:true}
					]
				countries = countries.concat(response.data.ArrayList)
				if(noDomestic){
					this.removeCountry(countries,'USA')
				}
    			this[varName] = countries
      		}, error => {
      			this.$store.commit('setMessageError', this.$t('error.404'))
      		})
		},
		getNonUsCanCountries(varName){
			this.$http.get(this.getCountriesAPI(), this.jtiHeader).then(response => {
				let countries = []
				countries = countries.concat(response.data.ArrayList)
				this.removeCountry(countries,'USA')
				this.removeCountry(countries,'CAN')
    			this[varName] = countries
      		}, error => {
      			this.$store.commit('setMessageError', this.$t('error.404'))
      		})
		},
		getCountryName(countryCode, countries){
			let countryName
			countries.forEach(function(country){
				if(country.code==countryCode){
					countryName = country.name
				}
			})
			return countryName
		},
		getInstitutionsByCountryCode(countryCode) {
			let institutions
			this.$http.get(this.getInstitutionsByCountryCodeAPI(countryCode), this.jtiHeader).then(response => {
				let institutions = response.data.ArrayList
				institutions.sort(this.schoolSort)
				this.nonUSCANSchools = institutions			
      		}, error => {
      			this.$store.commit('setMessageError', this.$t('error.404'))
      		})
			return institutions
		},
		getStates(varName, countryCode, includeTerritories){
			this.$http.get(this.getStatesAPI(countryCode), this.jtiHeader).then(response => {
				let states = response.data.ArrayList
				if(!includeTerritories){
					states = states.filter(state => !state.territory)
				}
				this[varName] = states
				if(states && states.length){
					return true
				} else {
					return false
				}
			}, error => {
				this.$store.commit('setMessageError', this.$t('error.404'))
			})
		},
		removeCountry(countryList, countryCode){
			countryList.forEach(function(country, index){
				if(countryCode == country.code){
					countryList.splice(index,1)
				}
			})
		},
		schoolSort(school1,school2){
			const name1 = school1.name.toUpperCase()
			const name2 = school2.name.toUpperCase()
			if (name1 < name2) {
				return -1;
			}
			if (name1 > name2) {
				return 1;
			}
			return 0;
		}
	}
}
