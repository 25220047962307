export default {
	data(){
  	return {
  		autoFillInProgress : false
  	}
  },
	methods: {
		autoFill : function(){
			this.autoFillInProgress = true
			for(var key in this){
				var firstChar = key.charAt(0)
				if(typeof this[key] !== 'function' && firstChar !== '$' && firstChar !== '_'){
					if(typeof this.$store.state.formData[key] != "undefined" && this.$store.state.formData[key]!==null){
						this[key] = this.$store.state.formData[key]
					} else if(typeof this.$store.state.schoolInfo[key] != "undefined" && this.$store.state.schoolInfo[key]){
						this[key] = this.$store.state.schoolInfo[key]
					} else if(typeof this.$store.state.paymentInfo[key] != "undefined" && this.$store.state.paymentInfo[key]){
						this[key] = this.$store.state.paymentInfo[key]
					} else if(typeof this.$store.state.grant[key] != "undefined" && this.$store.state.grant[key]){
						this[key] = this.$store.state.grant[key]
					} else if(typeof this.$store.state.servicesForm[key] != "undefined" && this.$store.state.servicesForm[key]){
						this[key] = this.$store.state.servicesForm[key]
					} else if(typeof this.profile[key] != "undefined" && this.profile[key]){
						this[key] = this.profile[key]
					}
				}
			}
			this.autoFillInProgress = false
		}
	}
}
