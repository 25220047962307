<template>
	<v-flex xs12 v-if="grantOpen && flow!='pre-approved'" class="text-xs-right stepper">
		<v-stepper class="hidden-xs-only" :value="currentStep" alt-labels>
  		<v-stepper-header>
    		<v-stepper-step step="1" :complete="currentStep>1">{{$t('grant.step1')}}</v-stepper-step>
    		<v-divider></v-divider>
    		<v-stepper-step step="2" :complete="currentStep>2">{{$t('grant.step2')}}</v-stepper-step>
    		<v-divider></v-divider>
    		<v-stepper-step step="3" :complete="currentStep>3">{{$t('grant.step3')}}</v-stepper-step>
    		<v-divider></v-divider>
    		<v-stepper-step step="4">{{$t('grant.step4')}}</v-stepper-step>
  		</v-stepper-header>
   	</v-stepper>
		<v-progress-linear v-bind:value="currentStep/5*100" color="primary" height="5" class="hidden-sm-and-up">
		</v-progress-linear>
 	</v-flex>
</template>

<script>
	import EnvironmentDetector from 'mixins/EnvironmentDetector'
	export default {
		name: 'GrantStepper',
		mixins: [EnvironmentDetector],
		props: {
			currentStep: {
				type: Number,
			default: 1
			}
		},
		computed: {
			grantOpen(){
				return this.$store.state.grant.grantOpen
			}
		}
	}
</script>

<style lang="less" scoped>
	.stepper {
		margin-top: -20px;
	}
	.v-stepper {
		box-shadow: none;
		border: none;
	}
</style>
