<template>
    <v-flex class="pa-4">
        <v-btn v-if="showBackBtn" id="product-settings" large dark icon flat color="error lighten-1" :to="{name:'Home'}"><v-icon>close</v-icon></v-btn>
		<v-layout class="mb-2">
			<span class="body-2 font-weight-bold">{{$t('optin.products')}}</span>
		</v-layout>
        <Messaging outline></Messaging>
        <table class="text-xs-left mt-4">
            <tr class="grey--text">
                <th>Product</th>
                <th colspan="2">Description</th>
                <th>Grade Level</th>
                <th class="text-xs-right">Enabled</th>
            </tr>
            <tr v-for='productID in productIDs' :key="productID">
                <td class="py-3 pr-1"><img :src="productSettings[productID].logoFull" alt="Frax Logo"/></td>
                <td>{{productSettings[productID].description}}</td>
                <td class="pr-1">
                    <v-btn color="primary" outline small class="mx-0 pt-1" :href="getMarketingURL(UrlFriendlyProductName(productID))" target="_blank">
                        Learn More
                    </v-btn>
                </td>
                <td>Grades {{productSettings[productID].gradeBand}}</td>
                <td>
                    <v-switch v-model="optIns[productID]"></v-switch>
                </td>
            </tr>
        </table>
        <v-flex class="text-xs-center mt-1">
            <v-btn @click="updateOptOuts()" color="primary font-weight-bold">Update</v-btn>
        </v-flex>
    </v-flex>
</template>

<script>
    import APIs from 'mixins/APIs'
	import EnvironmentDetector from 'mixins/EnvironmentDetector'
	import Forwarding from 'mixins/Forwarding'
	import LoginDetector from 'mixins/LoginDetector'
	import Messaging from 'components/layout/Messaging'
    import ProductInfo from 'mixins/ProductInfo'
	import ProfileLoader from 'mixins/SubscriptionsLoader'
    import SubscriptionHelper from 'mixins/SubscriptionHelper'
    import { mapActions, mapGetters, mapState } from 'vuex'
	export default {
		name: 'OptIn',
        metaInfo : {
            title : 'Opt-In'
        },
		components: {
			Messaging
		},
		mixins: [APIs, EnvironmentDetector, Forwarding, LoginDetector, ProductInfo, ProfileLoader, SubscriptionHelper],
        data(){
            return {
                optIns: {},
            }
        },
		computed : {
            ...mapGetters('platformData/profile', ['profile']),
            ...mapState('platformData/app', ['jti']),
            productIDs(){
                return this.getProductIDs(this.activeSubscriptions)
            },
            showBackBtn(){
                return !this.$route.params.hideBack
            }
        },
        methods : {
            ...mapActions('platformData/profile', ['fetchProfile']),
            UrlFriendlyProductName(productID){
                return this.productEviroment(productID)
            },
            updateOptOuts(){
                let newOptOuts = [0]
                for(const prodID in this.optIns){
                    if(!this.optIns[prodID]){
                        newOptOuts.push(Number(prodID))
                    }
                }
                let updatedProfile = {
                    productsOptOutJson: JSON.stringify(newOptOuts),
					username : this.profile.username,
					firstname : this.profile.firstName,
					middlename : this.profile.middleName,
					lastname : this.profile.lastName,
                    displayName : this.profile.displayName,
					titleID : this.profile.titleID,
					email : this.profile.email,
					languageID : this.profile.languageID,
					isValidEmail : this.profile.isValidEmail,
					isSubscribedToEmails : this.profile.isSubscribedToEmails,
					isDeleted : this.profile.isDeleted
				}
			  	this.$http.put(this.getProfileUpdateAPI(), updatedProfile, this.jtiHeader).then(response => {
                    this.$router.push({name:'Home'})
                    this.fetchProfile()
			  	}, error => {
			  		if (error.response.status == 404 || error.response.status == 0) {
						this.$store.commit('setMessageError', this.$t('error.404'))
					} else {
						this.$store.commit('setMessageError', this.$t('error.unknown'))
					}
			  	})
            }
        },
		created(){
            this.loadSubscriptions()
            let optOuts = this.profile.productsOptOut
            this.productIDs.forEach(prodID => {
                if(optOuts.length){
                    this.optIns[prodID] = !optOuts.includes(prodID)
                } else {
                    this.optIns[prodID] = false
                }
            })
        },
	}
</script>

<style lang="less" scoped>
    #product-settings {
        float: right;
        margin-top: -15px;
        margin-right: -15px;
    }
    table {
        width: 100%;
        td {
            font-weight: 600;
        }
        img {
            height: 40px;
        }
        a {
            text-transform: none;
        }
        .v-input--switch {
            float: right;
            margin-right: -5px;
        }
    }
</style>
