<template>
    <div
      :class="[
        'footer-container dont-print',
        // {'ground-container': isStudentHome}
      ]"
    >
        <v-container>
            <v-flex xs12 xl8 offset-xl2>
                <v-layout wrap class="wrapper">
                    <div class="logo-wrapper">
                        <a :href="mainUrl" class="logo mr-5" target="_blank">
                            <img src="../../assets/shared/img/explorelearning-logo.png" alt="ExploreLearning logo">
                        </a>
                        <template v-if="isNotStudent">
                          <a v-for="(icon, index) in mediaIcons" :key="index" :href="icon.href" target="_blank">
                              <i :class="['icn fab', 'fa-' + icon.name]"></i>
                          </a>
                        </template>
                    </div>
                </v-layout>
                <hr class="separator" />
                <v-layout wrap class="wrapper mt-2">
                    <div class="links">
                        <span v-for="(link, index) in about" :key="index">
                            <router-link :to="link.to">
                                <span class="label" v-html="link.name"></span>
                            </router-link>
                            <span v-if="index < about.length - 1" class="backslash"> / </span>
                        </span>
                    </div>
                </v-layout>
                <v-flex>
                    <p class="text mb-1" v-html="legal"></p>
                    <p class="text" v-text="address"></p>
                </v-flex>                
            </v-flex>
        </v-container>
    </div>
  </template>
  
  <script>
    import APIs from 'mixins/APIs'
    import Constants from 'mixins/Constants'
    import { mapGetters } from 'vuex'

  
  export default {
    mixins : [APIs, Constants],
    data() {
      return {
        mainUrl: 'https://www.explorelearning.com/',
        address: '110 Avon Street, Charlottesville, VA, 22902 USA'
      }
    },
    computed: {
      ...mapGetters('platformData/profile', ['profile']),
      isNotStudent(){
        return this.loggedIn && this.profile.roleID != this.roleIDs.STUDENT
      },
      legal() {
        return ` &copy; ${new Date().getFullYear()} ExploreLearning. All rights reserved. A Cambium Learning Group Company. Gizmos, Reflex, Frax, and Science4Us are registered trademarks of ExploreLearning.`
      },
      mediaIcons() {
        return [
          { name: 'twitter', href: 'https://twitter.com/explorelearning' },
          { name: 'facebook-f', href: 'https://www.facebook.com/ExploreLearningK12STEMSolutions' },
          // { name: 'youtube', href: 'https://www.youtube.com/user/ELGizmos' }
        ]
      },
      about() {
        return [
          { name: 'Privacy Policy', to: {name: 'Privacy'} },
          { name: 'Terms', to: {name: 'Terms'} },
          {
            name: 'Do Not Sell My Personal Information',
            to: {name: 'DoNotSellMyInformation'}
          },
          {
            name: 'Accessibility',
            to: {name:'Accessibility'}
          },
        ].filter(link => !link.hide)
      }
    }
  }
  </script>
  
  <style lang="less" scoped>
  .footer-container {
    padding-top: 2em;
    display: flex;
    flex-shrink: 0;
    // background-image: url(../.././assets/shared/img/insights-bg.jpg);
    .wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      padding-bottom: .5em;
      gap: 1em;
      .logo-wrapper {
        display: flex;
        align-items: center;
        .icn {
          padding-right: 0.5em;
          color: #fff;
          height: 0.5em;
          &:hover {
            color: #d3d3d3;
          }
        }
      }
      .label {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .content {
        display: flex;
        gap: 0.5em;
        a {
          display: flex;
          align-items: center;
          border: unset;
          text-decoration: none;
          color: #444;
          font-size: 1em;
          border-radius: 20px;
          padding: 0.15em 0.75em;
          background: #fff;
          &:hover {
            background: #d3d3d3
          }
        }
      }
      .links {
        a {
          color: #fff;
          font-size: 1em;
          font-weight: 400;
          text-decoration: none;
          &:hover {
            color: #d3d3d3;
          }
        }
        .backslash {
            color: #fff;
        }
      }
    }
    .text {
      color: #ffffff90 !important;
      font-size: 1em;
    }
  }
  </style>