<template>
	<v-flex xs12 v-if="flow=='reg'" class="text-xs-right stepper">
		<v-stepper class="hidden-xs-only" :value="currentStep" alt-labels>
            <v-stepper-header>
                <v-stepper-step step="1" :complete="currentStep>1">{{$t('trial.step1')}}</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="2" :complete="currentStep>2">{{$t('trial.step2')}}</v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="3" color="grey" complete complete-icon="task_alt">
                    <span class="grey--text">{{$t('trial.step3')}}</span>
                </v-stepper-step>
            </v-stepper-header>
   	    </v-stepper>
		<v-progress-linear v-bind:value="currentStep/3*100" color="primary" height="5" class="hidden-sm-and-up" />
 	</v-flex>
</template>

<script>
	import EnvironmentDetector from 'mixins/EnvironmentDetector'
	export default {
		name: 'GrantStepper',
		mixins: [EnvironmentDetector],
		props: {
			currentStep: {
				type: Number,
			    default: 1
			}
		},
	}
</script>

<style lang="less" scoped>
	.stepper {
		margin-top: -20px;
	}
	.v-stepper {
		box-shadow: none;
		border: none;
	}
</style>
