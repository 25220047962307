import Constants from 'mixins/Constants'
export default {
	mixins: [Constants],
	computed: {
		// This one works better for components outside of the vue-router parent
		currentEnvironment(){
			if(this.environments.includes(this.requestedEnvironment)){
				return this.requestedEnvironment.toLowerCase()
			} else {
				return 'el'
			}
		},
		requestedEnvironment(){
			return this.$root.$route.params.environment
		},
		flow(){
  			return this.$route.params.flowOverride || this.$route.meta.flow
  		}
	},
	methods: {
		getEnvironment(){
			return this.currentEnvironment
		}
	}
}
