<template>
	<Headline :flowOverride="flowOverride" :headline="headline" :logo="currentEnvironment"></Headline>
</template>

<script>
    import Headline from 'components/layout/Headline'
	import EnvironmentDetector from 'mixins/EnvironmentDetector'
	export default {
        name: 'GrantHeadLine',
        components: {Headline},
        mixins: [EnvironmentDetector],
		props: {
			headline: {
				type: String,
				default: null
			},
		},
		computed: {
			flowOverride(){
                if(this.flow == 'pre-approved'){
                    return 'Pre-Approved Grant Application'
                } else if(this.$store.state.grant.international){
                    return this.$t('grant.headerInternational')
                } else {
                    if(this.isGrantOpen){
                        return this.$t('grant.header')
                    } else {
                        return this.$t('grant.inquiry')
                    }
                }
            },
            isGrantOpen(){
                return this.$store.state.grant.grantOpen
            },
		}
	}
</script>
