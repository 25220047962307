<template>
    <UserManagmentModal
        :value="value"
        :headline="$t('classMgmt.productAccessHeader')"
        :subHeadline="userCountOrName(selectedNonStudents, $tc('classMgmt.' + userType.toLowerCase(), selectedNonStudents.length))"
        size="500"
        @closeDialogs="closeDialogs()"
    >
        <v-card-text>
            <Messaging outline class="mb-3"/>
            <v-layout wrap v-for="productID in productIDs" :key="productID">
                <v-flex xs2 class="text-xs-center">
                    <img :src="productSettings[productID].logo" class="permission-icon" :alt="productDisplayName(productID)+' Logo'"/>
                </v-flex>
                <v-flex xs10>
                    <v-radio-group v-model="permissions[productID]" row class="ml-3">
                        <v-radio
                            label="Enable"
                            :key="productDisplayName(productID)+'-on'"
                            value="on"
                            :disabled="tooManyLicenses(productID)"
                        ></v-radio>
                        <v-radio
                            label="Disable"
                            :key="productDisplayName(productID)+'-off'"
                            value="off"
                        ></v-radio>
                        <v-radio
                            :disabled="selectedNonStudents.length<2"
                            label="Do Not Change"
                            :key="productDisplayName(productID)+'-no-change'"
                            value="no-change"
                        ></v-radio>
                    </v-radio-group>
                </v-flex>
                <v-flex v-if="getActiveInstitutionSubscriptions(productID, selectedSchool).length > 1 && permissions[productID] == 'on'"
                    xs12 
                    class="ml-2">
                    <v-select
                        :items="getActiveInstitutionSubscriptions(productID, selectedSchool)"
                        :label="'Select ' + productDisplayName(productID)  + ' Subscription'"
                        v-model="selectedSubscription[productID]"
                        item-text="name"
                        :return-object="true"
                        :name="productDisplayName(productID) + ' subscription'"
                        v-validate="'required'"
                        :error-messages="errors.collect(productDisplayName(productID) + ' subscription')">
                        <template #item="subscription">
                            <h4 class="mr-auto">{{subscription.item.name.length > 30 ? subscription.item.name.slice(0, 29) + '&hellip;' : subscription.item.name}}</h4>
                            {{subscription.item.settings ? `${new Date(subscription.item.settings.startsOn).toLocaleDateString("en-US")} - ${new Date(subscription.item.settings.expiresOn).toLocaleDateString("en-US")}` : ''}}
                            <br/>
                            {{subscription.item.settings ? createCapString(subscription.item) : ''}}
                        </template>
                    </v-select>
                </v-flex>
            </v-layout>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="setProductAccess()" class="elevation-0" :disabled="disableSubmit">
                <v-icon>check</v-icon> {{$t('classMgmt.accept')}}
            </v-btn>
        </v-card-actions>
    </UserManagmentModal>
</template>

<script>
    import UserManagmentModal from 'components/layout/UserManagmentModal'
    import Messaging from 'components/layout/Messaging'
    import ProductInfo from 'mixins/ProductInfo'
    import SubscriptionHelper from 'mixins/SubscriptionHelper'

	export default {
        name: 'ProductAccessModal',
        components: { Messaging, UserManagmentModal },
        mixins: [ProductInfo, SubscriptionHelper],
        props: {
            productIDs: Array,
            seatInfo: Function,
            subSeatInfo: Function,
            selectedNonStudents: Array,
            selectedSchool: Object,
            userCountOrName: Function,
            userType: String,
            value: Boolean,
        },
        data() {
            return {
                permissions : {},
                selectedSubscription : {},
                newMultiSubscriptionSubSelection : []
            }
        },
		computed : {
            availableSeats(){
                let counts = {}
                this.productIDs.forEach(productID => {
                    let seatInfo = this.seatInfo(productID)
                    counts[productID] = seatInfo.teacherDelta
                })
                return counts
            },
            availableSubscriptionSeats(sub){
                let counts = {}
                if (this.selectedSubscription && Object.keys(this.selectedSubscription).length) {
                    Object.values(this.selectedSubscription).forEach(sub => {
                        let seatInfo = this.subSeatInfo(sub.id)
                        counts[sub.id] = seatInfo.teacherDelta
                    })
                }
                return counts
            },
            disableSubmit(){
                let disable = true
                if(this.selectedNonStudents.length==1){
                    let licenses = this.selectedNonStudents[0].licenses ? this.selectedNonStudents[0].licenses.filter(l => l.matchingLicense).map(l => l.productId) : null;
                    if(licenses){
                        let multiSubNoSelection = false
                        this.productIDs.forEach(productId => {
                            let teacherLicenses = this.selectedNonStudents[0].licenses.filter(l => l.matchingLicense && l.productId == productId).map(l => l.subscriptionId).sort()
                            let selectedLicenses = this.selectedSubscription[productId] && this.selectedSubscription[productId].productId === productId 
                                ? [this.selectedSubscription[productId].id] 
                                : {}
                            //if they are multi-sub and clicked enabled but havent selected any subs yet
                            if (this.productsWithMultipleSubscriptions.includes(productId) && (this.permissions[productId] == "on" && !selectedLicenses.length)) {
                                disable = true
                                multiSubNoSelection = true
                            //if they are multi sub but the licenses matches exactly with what the user currently already has
                            } else if (this.productsWithMultipleSubscriptions.includes(productId) 
                                && this.permissions[productId]=="on" 
                                && (JSON.stringify(teacherLicenses) !== JSON.stringify(selectedLicenses))
                                && !multiSubNoSelection) {
                                    disable = false
                            //if they are not multi sub just check if the enabled/disabled status matches
                            } else if (Boolean(this.permissions[productId]=="on")!==licenses.includes(productId) && !multiSubNoSelection){
                                disable = false
                            }
                        })
                    }
                } else {
                    let multiSubNoSelection = false
                    this.productIDs.forEach(productId => {
                        //checks if they are a multi-sub user who selected enabled but didnt pick any licenses yet
                        if (this.productsWithMultipleSubscriptions.includes(productId) && (this.permissions[productId] == "on" && !this.selectedSubscription[productId])) {
                            disable = true
                            multiSubNoSelection = true
                        // All Products set to "Do Not Change"
                        } else if(this.permissions[productId]!=="no-change" && !multiSubNoSelection){
                            disable = false
                        }
                    })
                }
                if(this.seatsExceeded.length){
                    // Product Cap Exceeded
                    disable = true
                }
                return disable
            },
            newUsersPerProduct(){
                let newUsers = {}
                this.productIDs.forEach(product =>{
                    if(this.permissions[product]=='on'){
                        newUsers[product] = this.numWithoutProduct(this.selectedNonStudents,product)
                    }
                })
                return newUsers
            },
            seatsExceeded(){
                let tooManySeats = []
                if (Object.keys(this.selectedSubscription).length && this.newMultiSubscriptionSubSelection.length) {
                    Object.values(this.selectedSubscription).forEach(sub => {
                        if(this.permissions[sub.productId]=='on' && this.newUsersPerProduct[sub.productId]>[this.availableSubscriptionSeats[sub.id]]){
                            tooManySeats.push(this.subSeatInfo(sub.id))
                        }
                    })
                } else {
                    this.productIDs.forEach(productID => {
                        if(!this.productsWithMultipleSubscriptions.includes(productID) && this.permissions[productID]=='on' && this.newUsersPerProduct[productID]>[this.availableSeats[productID]]){
                            tooManySeats.push(this.seatInfo(productID))
                        }
                    })
                }
                return tooManySeats
            },
            platform(){
                return this.$store.state.platform
            },
            products(){
                let products = []
                this.productIDs.forEach(productID =>{
                    // ProductIDs can be trusted for Employees and CTs
                    this.subscriptions.forEach(sub => {
                        if(sub.productId==productID && sub.isActive && !sub.isExpired && !products.map(p => p.id).includes(sub.productId)){
                            products.push({id:productID, name:this.productDisplayName(productID)})
                        }
                    }) 
                })
                return products
            },
            productsWithMultipleSubscriptions() {
                const products = []
                this.productIDs.forEach(productID =>{
                    if (this.getActiveInstitutionSubscriptions(productID, this.selectedSchool).length > 1) {
                        products.push(productID)
                    }
                })
                return products
            }
        },
        watch: {
            seatsExceeded(val){
                if(this.value){
                    let message = ''
                    val.forEach(product => {
                        product.newSeats = this.newUsersPerProduct[product.productId]
                        product.teacherOverCap = product.teacherDelta * -1 + product.newSeats
                        if(message != ''){
                            message += '<br/><br/>'
                        }
                        message += this.$t('classMgmt.overTeacherLimit',product)
                    })
                    this.$store.commit('setMessageError', message)
                }
            },
            selectedSubscription(val){
                const missingSubscriptions = [];

                this.selectedNonStudents.forEach(user => {
                    const userLicenseIds = user.licenses.map(license => license.subscriptionId);
                    //using for of loop for selectedSubscription since its an object
                    for (const productId in this.selectedSubscription) {
                        const selectedId = this.selectedSubscription[productId].id;
                        console.log(missingSubscriptions.map(x => x.id), this.selectedSubscription[productId].id)
                        if (!userLicenseIds.includes(selectedId) && !missingSubscriptions.map(x => x.id).includes(this.selectedSubscription[productId].id)) {
                            missingSubscriptions.push(this.selectedSubscription[productId]);
                        }
                    }
                });

                this.newMultiSubscriptionSubSelection = missingSubscriptions;
            },
            value(val){
                if (val && this.selectedNonStudents){
                    this.selectedSubscription = {};
                    this.productIDs.forEach(productID=>{
                        if(this.selectedNonStudents.length==1){
                            if(this.selectedNonStudents[0].licenses.filter(l => l.matchingLicense).map(l => l.productId).includes(productID)){
                                this.$set(this.permissions,productID,"on")
                            } else {
                                this.$set(this.permissions,productID,"off")
                            }
                            if(this.productsWithMultipleSubscriptions.includes(productID)) {
                                const teacherLicense = this.selectedNonStudents[0].licenses.find(l => l.matchingLicense && l.productId === productID)
                                if (teacherLicense) {
                                    const matchingSub = this.subscriptions.find(s => {
                                        return s.id === this.selectedNonStudents[0].licenses.find(l => l.matchingLicense && l.productId === productID).subscriptionId
                                    });
                                    if (matchingSub) {
                                        this.$set(this.selectedSubscription,productID,matchingSub)
                                    }
                                }                                
                            }
                        } else {
                            this.$set(this.permissions,productID,"no-change")
                        }
                    })
                }
            }
        },
        methods: {
            closeDialogs() {
                this.$emit('closeDialogs')
            },
            createCapString(sub) {
                //gizmos with teacher plus license-show teacher cap
                if (sub.productId == 3 && sub.licensingTypeId === 10 && sub.teachersCap > 0 && sub.teachersCap !== Infinity) {
                    return `${sub.teachersUsed}/${sub.teachersCap} Seats Used`
                //Frax or reflex with teacher license-show students cap
                } else if (sub.productId <= 2 && sub.licensingTypeId === 2 && sub.studentsCap > 0 && sub.studentsCap !== Infinity){
                    return `${sub.studentsUsed}/${sub.studentsCap} Seats Used`
                } else {
                    return ''
                }
            },
            numWithoutProduct(users, productID){
                let count = 0
                if(users[0] && users[0].licenses){
                    for(let user of users){
                        if(!user.licenses.filter(l => l.matchingLicense).map(l => l.productId).includes(productID)){
                            ++count
                        }
                    }
                }
                return count
            },
            async setProductAccess(){
                let message = ''
                let includeEmailMsg = false
                let role = ''
                let self = this;
                this.productIDs.forEach(async productId=>{
                    let permission = self.permissions[productId]
                    if(permission=="on"){
                        //toAdd is a array of teachers that need products/subs added
                        let toAdd = self.selectedNonStudents.filter(teacher => {
                            if (!self.productsWithMultipleSubscriptions.includes(productId)) {
                                return !teacher.licenses.filter(l => l.matchingLicense).map(l => l.productId).includes(productId)
                            } else {
                                // Check if the selectedSubscription is missing from the teacher's licenses
                                let toAddLicenses = false;
                                if (!teacher.licenses.some(l => l.productId === self.selectedSubscription[productId].productId && l.subscriptionId === self.selectedSubscription[productId].id)) {
                                    toAddLicenses = true
                                }
                                return toAddLicenses
                            }
                        })

                        //multiSubsToRemove is an array of teachers that have a multi-sub product that needs a sub removed
                        let multiSubsToRemove = []
                        //ubsToRemove is an array of subscriptionIds that need to be removed
                        let subsToRemove = []
                        if (self.productsWithMultipleSubscriptions.length && self.productsWithMultipleSubscriptions.includes(productId)) {
                            multiSubsToRemove = self.selectedNonStudents.filter(teacher => {
                                // Check if any license has a matchingLicense and its subscriptionId is not present in selectedSubscription
                                return teacher.licenses.some(l => l.matchingLicense && (l.productId === productId) && (self.selectedSubscription[productId].id !== l.subscriptionId));
                            });
                            if(multiSubsToRemove.length) {
                                self.selectedNonStudents.forEach(teacher => {
                                    teacher.licenses.forEach(license => {
                                        //if the productId matches the current one, the teacher's license isnt in selectedSubs,
                                        // and there is no duplicates already in subsToRemove
                                        if (license.productId === productId 
                                        && (self.selectedSubscription[productId].id !== license.subscriptionId)
                                        && !subsToRemove.some(id => id === license.subscriptionId)) {
                                            subsToRemove.push(license.subscriptionId);
                                        }
                                    });
                                });
                            }

                        }
                        if(toAdd.length){
                            //check if we are adding a sub (if block) or the entire product (else block) for the teacher
                            if (self.selectedSubscription[productId]) {
                                await self.toggleProductAccess(toAdd,productId,1,false, false, [self.selectedSubscription[productId].id])
                            } else {
                                await self.toggleProductAccess(toAdd,productId,1,false)
                                role = self.$tc('classMgmt.teachers',toAdd.length).toLowerCase()
                                message = message + self.$t('classMgmt.productAccessGrantToast',{product:self.productDisplayName(productId),num:toAdd.length,role}) + ' '
                                includeEmailMsg = true
                            }
                        }
                        //Check for if the user is still enabled but needs a sub removed
                        if (multiSubsToRemove.length) {
                            await self.toggleProductAccess(multiSubsToRemove,productId,0,false, false, subsToRemove)
                            role = self.$tc('classMgmt.teachers',toAdd.length).toLowerCase()
                        }
                    //check if we are entirely removing a product
                    } else if(permission=="off"){
                        let toRemove = self.selectedNonStudents.filter(teacher => {
                            return teacher.licenses.filter(l => l.matchingLicense).map(l => l.productId).includes(productId)
                        })
                        if(toRemove.length){
                            await self.toggleProductAccess(toRemove,productId,0,false)
                            message = message + self.$t('classMgmt.productAccessRemoveToast',{product:self.productDisplayName(productId),num:toRemove.length,role:self.$tc('classMgmt.teachers',toRemove.length).toLowerCase()}) + ' '
                        }
                    }
                })
                this.closeDialogs()
                this.$emit("deselectUsers")
                if(message){
                    if(includeEmailMsg){
                        message = message + this.$t('classMgmt.productAccessEmail',{role})
                    }
                    this.$emit('handleSuccess', message)
                }
            },
            toggleProductAccess(teachers, productId, onOrOff, dontUpdate, updateInvites, subs){
                this.$emit('toggleProductAccess', teachers, productId, onOrOff, dontUpdate, updateInvites, subs)
            },
            tooManyLicenses(){
                return false
            }
        }
    }
</script>

<style lang="less" scoped>
</style>