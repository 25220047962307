<template>
    <UserManagmentModal
        :value="value"
        :headline="$t('home.freemium.header')"
        size="500"
        @closeDialogs="closeDialog()"
    >
        <v-card-text>
            <p class="uppercase font-weight-medium grey--text text--darken-1">{{$t('home.freemium.intro')}}</p>
            <v-layout class="mb-3 pl-2">
                <v-flex xs1><v-icon color="success">new_releases</v-icon></v-flex>
                <v-flex>{{$t('home.freemium.bullet1')}}</v-flex>
            </v-layout>
            <v-layout class="mb-3 pl-2">
                <v-flex xs1><v-icon color="success">description</v-icon></v-flex>
                <v-flex>
                    {{$t('home.freemium.bullet2')}}
                    <br/>
                    <span class="caption grey--text text--darken-2">
                        {{$t('home.freemium.subbullet2')}}
                    </span>
                </v-flex>
            </v-layout>
            <v-layout class="mb-3 pl-2">
                <v-flex xs1><v-icon color="error">watch_later</v-icon></v-flex>
                <v-flex>
                    {{$t('home.freemium.bullet3')}}
                    <br/>
                    <span class="caption grey--text text--darken-2">
                        {{$t('home.freemium.subbullet3')}}
                    </span>
                </v-flex>
            </v-layout>
            <v-layout class="pl-2">
                <v-flex xs1 ><v-icon>sync</v-icon></v-flex>
                <v-flex>{{$t('home.freemium.bullet4')}}</v-flex>
            </v-layout>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
                :href="freemiumURL" 
                color="primary" 
                class="elevation-0" 
                target="_blank"
            >
                {{$t('home.freemium.freeGizmos')}}
            </v-btn>
        </v-card-actions>
    </UserManagmentModal>
</template>

<script>
    import UserManagmentModal from 'components/layout/UserManagmentModal'
    import { getUrls } from '@explorelearning/urls';

	export default {
        name: 'FormattingModal',
        components: { UserManagmentModal },
        props: {
            value: Boolean,
        },
		computed : {
            gizmosBaseURL() {
                return getUrls().gizmosApp
            },
            freemiumURL(){
                return this.gizmosBaseURL + '/free-gizmos';
            }
        },
        methods: {
            closeDialog() {
                this.$emit('closeDialog')
            },
        }
	}
</script>

<style lang="less" scoped>

</style>