<template>
    <UserManagmentModal
        :value="value"
        :headline="$t('classMgmt.editGradeLevelHeader')"
        :subHeadline="userCountOrName(editedStudent,$tc('classMgmt.students', editedStudent.length))"
        @closeDialogs="closeDialogs()"
    > 
        <v-card-text>
            <v-select
                v-model="editGradeLevelSelection"
                :items="gradeLevelIDs"
                item-text="label"
                item-value="id"
                :label="$t('classMgmt.gradeLevel')"
                prepend-icon="school"
            ></v-select>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="updateGradeLevels(editedStudent,editGradeLevelSelection)" :disabled="editGradeLevelSelection===null" class="elevation-0">
                <v-icon>check</v-icon>&nbsp;{{$t('classMgmt.accept')}}
            </v-btn>
        </v-card-actions>
    </UserManagmentModal>
</template>

<script>
    import Constants from 'mixins/Constants'
    import UserManagmentModal from 'components/layout/UserManagmentModal'
    import { mapActions } from 'vuex'

	export default {
        name: 'EditGradeLevelModal',
        components: { UserManagmentModal },
        mixins: [Constants],
        props: {
            institutionID: Number,
            selectedUsers: Array,
            tenantID: Number,
            userCountOrName: Function,
            value: Boolean,
        },
        data() {
            return {
                editGradeLevelSelection: null,
                editedStudent: [],
            }
        },
		computed : {
            editGradeLevelAlert(){
                return this.editGradeLevelSelection > 0 && this.editGradeLevelSelection < 3
            },
             platform(){
                 return this.$store.state.platform
            },
        },
        methods: {
            ...mapActions('platformData/students', ['updateStudents']),
            async updateGradeLevels(students, gradeLevelID){
                students.forEach(function(student){
                    student.gradeId = gradeLevelID
                    if(typeof student.reflexJson == 'object'){
                        student.reflexJson = JSON.stringify(student.reflexJson)
                    }
                })
                let institutionId = this.institutionID
                let tenantId = this.tenantID
                let self = this;
                (async function() {
                    await self.updateStudents({institutionId, tenantId, students})
                    self.$emit("updateModel")
                    self.$emit('closeDialogs')
                })()
            },
            closeDialogs() {
                this.$emit('closeDialogs')
            },
        },
        watch: {
            value(val) {
                if (val)
                {
                    this.editedStudent = this.selectedUsers
                    if(this.selectedUsers.length==1){
                        this.editGradeLevelSelection = this.selectedUsers[0].gradeId
                    } else {
                        this.editGradeLevelSelection = null
                    }
                }
            }
        }
	}
</script>

<style lang="less" scoped>
</style>