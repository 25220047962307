<template>
	<v-layout wrap>
    	<Headline :headline="$t('grantComplete.header')"></Headline>
    	<v-flex xs12 mb-3>{{$t('grantComplete.message0')}}</v-flex>
    	<v-flex xs12>{{$t('grantComplete.message1')}}</v-flex>
    	<ul>
    		<li v-html="$t('grantComplete.nextStep0')"></li>
    		<li v-html="$t('grantComplete.nextStep1')"></li>
    	</ul>
    	<v-flex xs12 mt-3>{{$t('grantComplete.message2-0')}}&nbsp;<a href="mailto:elgrants@explorelearning.com">{{$t('grantComplete.grantsTeam')}}</a>.</v-flex>
    </v-layout>
</template>

<script>
  import Headline from 'components/layout/Headline'
  import EnvironmentDetector from 'mixins/EnvironmentDetector'
  export default {
    name: 'GrantComplete',
    metaInfo : {
		title : 'Complete'
	},
    components: {Headline},
    mixins: [EnvironmentDetector],
    created(){
  		if(!this.$store.state.navigation.allowConfirm){
    		this.$router.replace({name:'GrantTerms'})
    	}
  	}
  }
</script>
