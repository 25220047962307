<template>
	<div>
		<form v-if="displayReady" @submit.prevent="next">
			<v-layout wrap>
				<Headline :headline="$t('classCode.headline')"></Headline>
			</v-layout>
			<v-layout wrap justify-space-between class="join-class-info-box" v-if="step>0">
				<v-flex shrink ml-2 my-2 xs8>
					<div class="font-weight-bold title pb-1">{{ this.class.className }}</div>
					<div>Teacher: {{ teacherName }}</div>
				</v-flex>
				<v-flex shrink ml-auto align-self-center xs3>
					<span class="class-code-display-box px-2 ml-1 font-weight-medium">EL{{ this.class.classCode }}</span>
				</v-flex>
			</v-layout>
			<v-layout wrap>
				<Messaging outline class="mb-0 mt-3"></Messaging>
			</v-layout>
			<!-- Step 0: Solicit ClassCode -->
			<v-layout wrap v-if="step==0">
                <p class="subheading">{{ $t('classCode.instructionsStep0') }}</p>
				<v-flex xs12>
					<v-text-field
						id="classCodeField"
						key="classCodeField"
                        v-model="classCode"
                        :label="$t('classCode.classCode')"
                        v-validate="classCodeValidation"
                        name="class code"
                        :error-messages="errors.collect('class code')"
                        :hint="$t('classCode.classCodeHint')"
                        :persistent-hint="true"
                    >
                        <template #append>
                            <v-tooltip left>
                                <template #activator="{ on }">
                                    <v-icon color="info" v-on="on" class="text--lighten-2">help</v-icon>
                                </template>
                                <span v-html="$t('classCode.classCodeTooltip')" />
                            </v-tooltip>
                        </template>
			      </v-text-field>
				</v-flex>
			</v-layout>
			<!-- Step 1: Account Choice -->
			<v-layout wrap v-if="step==1" mb-4>
				<v-flex xs12 v-if="loggedIn">
					<v-btn @click="enrollStudentExisting()" color="primary" large block class="mb-4">
						{{ $t('classCode.join') }}
					</v-btn>
				</v-flex>
				<v-flex xs12 v-else>
					<p class="subheading">{{ $t('classCode.instructionsStep1') }}</p>
					<v-btn @click="step=2" color="primary" large block class=mb-4>
						{{ $t('regCode.makeAccount') }}
					</v-btn>
					<v-btn @click="step=3" color="primary" flat large block>
						{{ $t('regCode.haveAccount') }}
					</v-btn>
				</v-flex>
			</v-layout>
			<!-- Step 2: Create New Student -->
			<v-layout wrap v-if="step==2">
				<v-flex xs12>
					<p class="subheading">{{ $t('classCode.instructionsStep2') }}</p>
				</v-flex>
				<v-flex xs12 sm6>
					<v-text-field
						id="firstNameField"
						key="firstNameField"
                        v-model="firstName"
                        :label="$t('form.firstName')"
                        v-validate="'required|max:30|noTags|noEscapes'"
                        name="first name"
                        :error-messages="errors.collect('first name')"
                    />
				</v-flex>
				<v-flex xs12 sm6>
					<v-text-field
						id="lastNameField"
						key="lastNameField"
                        v-model="lastName"
                        :label="$t('form.lastName')"
                        v-validate="'required|max:30|noTags|noEscapes'"
                        name="last name"
                        :error-messages="errors.collect('last name')"
                    />
				</v-flex>
				<v-flex xs6>
					<v-text-field
						id="usernameField"
						key="usernameField"
                        v-model="userName"
                        :label="$t('form.userName')"
                        v-validate="'required|max:50|noTags|noEscapesExceptAt|noSpaces'"
                        name="username"
                        :error-messages="errors.collect('username')"
						v-on:blur="checkAvalbility(false)"
						:append-icon="userNameAvalible ? '' : 'cancel'"
						:error="!userNameAvalible"
						:rules="userNameAvalible ? [] : [()=>($t('error.usernameUnavailable'))]"
                    />
				</v-flex>
				<v-flex xs6>
					<v-select
						v-model="gradeLevelID"
						:items="gradeLevelIDs"
						item-text="label"
						item-value="id"
						:label="$t('classMgmt.gradeLevel')"
						v-validate="'required'"
						name="grade level"
						:error-messages="errors.collect('grade level')"
					></v-select>
				</v-flex>
				<v-flex xs6>
					<v-text-field
						id="passwordField"
						key="passwordField"
                        v-model="password"
                        :label="$t('form.password')"
                        v-validate="'required|max:30'"
                        name="password"
						type="password"
                        :error-messages="errors.collect('password')"
						ref="password"
                    />
				</v-flex>
				<v-flex xs6>
					<v-text-field
						id="passwordConfirmField"
						key="passwordConfirmField"
                        v-model="passwordConfirm"
                        :label="$t('form.passwordConfirm')"
                        v-validate="'confirmed:password|required'"
                        name="password confirmation"
						type="password"
                        :error-messages="errors.collect('password confirmation')"
                    />
				</v-flex>
</v-layout>
			<!-- Step 3: Login -->
			<v-layout wrap v-if="step==3">
				<v-flex xs12>
					<p class="subheading">{{ $t('classCode.instructionsStep3') }}</p>
				</v-flex>
				<v-flex xs12>
					<v-text-field
						id="usernameField"
						key="usernameField"
                        v-model="userName"
                        :label="$t('form.userName')"
                        v-validate="'required|max:50'"
                        name="student ID"
                        :error-messages="errors.collect('student ID')"
                    />
				</v-flex>
				<v-flex xs12>
					<v-text-field
						id="passwordField"
						key="passwordField"
                        v-model="password"
                        :label="$t('form.password')"
                        v-validate="'required|max:30'"
                        name="password"
						type="password"
                        :error-messages="errors.collect('password')"
                    />
				</v-flex>
			</v-layout>
			<!-- Step 10: Success -->
			<v-layout wrap v-if="step==10">
				<v-flex xs12>
					<p v-html="$t('classCode.successLogin',{className, loginURL:getStudentLoginURL()})" />
					<p v-html="$t('classCode.successCard')" />
				</v-flex>
</v-layout>
			<!-- Buttons -->
			<v-layout v-if="step<10" v-show="showBtns" wrap class="my-3">
				<v-flex xs6 class="text-xs-left pa-0">
					<v-btn v-show="showBackButton" class="ml-0" large flat color="primary" @click="back()">
						{{$t('navigation.back')}}
					</v-btn>
				</v-flex>
				<v-flex xs6 class="text-xs-right pa-0">
					<v-btn color="primary" class="mr-0" large raised type="submit" :disabled="disableNextBtn">
						{{$t('navigation.next')}}
					</v-btn>
				</v-flex>
			</v-layout>
			<v-layout v-else wrap class="mt-3">
				<v-flex xs6 class="text-xs-left pa-0">
					<v-btn large flat color="primary" class="ml-0" @click="onSave('cards', student)">
						{{$t('classCode.downloadLoginCard')}}
					</v-btn>
				</v-flex>
				<v-flex xs6 class="text-xs-right pa-0">
					<v-btn v-if="displayClassLink"  color="primary" class="mr-0" large raised :href="classLinkURL">
						{{$t('classCode.goToClass')}}
					</v-btn>
				</v-flex>
			</v-layout>
			<!-- Footers -->
			<v-footer absolute v-if="step==1">
				<div class="center">
					{{$t('classCode.wrongClass')}}
					<a @click="back()">{{ $t('classCode.tryAgain') }}</a>
				</div>
			</v-footer>
			<v-footer absolute v-if="step==2">
				<div class="center">
					{{$t('email.footer')}}
					<a @click="step=3">{{ $t('regCode.haveAccount') }}</a>
				</div>
			</v-footer>
			<v-footer absolute v-if="step==3">
				<div class="center">
					{{$t('classCode.footer')}}
					<a @click="step=2">{{ $t('regCode.makeAccount') }}</a>
				</div>
			</v-footer>
		</form>
	</div>
</template>

<script>
	import APIs from 'mixins/APIs'
	import Constants from 'mixins/Constants'
	import ErrorHandling from 'mixins/ErrorHandling'
	import Headline from 'components/layout/Headline'
	import LoginDetector from 'mixins/LoginDetector'
	import Messaging from 'components/layout/Messaging'
	import PrintHelper from 'mixins/PrintHelper'
	import UserNameAvalibility from 'mixins/UserNameAvalibility'
	import _debounce from 'lodash/debounce'
	import { getUrls } from '@explorelearning/urls';
	export default {
		name: 'StudentSelfEnrollment',
		metaInfo() {
            return{
                title : this.title
            }
        },
		components: {
			Headline,
			Messaging
		},
		mixins: [APIs, Constants, ErrorHandling, LoginDetector, PrintHelper, UserNameAvalibility],
		data () {
			return {
                class : {},
				classCode: null,
				debounceUserNameCheck: null,
				displayClassLink: true,
				disableNextBtn: false,
				displayReady: false,
				firstName: null,
				gradeLevelID: null,
				lastName: null, 
				password: null,
				passwordConfirm: null,
                step: 0,
				student: {},
                teacherName: null,
                tenantID: null,
				userClaims: {},
				userName: null
			}
		},
		computed : {
			classCodeStripped(){
				const firstTwoLetters = this.classCode.trim().substring(0,2).toLowerCase()
				if(firstTwoLetters == 'el'){
					return this.classCode.substring(2)
				} else {
					return this.classCode
				}
			},
			classCodeValidation(){
				let validators = 'required'
				if(this.classCode && this.classCode.trim().indexOf('el')==0){
					validators = validators + '|length:8'
				}
				return validators
			},
			classLinkURL(){
				return this.getStudentAppURL(this.product) + '&classID=' + this.class.classID
			},
			legacyBaseURL(){
                return getUrls().gizmosLegacy
            },
			studentName(){
				return this.$store.state.platformData.app.claims.first + ' ' + this.$store.state.platformData.app.claims.last
			},
			showBackButton(){
				return this.step > 0
			},
			showBtns(){
				return this.step !== 1
			},
            className(){
                return this.class.className || null
            }
		},
		watch : {
			async userName() {
				if (this.step == 2 && !this.checkingUserName && this.userName.length >0) {
					await this.debounceUserNameCheck()
				}
			}
		},
		async created(){
			//defining debounce function here otherwise it wouldnt be instantiated before the watcher
			this.debounceUserNameCheck = _debounce(async () => {
				await this.checkAvalbility(false)
			}, 200)
			this.classCode = this.$route.query.classCode || this.$route.params.classCode
			if(this.classCode){
				this.sendCode()
			} else {
				this.displayReady = true
			}
		},
		methods : {
			back(){
				this.$store.commit('setMessageError',null)
				this.userName = null
				this.password = null
				const backBtnMap = {
					1 : 0,
					2 : 1,
					3 : 1
				}
				this.step = backBtnMap[this.step]
			},
			async next() {
				this.disableNextBtn = true
				var valid = await this.$validator.validateAll()
                if(valid){
					switch(this.step){
						case 0: 
							await this.sendCode()
							break;
						case 2:
							await this.enrollStudentNew()
							break;
						case 3:
							await this.studentLogin()
							break;
						default:
							this.disableNextBtn = false
					}
				} else {
					this.disableNextBtn = false
				}
			},
			async enrollStudentExisting(){
				let claimsSource = {}
				if(this.userClaims.sessionID){
					claimsSource = this.userClaims
				} else {
					claimsSource = this.$store.state.platformData.app.claims
				}
				const enrollmentInfo = {
					classCode : this.classCodeStripped,
					tenantID : this.tenantID,
					sessionID: claimsSource.sessionID,
					loginID : claimsSource.logID,
					username : claimsSource.user_name,
				}
				this.$http.post(this.getStudentSelfEnrollmentExisting(),enrollmentInfo,this.jtiHeader).then(response =>{
					this.student = {
						firstName : claimsSource.first,
						lastName : claimsSource.last,
						username : response.data.LoginReturnEntity.username,
						password : response.data.LoginReturnEntity.password,
						isClassCodeEnabled : true
					}
					this.verifyProductAccess(response,3)
					this.showSuccess()
				}, error => {
					this.handleError(error.response)
					this.step = 1
					this.displayReady = true
				})
			},
			async enrollStudentNew(){
				var valid = await this.$validator.validateAll()
				if(valid && this.userNameAvalible){
					const student = {
						classCode : this.classCodeStripped,
						tenantID : this.tenantID,
						firstname : this.firstName,
						lastname : this.lastName,
						username : this.userName,
						password : this.password,
						gradeLevelID: this.gradeLevelID,
						isClassCodeEnabled: true
					}
					this.$http.post(this.getStudentSelfEnrollmentNew(),student,this.jtiHeader).then(response =>{
						this.student = {
							firstName : student.firstname,
							lastName : student.lastname,
							username : student.username,
							password : student.password,
							isClassCodeEnabled : true
						}
						this.verifyProductAccess(response,3)
						this.$http.post(this.getLoginAPI('app', false,), {"username": this.userName, "password": this.password}).then(response => {
								this.showSuccess()
							}, error => {
								this.showSuccess()
							})
					}, error => {
						this.handleError(error.response)
					}).finally(() => {
						this.disableNextBtn = false
					})
				} else {
					this.disableNextBtn = false
				}
			},
			async sendCode(){
				let payload = {classCode:this.classCode.trim()}
				const firstTwoLetters = payload.classCode.substring(0,2).toLowerCase()
				if(firstTwoLetters == 'el'){
					// Try Code on Platform
					payload.classCode = this.classCodeStripped
					this.$http.post(this.getValidateClassCodeAPI(),payload,this.jtiHeader).then(response => {
						this.$store.commit('setMessageError',null)
						const responseData = response.data.AuthClassInfo
						this.tenantID = responseData.tenantID
						this.teacherName = responseData.teacherDisplayName
						this.class = responseData.classes[Object.keys(responseData.classes)[0]]						
						this.step++
						this.displayReady = true
					}, error => {
						this.handleError(error.response)
						this.displayReady = true
					}).finally(() => {
						this.disableNextBtn = false
					})
				} else {
					const legacyClassCodeValidation = this.legacyBaseURL + '?method=cUser.actVerifyClassCodePlatform'
					this.$http.post(legacyClassCodeValidation,payload).then(response => {
						if(response.data.SUCCESS){
							window.location = this.legacyBaseURL + response.data.REDIRECTURL
						} else {
							this.$store.commit('setMessageError',response.data.ERRORMSG)
							this.displayReady = true
						}
					}, error => {
						this.$store.commit('setMessageError',this.$t('classCode.invalidCode'))
						this.displayReady = true
					}).finally(() => {
						this.disableNextBtn = false
					})
				}
            },
			showSuccess(){
				this.step = 10
				this.$store.commit('setMessageError',null)
				this.$store.commit('setMessageInfo',null)
				this.$store.commit('setMessageSuccess',this.$t('classCode.success',{name:this.className}))
				this.displayReady = true
			},
			async studentLogin(){
				this.$http.post(this.getClassesAPI(),{username: this.userName.trim()}).then(response => {
					// Student Is Trying to Use Teacher Username
					this.$store.commit('setMessageError',this.$t('classCode.loginWithTeacherUsername'))
					this.userName = null
					this.password = null
					// this.step = 2
				}, error2 => {
					this.$http.post(this.getLoginAPI('app', false,), {"username": this.userName, "password": this.password}).then(response => {
						this.userClaims = this.decodeXclaims(response)
						this.enrollStudentExisting()
					}, error => {
						let errorID = this.handleError(error.response, [14]).errorID
						if(errorID == 14){
							this.$store.commit('setMessageError',this.$t('classCode.invalidCreds'))
						}
					}).finally(() => {
						this.disableNextBtn = false
					})
				}).finally(() => {
					this.disableNextBtn = false
				})
			},
			verifyProductAccess(response,prodID, warnMsg = 'error.error-1251'){
				let hasProduct = response.data.LoginReturnEntity.licenses.includes(prodID)
				if(!hasProduct){
					this.displayClassLink = false
					this.$store.commit('setMessageWarning', this.$t(warnMsg))
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.join-class-info-box {
		background-color: #edf6ff;
		border-radius: 5px;
		color: #1976d2;
	}
	.class-code-display-box {
		background-color: #1976d2;
		border-radius: 5px;
		color: #FFFFFF;
	}
</style>