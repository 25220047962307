<template>
    <span>
        <div id="el-logo" class="el-logo">
            <img src="../../assets/explorelearning/img/el.svg" alt="ExploreLearning Logo"/>
        </div>
        <span v-if="text" class="el-orange-text el-logo-title-text">{{text}}</span>
    </span>
</template>

<script>
    export default {
        name: 'EL Logo',
        props: {
            text : {
                type: String,
                default: null
            }
        },
    }
</script>

<style scoped lang="less">
    * {
        display:inline-block
    }
	.el-logo-title-text {
        font-size: 18px!important;
        padding-top: 7px;
        margin-left: 10px;
        vertical-align: top;
    }
</style>