import APIs from 'mixins/APIs'
export default {
	mixins: [APIs],
	computed: {
		ignoreReferrer(){
			return this.$store.state.navigation.ignoreReferrer || false
		}
	},
	methods: {
		buildExternalLink(externalURL, queryStringObject, hashValue){
			let url = externalURL
			let numKeys = Object.keys(queryStringObject).length
			if(numKeys){
				let keyCount = 0
				if(externalURL.indexOf('?')==-1){
					url = url+'?'
				} else {
					url = url+'&'
				}
				for(let queryStringKey in queryStringObject){
					url = url+queryStringKey+'='+queryStringObject[queryStringKey]
					++keyCount
					if(keyCount < numKeys){
						url = url+'&'
					}
				}
			}
			if(hashValue){
				url = url + '#' + hashValue
			}
			return url
		},
		extractGizmoId(checkStore = true){
            let gizmoID = null
			if(checkStore && this.$store.state.navigation.legacyLogin){
				gizmoID = this.$store.state.navigation.legacyLogin
			} else {
				const referrer = document.referrer
				let query = window.location.search
				let querySearch = 'gizmos='
				if(query.includes(querySearch)){
					let gizmoIdIndex = query.indexOf(querySearch) + querySearch.length
					gizmoID = query.substring(gizmoIdIndex)
				} else if(referrer.includes(window.location.hostname)){
					const searchStrings = [
						// Legacy Website
						'cResource.dspDetail&ResourceID=',
						'cResource.dspView&ResourceID=',
						// Grizzly Website
						'/gizmos/gizmo-detail/',
						'/gizmos/launch-gizmo/',
						'/gizmos/case-detail/',
						// Moncur Website
						'/find-gizmos/lesson-info?resourceId=',
						'/find-gizmos/launch-gizmo?resourceId='
					]
					searchStrings.forEach(searchString => {
						if(referrer.includes(searchString)){
							let gizmoIdIndex = referrer.indexOf(searchString) + searchString.length
							gizmoID = referrer.substring(gizmoIdIndex)
						}
					})
				}
				if(gizmoID && gizmoID.includes("&")){
					const ampIndex = gizmoID.indexOf("&")
					gizmoID = gizmoID.substring(0,ampIndex)
				}
			}
			gizmoID = Number(gizmoID)
			if(Number.isInteger(gizmoID) && gizmoID > 1){
				this.$store
				return gizmoID
			} else {
            	return null
			}
		},
		forward : function(defaultURL, queryStringObject, newWindow){
			let forwardTo = null
			if(this.$store){
				forwardTo = this.$store.state.navigation.forwardingURL
			}
			if(forwardTo){
				this.$store.commit('setForwardingURL', null)
				this.go(forwardTo, queryStringObject, newWindow)
			} else if(document.referrer.search(window.location.origin+'.*(?!account)/(reporting|gizmos|s4us)/') > -1 && !this.ignoreReferrer){
				this.go(document.referrer, queryStringObject, newWindow)
			} else {
				this.go(defaultURL, queryStringObject, newWindow)
			}
		},
		go : function(destination, queryStringObject){
			if (typeof destination == 'string' || destination instanceof String) {
				destination = decodeURIComponent(destination)
				if(queryStringObject){
					// Pull Off The Hash
					var hash = ''
					if(destination.includes('#')){
						var splitURL = destination.split('#')
						destination = splitURL[0]
						hash = splitURL[1]
					}
					destination = this.buildExternalLink(destination, queryStringObject, hash)
				}
				if(destination.indexOf('../')>=0){
					window.location.href = destination
				} else {
					if(destination.indexOf('http')!=0){
						destination = 'https://' + destination
					}
					window.location.href = destination
				}
			} else {
				this.$router.replace(destination)
			}
		},
		refreshAndForward(URL){
			this.$http.get(this.getRefreshAPI(),this.jtiHeader).then(response=>{
				let userInfo = this.decodeXclaims(response)
				this.$store.commit('setJTI', userInfo.jti)
				this.forward(URL)
			},error=>{
				console.log(error)
			})
		}
	}
}
