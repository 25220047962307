<template>
    <UserManagmentModal
        :value="value"
        :headline="$t('classMgmt.deleteClassHeader')"
        @closeDialogs="closeDialogs()"
    >
        <v-card-text>
            <p v-html="$t('classMgmt.areYouSureDelete', {className: classObj.name})" />
            <p v-if="exceedsCap">
                <span v-if="isRostered" v-html="$t('classMgmt.deleteWhileAboveCapRostered',{cap: classCap, className: classObj.name, numClasses})" />
                <span v-else v-html="$t('classMgmt.deleteWhileAboveCap',{cap: classCap, className: classObj.name, numClasses})" />
            </p>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" class="elevation-0" @click="deleteClass">
                <v-icon>delete</v-icon>&nbsp;{{$t('classMgmt.deleteClassHeader')}}
            </v-btn>
        </v-card-actions>
    </UserManagmentModal>
</template>

<script>
    import UserManagmentModal from 'components/layout/UserManagmentModal'

	export default {
        name: 'DeleteClassModal',
        components: { UserManagmentModal },
        props: {
            classCap: Number,
            classObj: Object,
            institutionID: Number,
            isRostered: Boolean,
            numClasses: Number,
            value: Boolean,
        },
		computed : {
            exceedsCap(){
                return this.numClasses > this.classCap
            },
            platform(){
                 return this.$store.state.platform
            }
        },
        methods: {
            closeDialogs() {
                this.$emit('closeDialogs')
            },
            async deleteClass(){
                let id = this.classObj.id
                let institutionId = this.institutionID
                let self = this;
                (async function() {
                    await self.platform.deleteClass({id, institutionId})                    
                    await self.$emit("updateModel")            
                    self.closeDialogs()
                })() 
            },
        }
	}
</script>

<style lang="less" scoped>
</style>