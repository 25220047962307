<template>
    <UserManagmentModal
        :value="value"
        :headline="title"
        :subHeadline="subtitle"
        size="500"
        @closeDialogs="closeDialogs()"
    >
        <v-card-text>
            <Messaging outline class="mb-3"/>
            <v-layout wrap>
                <v-select
                    :items="getDropdownItems(productID)"
                    :label="'Select ' + productDisplayName(productID)  + ' Subscription'"
                    v-model="selectedSubscription"
                    item-text="name"
                    :return-object="true"
                    :name="productDisplayName(productID) + ' subscription'"
                    v-validate="'required'"
                    :error-messages="errors.collect(productDisplayName(productID) + ' subscription')">
                    <template #item="subscription">
                        <h4 class="mr-auto">{{subscription.item.name.length > 30 ? subscription.item.name.slice(0, 29) + '&hellip;' : subscription.item.name}}</h4>
                        {{subscription.item.settings ? `${new Date(subscription.item.settings.startsOn).toLocaleDateString("en-US")} - ${new Date(subscription.item.settings.expiresOn).toLocaleDateString("en-US")}` : ''}}
                        <br/>
                        {{subscription.item.settings ? createCapString(subscription.item) : ''}}
                    </template>
                </v-select>
            </v-layout>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="setProductAccess()" class="elevation-0" :disabled="disableSubmit">
                <v-icon>check</v-icon> {{$t('classMgmt.accept')}}
            </v-btn>
        </v-card-actions>
    </UserManagmentModal>
</template>

<script>
    import UserManagmentModal from 'components/layout/UserManagmentModal'
    import Messaging from 'components/layout/Messaging'
    import ProductInfo from 'mixins/ProductInfo'
    import SubscriptionHelper from 'mixins/SubscriptionHelper'

	export default {
        name: 'MultiSubAccessModal',
        components: { Messaging, UserManagmentModal },
        mixins: [ProductInfo, SubscriptionHelper],
        props: {
            productID: Number,
            productIDs: Array,
            subSeatInfo: Function,
            selectedNonStudent: Array,
            selectedSchool: Object,
            value: Boolean,
        },
        data() {
            return {
                permissions : {},
                selectedSubscription : {},
            }
        },
		computed : {
            availableSubscriptionSeats(){
                let counts = {}
                if (Object.keys(this.selectedSubscription).length) {
                    Object.values(this.selectedSubscription).forEach(sub => {
                        let seatInfo = this.subSeatInfo(sub)
                        counts[sub] = seatInfo.teacherDelta
                    })
                }
                return counts
            },
            disableSubmit(){
                if (this.seatsExceeded.length) {
                    return true
                } else if (this.selectedTeacher && this.productID && this.selectedSubscription.id) {
                    let teacherLicenses = this.selectedTeacher.licenses.filter(l => l.matchingLicense && l.productId == this.productID).map(l => l.subscriptionId).sort()
                    let selectedLicenses = [this.selectedSubscription.id];
                    return (JSON.stringify(teacherLicenses) === JSON.stringify(selectedLicenses))
                } else {
                    return this.selectedSubscription.id === null ? false : true 
                }
            },
            newUsersBeingAdded(){
                if (this.selectedNonStudent[0] && Object.keys(this.selectedSubscription).length && this.selectedSubscription.id) {
                    return this.selectedNonStudent[0].licenses.map(l => l.subscriptionId).includes(this.selectedSubscription.id) ? 0 : 1
                } else {
                    return 0
                }
            },
            seatsExceeded(){
                let tooManySeats = []

                if (Object.keys(this.selectedSubscription).length) {
                    if(this.newUsersBeingAdded>[this.availableSubscriptionSeats[this.selectedSubscription.id]]){
                        tooManySeats.push(this.subSeatInfo(this.selectedSubscription.id))
                    }
                }

                return tooManySeats
            },
            selectedTeacher() {
                if (this.selectedNonStudent[0]) {
                    return this.selectedNonStudent[0]
                } else {
                    return {}
                }
            },
            subtitle() {
                if (this.selectedTeacher && this.selectedTeacher.firstName) {
                   return `${this.selectedTeacher.firstName} ${this.selectedTeacher.lastName}`
                } else if (this.selectedTeacher && !this.selectedTeacher.firstName) {
                    return `${this.selectedTeacher.email}`
                } else {
                    return ''
                }
            },
            title() {
                return this.productID ? `${this.productSettings[this.productID].displayName} Subscription Select` : ''
            },
            products(){
                let products = []
                this.productIDs.forEach(productID =>{
                    // ProductIDs can be trusted for Employees and CTs
                    this.subscriptions.forEach(sub => {
                        if(sub.productId==productID && sub.isActive && !sub.isExpired && !products.map(p => p.id).includes(sub.productId)){
                            products.push({id:productID, name:this.productDisplayName(productID)})
                        }
                    }) 
                })
                return products
            },
        },
        watch: {
            seatsExceeded(val){
                if(this.value){
                    let message = ''
                    val.forEach(product => {
                        product.newSeats = this.newUsersBeingAdded
                        product.teacherOverCap = product.teacherDelta * -1 + product.newSeats
                        if(message != ''){
                            message += '<br/><br/>'
                        }
                        message += this.$t('classMgmt.overTeacherLimit',product)
                    })
                    this.$store.commit('setMessageError', message)
                }
            },
            value(val){
                this.selectedSubscription = {};
                if (val && this.selectedTeacher && this.productID){
                    this.selectedTeacher.licenses.filter(l => l.matchingLicense && l.productId === this.productID).forEach(l => {
                        const matchingSub = this.subscriptions.find(s => s.id === l.subscriptionId);
                        if (matchingSub) {
                            this.selectedSubscription = matchingSub
                        }
                    });
                }
            }
        },
        methods: {
            closeDialogs() {
                this.$emit('closeDialogs', ['multiSubAccessModal'])
            },
            createCapString(sub) {
                if (sub) {
                    //gizmos with teacher plus license-show teacher cap
                    if (sub.productId == 3 && sub.licensingTypeId === 10 && sub.teachersCap > 0 && sub.teachersCap !== Infinity) {
                        return `${sub.teachersUsed}/${sub.teachersCap} Seats Used`
                    //Frax or reflex with teacher license-show students cap
                    } else if (sub.productId <= 2 && sub.licensingTypeId === 2 && sub.studentsCap > 0 && sub.studentsCap !== Infinity){
                        return `${sub.studentsUsed}/${sub.studentsCap} Seats Used`
                    } else {
                        return ''
                    }
                }
            },
            getDropdownItems(productID) {
                if (this.selectedTeacher.licenses) {
                    const items = this.getActiveInstitutionSubscriptions(productID, this.selectedSchool);
                    const hasLicense = this.selectedTeacher.licenses.some(l => l.productId === productID)
                    if (hasLicense) {items.push({name: 'No Subscription (disable)', id: null})}
                    return items
                } else {
                    return []
                }
            },
            async setProductAccess(){
                let message = ''
                const teacherLicenses = this.selectedTeacher.licenses.filter(l => l.matchingLicense && l.productId == this.productID).map(l => l.subscriptionId)
                const selectedLicenses = this.selectedSubscription.id ? [this.selectedSubscription.id] : null
                const licensesToAdd = selectedLicenses ? selectedLicenses.filter(s => !teacherLicenses.includes(s)) : []
                const licensesToRemove = selectedLicenses ? teacherLicenses.filter(l => !selectedLicenses.includes(l)) : teacherLicenses
                const updateInvites = this.selectedTeacher.inviteExpiresOn ? true : false

                if (licensesToAdd.length) {
                    try {
                        await this.toggleProductAccess(this.selectedNonStudent,this.productID,1,false, updateInvites, licensesToAdd)
                    } catch (error) {
                        console.log(error)
                    }
                }

                if (licensesToRemove.length) {
                    try {
                        await this.toggleProductAccess(this.selectedNonStudent,this.productID,0,false, updateInvites, licensesToRemove)
                    } catch (error) {
                        console.log(error)
                    }
                }

                this.closeDialogs()
                this.$emit("deselectUsers")
                this.$emit('handleSuccess', message)
            },
            toggleProductAccess(teachers, productId, onOrOff, dontUpdate, updateInvites, subs){
                this.$emit('toggleProductAccess', teachers, productId, onOrOff, dontUpdate, updateInvites, subs)
            },
            tooManyLicenses(){
                return false
            }
        }
    }
</script>

<style lang="less" scoped>
</style>