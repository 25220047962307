<template>
    <UserManagmentModal
        :value="value"
        :headline="editedNonStudent.firstName + ' ' + editedNonStudent.lastName"
        size="550"
        @closeDialogs="closeDialogs()"
    >
        <v-list>
            <v-flex v-if="userType=='Teachers'">
                <v-subheader>
                    {{$t('classMgmt.productAccess').toUpperCase()}}
                    <span v-if="isProductAccessEditable" class="caption ml-2">({{$t('classMgmt.toggleInstructions')}})</span>
                    <span v-else class="caption ml-2">({{$t('classMgmt.teacherProductAccess')}})</span>
                </v-subheader>
                <v-flex mb-2>
                    <ProfileProductList
                        @toggleProductAccess="toggleProductAccess"
                        @openMultiSubModal="openMultiSubModal"
                        :altLicenseSource="altLicenseSource[user[0].id]"
                        :checkProductUpgrade="checkProductUpgrade"
                        :ownedProducts="ownedProducts"
                        :user="editedNonStudent"
                        :hasProductSubscription="hasProductSubscription"
                        :selectedSchool="selectedSchool"
                        :subscriptions="subscriptions"
                        :editable="isProductAccessEditable"
                        :isTeacher="true"
                        :seatLimitReached="seatLimitReached"
                    ></ProfileProductList>
                </v-flex>
                <v-divider></v-divider>
            </v-flex>
            <v-subheader>
                <v-icon v-if="editedNonStudent.isRostered" small>sync_alt</v-icon>
                <span v-if="editedNonStudent.isRostered">&nbsp;{{$t('classMgmt.rostered')}}&nbsp;</span>{{$t('classMgmt.profile')}}
                <v-spacer></v-spacer>
                <v-list-tile-action v-if="allowEditing">
                    <v-btn v-if="editNonStudentModalEditMode" flat color="error" @click="exitNonStudentModalEditMode()">
                        <v-icon>cancel</v-icon>&nbsp;{{$t('classMgmt.cancel')}}
                    </v-btn>
                    <v-btn v-else flat color="primary" @click="enterNonStudentEditMode(editedNonStudent)">
                        <v-icon>edit</v-icon>&nbsp;{{$t('classMgmt.edit')}}
                    </v-btn>
                </v-list-tile-action>
            </v-subheader>
            <form @submit.prevent="updateNonStudentProfile(editedNonStudent,editedNonStudentInfo)">
                <v-flex class="mx-4 mb-3">
                    <Messaging outline></Messaging>
                </v-flex>
                <v-list-tile avatar>
                    <v-list-tile-avatar>
                        <v-icon>perm_identity</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content v-if="editNonStudentModalEditMode">
                        <v-layout wrap>
                            <v-flex xs2 class="pr-1">
                                <v-select                                                
                                    v-model="editedNonStudentInfo.titleID"
                                    :items="userTitles"
                                    item-text="label"
                                    item-value="id"
                                    :label="$t('form.title')"
                                ></v-select>
                            </v-flex>
                            <v-flex xs3 class="pr-1">
                                <v-text-field
                                    v-model="editedNonStudentInfo.firstName"
                                    label="First Name *"
                                    v-validate="'required|max:30|noEscapes'"
                                    name="first name"
                                    :error-messages="errors.collect('first name')"
                                    hide-details
                                    data-vv-validate-on="change"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs3 class="pr-1">
                                <v-text-field
                                    v-model="editedNonStudentInfo.middleName"
                                    label="Middle Name"
                                    v-validate="'max:30|noEscapes'"
                                    placeholder=" "
                                    name="middle name"
                                    :error-messages="errors.collect('middle name')"
                                    hide-details
                                    data-vv-validate-on="change"
                                ></v-text-field>
                            </v-flex>
                            <v-flex xs4>
                                <v-text-field
                                    v-model="editedNonStudentInfo.lastName"
                                    label="Last Name *"
                                    v-validate="'required|max:30|noEscapes'"
                                    name="last name"
                                    :error-messages="errors.collect('last name')"
                                    hide-details
                                    data-vv-validate-on="change"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-list-tile-content>
                    <v-list-tile-content v-else>
                        <v-list-tile-sub-title>{{$t('classMgmt.fullName')}}</v-list-tile-sub-title>
                        <v-list-tile-title>
                            <span v-if="editedNonStudent.titleID">{{getTitle(editedNonStudent.titleID)}}&nbsp;</span>{{editedNonStudent.firstName}}&nbsp;<span v-if="editedNonStudent.middleName">{{editedNonStudent.middleName}}&nbsp;</span>{{editedNonStudent.lastName}}
                        </v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
                <v-list-tile avatar>
                    <v-list-tile-avatar>
                        <v-icon>assignment_ind</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content v-if="editNonStudentModalEditMode">
                        <v-layout wrap class="fullwidth">
                            <v-flex xs12>
                                <v-text-field
                                    v-model="editedNonStudentInfo.username"
                                    label="User Name *"
                                    v-validate="'required|max:50|noEscapesExceptAt'"
                                    name="username"
                                    :error-messages="errors.collect('username')"
                                    hide-details
                                    data-vv-validate-on="change"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-list-tile-content>
                    <v-list-tile-content v-else>
                        <v-list-tile-sub-title>{{$t('classMgmt.userName')}}</v-list-tile-sub-title>
                        <v-list-tile-title>{{editedNonStudent.username}}</v-list-tile-title>
                    </v-list-tile-content>
                </v-list-tile>
                <v-list-tile avatar>
                    <v-list-tile-avatar>
                        <v-icon>alternate_email</v-icon>
                    </v-list-tile-avatar>
                    <v-list-tile-content v-if="editNonStudentModalEditMode">
                        <v-layout wrap class="fullwidth">
                            <v-flex xs10>
                                <v-text-field
                                    v-model="editedNonStudentInfo.email"
                                    label="Email *"
                                    v-validate="'required|max:100'"
                                    name="email"
                                    :error-messages="errors.collect('email')"
                                    hide-details
                                    data-vv-validate-on="change"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-list-tile-content>
                    <v-list-tile-content v-else>
                        <v-list-tile-sub-title>{{$t('classMgmt.email')}}</v-list-tile-sub-title>
                        <v-list-tile-title>{{editedNonStudent.email}}</v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                        <v-btn v-if="editNonStudentModalEditMode" color="primary px-3" type="submit" class="elevation-0">
                            <v-icon>check</v-icon>&nbsp;{{$t('classMgmt.accept')}}
                        </v-btn>
                    </v-list-tile-action>
                </v-list-tile>
            </form>
        </v-list>
        <v-divider></v-divider>  
        <v-card-actions>
            <v-spacer v-if="!allowEditing"></v-spacer>
            <v-btn color="primary" flat @click="openResetPasswordDialog()" :disabled="editNonStudentModalEditMode" class="elevation-0">
                <v-icon>cached</v-icon>&nbsp;{{$t('classMgmt.resetPassword')}}
            </v-btn>
            <v-spacer v-if="allowEditing"></v-spacer>
            <v-btn v-if="allowEditing" color="error" flat @click="openDeleteUserDialog()" :disabled="editNonStudentModalEditMode" class="elevation-0">
                <v-icon>delete</v-icon>&nbsp;{{$t('classMgmt.delete')}}
            </v-btn>
        </v-card-actions>
    </UserManagmentModal>
</template>

<script>
    import APIs from 'mixins/APIs'
    import Constants from 'mixins/Constants'
    import Messaging from 'components/layout/Messaging'    
    import ProfileProductList from 'components/layout/ProfileProductList'
    import UserManagmentModal from 'components/layout/UserManagmentModal'
    import { mapActions } from 'vuex'

    export default {
        name: 'EditNonStudentModal',
        components: { Messaging, ProfileProductList, UserManagmentModal },
        mixins: [APIs, Constants],
        props: {
            altLicenseSource: Object,
            checkProductUpgrade: Function,
            editGradeLevelSelection: Number,
            institutionID: Number,
            isProductAccessEditable: Boolean,
            isUserEditable: Boolean,
            multiProduct: Boolean,
            ownedProducts: Array,
            products: Array,
            seatInfo: Function,
            selectedSchool: Object,
            subscriptions: Array,
            tenantID: Number,
            user: Array,
            userType: String,
            value: Boolean,
        },
        data() {
            return {
                editNonStudentModalEditMode: false,
                editedNonStudentInfo: {},
                editedNonStudent: {},
                editNonStudentModalRequiredAlert: false,
            }
        },
		computed : {
            allowEditing(){
                let allowEdits = this.isUserEditable
                let isAdmin = this.userType.toLowerCase()=='administrators'
                let isNotRostered = !this.editedNonStudent.isRostered
                return allowEdits && (isAdmin || isNotRostered)
            },
            availableSeats(){
                let counts = {}
                this.products.forEach(productID => {
                    let seatInfo = this.seatInfo(productID)
                    counts[productID] = seatInfo.teacherDelta
                })
                return counts
            },
            platform(){
                 return this.$store.state.platform
            },
            seatLimitReached(){
                let tooManySeats = []
                this.products.forEach(productID => {
                    if(this.availableSeats[productID] <= 0){
                        tooManySeats.push(this.seatInfo(productID))
                    }
                })
                return tooManySeats
            },
        },
        methods: {
            ...mapActions('platformData/teachers', ['updateTeachers']),
            ...mapActions('platformData/administrators', ['updateAdministrators']),
            closeDialogs() {
                this.$emit('closeDialogs')
            },
            enterNonStudentEditMode(user){
                this.editedNonStudentInfo.titleID = user.titleID
                this.editedNonStudentInfo.firstName = user.firstName
                this.editedNonStudentInfo.middleName = user.middleName
                this.editedNonStudentInfo.lastName = user.lastName
                this.editedNonStudentInfo.username = user.username
                this.editedNonStudentInfo.email = user.email
                this.editNonStudentModalEditMode = true
            },
            exitNonStudentModalEditMode(){
                this.editNonStudentModalEditMode = false
                this.editNonStudentModalRequiredAlert = false
                this.editedNonStudentInfo = {}
            },
            hasProductSubscription(subscriptions,prodID){
                let hasSub = false
                if (subscriptions)
                {
                    subscriptions.forEach(sub => {
                        if(sub.id){
                            let prodId = sub.productID || sub.productId
                            if(prodId==prodID){
                                hasSub = true
                            }
                        }
                    })
                }
                return hasSub
            },
            openDeleteUserDialog(){
                this.$emit('openDialog', 'deleteConfirmModal', {users: this.editedNonStudent})
            },
            openResetPasswordDialog(){
                this.$emit('openDialog', 'resetPasswordModal', {users: this.editedNonStudent})
            },
            openMultiSubModal(productID) {
                this.$emit('openDialog', 'multiSubAccessModal', {users: this.editedNonStudent, productID}, false)
            },
            setup(){
                this.editedNonStudent = this.user[0]
                this.exitNonStudentModalEditMode()
            },
            toggleProductAccess(user, productID){
                if(this.isProductAccessEditable){
                    this.$emit('toggleProductAccess', [user], productID)
                }
            },
            async updateNonStudentProfile(user,fieldsToChange){
                var valid = await this.$validator.validateAll()
                if(valid){
                    user.titleId = fieldsToChange.titleID
                    user.firstName = fieldsToChange.firstName
                    user.middleName = fieldsToChange.middleName
                    user.lastName = fieldsToChange.lastName
                    user.email = fieldsToChange.email
                    user.username = fieldsToChange.username
                    let users = [user]
                    let institutionId = this.institutionID
                    let tenantId = this.tenantID
                    let self = this;
                    (async function() {
                        if (user.roleID == 5) {
                            await self.updateAdministrators({institutionId, tenantId, users})
                        } else {
                            await self.updateTeachers({institutionId, tenantId, users})
                        }
                        
                        self.$emit('updateModel')
                        self.exitNonStudentModalEditMode()
                    })()
                } else {
                    let errorMsg = ''
                    this.$validator.errors.items.forEach(error=>{
                        errorMsg = errorMsg + error.msg + ' '
                    })
                    if(errorMsg){
                        this.$store.commit('setMessageError',errorMsg)
                    }
                }
            },
        },
        watch: {
            value(val) {
                if (val){
                    this.setup()
                }
            },
            user(){
                this.setup()
            }
        },
        created(){
            this.setup()
        }
	}
</script>

<style lang="less" scoped>
</style>