<template>
	<v-dialog :value="value" :max-width="size" :persistent="persistent" @input="closeDialogs">
        <v-card class="modal" :id="id">
            <v-card-title color="light-grey" class="headline">
                <v-content>
                    <v-flex v-if="headline">{{headline}}</v-flex>
                    <v-flex v-if="subHeadline" class="subheading font-weight-regular grey--text text--darken-1">{{subHeadline}}</v-flex>
                </v-content>
                <v-spacer></v-spacer>
                <v-btn flat icon @click="closeDialogs" :disabled="persistent"><v-icon>close</v-icon></v-btn>
            </v-card-title>
            <slot></slot>
        </v-card>
    </v-dialog>
</template>

<script>
	export default {
		name: 'UserManagmentModal',
        props: {
            value: Boolean,
            headline: String,
            subHeadline: String,
            id: String,
            persistent: {
                type: Boolean,
                default: false
            },
            size: {
                type: String,
                default: "500"
            },
            classes: {
                type: String,
                default: ''
            }
        },
        methods: {
            closeDialogs(){
                this.$emit('closeDialogs')
            }
        }
	}
</script>
