<template>
	<form @submit.prevent="getClassesAndStudents">
		<v-layout wrap>
			<v-flex xs12 sm10 offset-sm1 md8 offset-md2 class="border-free">
				<Headline :headline="$t('studentLogin.header')"></Headline>
			</v-flex>
			<v-flex xs12 sm10 offset-sm1 md8 offset-md2>
				<Messaging></Messaging>
			</v-flex>
			<v-flex xs12 sm10 offset-sm1 md8 offset-md2>
				<v-text-field
					v-model="username"
					:label="$t('form.teacherUsername')"
					v-validate="'required|max:50|noTags|noEscapesExceptAt'"
					browser-autocomplete="new-password"
					name="username"
					:error-messages="errors.collect('username')"
					color="white"
					dark
					autofocus
				>
				</v-text-field>
			</v-flex>
		</v-layout>
		<v-layout wrap>
			<v-flex>
				<v-btn color="primary" large round type="submit" :disabled="disableLogin">
					{{$t('navigation.go')}}
				</v-btn>
			</v-flex>
		</v-layout>
 	</form>
</template>

<script>
	import APIs from 'mixins/APIs'
  	import EnvironmentDetector from 'mixins/EnvironmentDetector'
  	import ErrorHandling from 'mixins/ErrorHandling'
  	import Headline from 'components/layout/Headline'
  	import Messaging from 'components/layout/Messaging'
	export default {
		name: 'StudentLogin',
		metaInfo : {
				title : 'Teacher Username'
			},
		components: {
			Headline,
			Messaging
		},
		mixins : [APIs, EnvironmentDetector, ErrorHandling],
		data () {
			return {
				username : ''
			}
		},
		computed : {
			disableLogin : function(){
				return this.$store.state.navigation.disableLogin
			}
		},
		methods : {
			getClassesAndStudents : async function(){
				var valid = await this.$validator.validateAll()
				if(valid){
					this.$http.post(this.getClassesAPI(),{username: this.username}).then(response => {
						//success
						this.$store.commit('setResponseData', response.data.AuthClassInfo)
						this.$router.push({ name: 'AppClass'})
					}, error => {
						//failure
						if (error.response.status == 400) {
							this.$store.commit('setMessageError', this.$t('error.error-12'))
						} else {
							this.$store.commit('setMessageError', this.$t('error.unknown'))
						}
					})
				}
			}
		}
	}
</script>
