export default {
	computed: {
		loggedIn(){
			return Boolean(this.$store.state.jti)
		},
		userJTI(){
			return this.$store.state.jti
		},
		jtiHeader(){
			return {headers: {'X-CSRFToken': this.$store.state.jti}}
		}
	},
}
