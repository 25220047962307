export default {
	computed: {
		isCertifiedTrainer(){
			return this.$store.getters["platformData/profile/isTrainer"]
		},
		isEmployee(){
			return this.$store.getters["platformData/profile/isEmployee"]
		},
		isEmployeeOrCertifiedTrainer(){
			return this.isEmployee || this.isCertifiedTrainer
		},
	},
}
