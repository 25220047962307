<template>
	<div>
		<v-layout wrap>
			<Headline :headline="$t('grantActivation.header')" :subHeadline="subHeadline"></Headline>
			<Messaging outline></Messaging>
			<v-flex xs12 v-if="preapproved">
				<v-btn color="primary" large block @click.native="modal=true">
					{{$t('grantActivation.readTerms')}}
				</v-btn>
				<v-dialog v-model="modal" persistent>
					<v-card>
						<v-card-title class="headline">
							{{$t('grant.mustAgree')}}:
						</v-card-title>
						<v-card-text>
							<GrantTerms :productID="getProductCode()"></GrantTerms>
						</v-card-text>
						<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="primary darken-1" flat @click.native="modal = false">{{$t('form.disagree')}}</v-btn>
						<v-btn color="primary darken-1" flat @click.native="preapproved = false">{{$t('form.agree')}}</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-flex>
			<v-flex v-else>
				<v-flex xs12>
					<v-btn color="primary" large block :disabled="!validCode" :to="{name: 'GrantCreated'}">
						{{$t('grantActivation.existingUser')}}
					</v-btn>
				</v-flex>
				<v-flex xs12>
					<v-btn color="primary" large block :disabled="!validCode" :to="{name: 'GrantAccountInfo'}" >
						{{$t('grantActivation.newUser')}}
					</v-btn>
				</v-flex>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
	import APIs from 'mixins/APIs'
	import GrantTerms from 'components/grant/GrantTerms'
	import Headline from 'components/layout/Headline'
	import Messaging from 'components/layout/Messaging'
	export default {
		name: 'GrantActivation',
		metaInfo : {
			title : 'Grant Code'
		},
		components: {
			GrantTerms,
			Headline,
			Messaging
		},
		mixins: [APIs],
		data () {
			return {
				grantName : '',
				school : '',
				validCode : false,
				preapproved : false,
				modal: false
			}
		},
		computed : {
			subHeadline(){
				if(this.grantName.toUpperCase() && this.school){
					return this.grantName.toUpperCase()+' / '+this.school
				} else {
					return null
				}
			}
		},
		created(){
			let grantCode = this.$route.params.grantCode
			this.$http.get(this.getGrantActivationCodeValidatorAIP(grantCode)).then(response => {
				let grantInfo = response.data.GrantApplication
				this.$store.commit('setCountry', grantInfo.countryCode)
				this.$store.commit('setEmail', grantInfo.email)
				this.$store.commit('setFirstName',grantInfo.firstName)
				this.$store.commit('setLastName',grantInfo.lastName)
				this.$store.commit('setPID', grantInfo.pid)
				this.$store.commit('setGrantRegCode', grantInfo.regCode)
				this.grantName = grantInfo.firstName+' '+grantInfo.lastName
				this.school = grantInfo.institutionName
				if (grantInfo.preapproved){
					this.preapproved = true
				}
				this.validCode = true
			}, error=> {
				let errorMessage = error.response.data.MessageResponse.message
				if(error.response.status===400){
					errorMessage = this.$t('error.invalidGrantCode')
				}
				this.$store.commit('setMessageError',errorMessage)
			})
		}
	}
</script>

<style lang="less" scoped>
	button {
		margin-bottom: 25px;
	}
</style>
