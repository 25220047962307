<template>
	<div>
	  <form v-if="incomplete" @submit.prevent="requestRecoveryEmail">
	  	<v-layout wrap>
	    	<Headline :headline="$t('forgotPassword.header')" flowOverride="none"></Headline>
				<v-flex xs12>
					<Messaging outline></Messaging>
				</v-flex>
				<v-flex xs12>
					<v-text-field
						v-model="email"
						:label="$t('form.email')"
						browser-autocomplete="new-password"
						v-validate="'required|email|max:100'"
						name="email"
						:error-messages="errors.collect('email')"
						ref="emailInput">
					</v-text-field>
				</v-flex>
				<v-flex xs6>
	    		<v-btn @click="$router.go(-1)" large color="grey lighten-2">
	      			{{$t('navigation.back')}}
	    		</v-btn>
	    	</v-flex>
	    	<v-flex class="text-xs-right">
		      <v-btn color="primary" large raised type="submit">
		        {{$t('form.submit')}}
		    	</v-btn>
		    </v-flex>
	  	</v-layout>
	  </form>
		<EmailSent v-else></EmailSent>
	</div>
</template>

<script>
	import APIs from 'mixins/APIs'
	import EmailSent from 'components/email/EmailSent'
	import Headline from 'components/layout/Headline'
	import Messaging from 'components/layout/Messaging'
	import { getUrls } from '@explorelearning/urls';
	export default {
		name: 'PasswordRecovery',
		metaInfo : {
			title : 'Email'
		},
		components: {
			EmailSent,
			Headline,
			Messaging
		},
		mixins : [APIs],
		data () {
			return {
				email : '',
				incomplete: true
			}
		},
		computed : {
			legacyBaseURL(){
                return getUrls().gizmosLegacy
            },
			isTouchDevice() {
                return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)
            }
		},
		mounted() {
            if(!this.isTouchDevice) {
				this.$refs.emailInput.focus();
            }            
        },
		methods : {
			requestRecoveryEmail : async function(){
				var valid = await this.$validator.validateAll()
				if(valid){
					let legacyURL = this.legacyBaseURL + '?method=cUser.actEmailLoginPlatform'
					this.$http.post(legacyURL, {"emailAddr": this.email.trim()}).then(response => {
						//success callback
						this.incomplete = false
					}, error => {
						//error callback
					})
					this.$http.post(this.getRecoveryAPI(), {"email": this.email.trim(), "lang": this.$locale.current()}).then(response => {
						//success callback
						this.incomplete = false
					}, error => {
						//error callback
						let response = error.response
						if (response.status == 400) {
							this.$store.commit('setMessageError', this.$t('error.invalidEmail'))
						} else if (response.status == 404 || response.status == 0) {
							this.$store.commit('setMessageError', this.$t('error.404'))
						} else {
							this.$store.commit('setMessageError', this.$t('error.invalidEmail'))
						}
					})
				}
			}
		}
	}
</script>
