<template>
    <v-layout class="login-instructions mx-1" :class="productClass" wrap>
        <v-flex v-html="$t(message, {product:productsDisplayNames})"></v-flex>
    </v-layout>
</template>

<script>
    import APIs from 'mixins/APIs'
    import EnvironmentDetector from 'mixins/EnvironmentDetector'
    import ProductInfo from 'mixins/ProductInfo'
    export default {
        name: 'LoginInstructions',
        mixins: [APIs, EnvironmentDetector, ProductInfo],
        props: {
            message : {
                required: true,
                type: String
            },
            product : {
                type: String
            }
        },
        computed : {
            otherProducts(){
                return this.removeItem(this.products, this.product)
            },
            productClass(){
                if(this.currentEnvironment == 'el'){
                    return ''
                } else {
                    return 'explorelearning-instructions'
                }
            },
            productsDisplayNames(){
                let displayNames = ''
                this.otherProducts.forEach(product=>{
                    displayNames += '<span class="font-weight-black">'+this.productDisplayName(this.getProductCode(product))+'</span>, '
                })
                displayNames = displayNames.substring(0,displayNames.length-2)
                if(this.otherProducts.length>1){
                    let index = displayNames.lastIndexOf(', ')
                    displayNames = displayNames.substring(0,index)+' or '+displayNames.substring(index+1)
                }
                return displayNames
            },
            products(){
                let environments = []
                this.availableProducts.forEach(product => {
                    environments.push(product.environment)
                })
                return environments
            }
        },
        methods : {
            removeItem(array,value){
                if(array.includes(value)){
                    let index = array.indexOf(value)
                    array.splice(index,1)
                }
                return array
            }
        }
    }
</script>

<style scoped lang="less">
    .login-instructions {
        min-height: 54px;
        width: 100%;
        border-radius: 4px;
        border: solid 1px #F6821F;
        background-color: #fff;
        .flex {
            padding: 4px 8px !important;
            font-weight: 600;
            color: #F6821F;
        }
    }
    .explorelearning-instructions {
        .flex{
            background-image: url('/src/assets/explorelearning/img/logo-leaf.svg');
            background-size: 40px 40px;
            background-position: 6px 6px;
            padding-left: 60px !important;
        }
    }
</style>
