export default {
	data(){
		return {
			registrationTypeIdLookup : {
				1  : 'reg', 		// Trial Registration
				2  : 'reg', 		// Grant Registration
				3  : 'reg', 		// Admin Invite (Invite System) [formerly Online Purchase Registration]
				4  : 'reg', 		// Teacher Registration (CSA) [formerly Order Fulfillment (Unused)]
				5  : 'reg', 		// Teacher Registration (RegCode System)
				6  : 'reg', 		// Parent Registration
				7  : 'passReset',	// Password Reset
				8  : 'reg', 		// Admin Registration (RegCode System)
				9  : 'reg', 		// Admin Registration (CSA) [formerly Admin Registration (Unused)]
				10 : 'grant', 		// Apply for Grant [soon to be SSO Freemium]
				11 : 'email', 		// Email Validation
				12 : 'reg', 		// Freemium Registration [formerly Web Registration]
				13 : 'grant', 		// Pre-Approved Grant
				14 : 'reg',			// SSO Trial Registration
				15 : 'reg'			// Teacher Invite (Invite System)
			}
		}
	},
	methods: {
		validateRegCodeType(ID,flow){
			return this.registrationTypeIdLookup[ID] == flow
		}
	}
}
