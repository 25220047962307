<template>
	<v-flex class="text-xs-center">
		<v-icon class="splash-icon">email</v-icon>
		<Headline v-if="validated=='success'" :headline="$t('validation.header')" :subHeadline="$t('validation.subHeader')" multipleLines></Headline>
		<Headline v-else-if="validated=='fail'" :headline="$t('validation.failHeader')" :subHeadline="$t('validation.failSubHeader')" multipleLines></Headline>
	</v-flex>
</template>

<script>
	import APIs from 'mixins/APIs'
	import Headline from 'components/layout/Headline'
	export default {
		name: 'TrialEmail',
		components: {
			Headline,
		},
		mixins: [APIs],
		data () {
			return {
				validated: ''
			}
		},
		created(){
			let regCode = this.$route.params.regCode
			this.$http.post(this.getEmailValidationAPI(regCode), {}).then(response => {
				this.validated = 'success'
			}, error => {
				this.validated = 'fail'
			})
		}
	}
</script>
