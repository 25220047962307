export default {
	// Common
	form : {
		apply : 'Apply',
		userName : 'Username',
		teacherUsername : 'Teacher Username',
		password : 'Password',
		passwordConfirm : 'Confirm Password',
		passwordNew : 'New Password',
		passwordUpdate : 'Update Password',
		forgotPassword : 'Forgot your username or password?',
		email : 'Email Address',
		emails : 'Email Address(es)',
		emailConfirm : 'Confirm Email',
		title : 'Title',
		titles : {
			mr : 'Mr.',
			ms : 'Ms.',
			mrs : 'Mrs.',
			miss : "Miss",
			dr : "Dr."
		},
		firstName : 'First Name',
		lastName : 'Last Name',
		middleName : 'Middle Name',
		middleInitial : 'Middle Initial',
		phoneNum : 'Phone Number',
		ext : 'Phone Ext.',
		mobileNum : 'Cell Phone Number',
		submit : 'Submit',
		zip : 'ZIP/Postal Code',
		institution : 'Institution',
		district : 'District/School System',
		regCode : 'Registration Code',
		studentID : 'Student ID',
		requiredFields : '* indicates required field',
		cardNumber : 'Credit Card Number',
		CCV : 'CCV',
		expMonth : 'Exp. Month',
		expYear : 'Exp. Year',
		cardType : 'Card Type',
		address1 : 'Street Address',
		address2 : 'Street Address (Line 2)',
		city : 'City',
		onCC : 'As it appears on credit card',
		purchase : 'Purchase',
		promoCode : 'Promo Code',
		institutionName : 'Institution Name',
		numStudents : 'Number of Students',
		agree : 'Agree',
		disagree : 'Disagree',
		displayName : 'Display name',
		profileMessage : 'Profile Message'
	},
	navigation : {
		back : 'Back',
		go : 'Go',
		login : 'Login',
		next : 'Next',
		submit : 'Submit',
		update : 'Update',
		close : 'Close',
		cart : 'View Cart',
		review : 'Review Order',
		reporting : '{product} Reporting',
		goto : 'Go To {product}',
		done : 'Done'
	},
	error : {
		404 : 'Unable to connect to server.',
		error : 'An error has occurred; please try again. If the problem persists, please <router-link :to="{name: \'Support\', query:{type:\'technical\'}}">contact customer support</router-link>.',
		// Old Error Message IDs
		'error-1' : 'Error: The provided email address is already tied to another account.',
		'error-2' : 'This username already exists; please try again with a different username.',
		'error-3' : 'Error: Invalid email address.',
		'error-4' : 'Error: Invalid registration code.',
		'error-5' : 'Your account activation link has been used. If you’ve previously activated your account but are unable to login, please <router-link :to="{name: \'ForgotPassword\'}">reset your password</router-link>. If additional assistance is needed, please <router-link :to="{name: \'Support\', query:{type:\'technical\'}}">contact customer support</router-link>.',
		'error-6' : 'Error: Invalid email address',
		'error-7' : 'Error: Invalid or expired registration code.',
		'error-8' : 'Error: The provided email address does not match an expected value.',
		'error-9' : 'Error: Expired registration code.',
		'error-10' : 'Error: Administrator codes can only be applied to Administrator accounts.',
		'error-11' : 'Error: Teacher codes can only be applied to Teacher accounts.',
		'error-12' : 'Error: Your invite was not found; please verify and try again.',
		'error-13' : 'Account Disabled',
		'error-14' : 'Invalid Username or Password',
		'error-16' : 'Insufficient number of Student seats in your subscription: Either revoke access for other students or ask your Administrator to increase the number of student seats.',
		'error-17' : 'Access to this product is denied; it would exceed your subscription\'s limits.',
		'error-21' : 'You do not have permissions for this service.',
		'error-22' : 'You do not have a subscription to this service.',
		'error-23' : 'Your subscription has expired.',
		'error-25' : "Your login is not authorized using this method. You must use your School/District's Learning Management Portal/Login system.",
		// New Error Message IDs
		'unauthorized' : 'Unauthorized: Your account does not have permission to take this action.',
		// = General Exceptions
		'error-0' : '@:error.error',
		// 'error-1' : '@:error.error',
		// 'error-2' : '@:error.error',
		// 'error-3' : '@:error.error',
		// = Cant Proceed fo Generic Reason
		'error-50' : '@:error.unauthorized',
		'error-51' : '@:error.unauthorized',
		'error-52' : '@:error.unauthorized',
		// = Unable to Proceed for Known Causes
		'error-1050' : "Access to this product is denied; it would exceed your subscription's limits.",
		'error-1051' : 'Insufficient number of Student seats in your subscription: Either revoke access for other students or ask your Administrator to increase the number of student seats.',
		'error-1052' : 'Your account is not eligible to take a trial with this product.',
		'error-1053' : 'Free access not available with this product.',
		'error-1054' : "Your account cannot be authenticated; please try again.",
		'error-1055' : '@:error.unauthorized',
		'error-1056' : 'Too many invalid login attempts recently; you will be unable to log into this account for the next 5 minutes.',
		'error-1451' : '@:error.unauthorized',
		// = SQL Server Issues
		'error-1100' : '@:error.error',
		'error-1102' : '@:error.error',
		'error-1103' : '@:error.error',
		'error-1104' : 'This username already exists; please try again with a different username.',
		'error-1105' : 'Error: The SSO/Rostering ID submitted already exists.',
		'error-1106' : 'Error: The student ID submitted already exists.',
		'error-1107' : '@:error.error',
		// = Reg Code Errors
		'error-1200' : 'Error: The provided email address is already tied to another account.',
		'error-1201' : 'Error: Invalid email address.',
		'error-1202' : 'Error: Invalid registration code.',
		'error-1203' : 'Your account activation link has been already used. If you’ve previously activated your account but are unable to login, please <router-link :to="{name: \'ForgotPassword\'}">reset your password</router-link>. If additional assistance is needed, please <router-link :to="{name: \'Support\', query:{type:\'technical\'}}">contact customer support</router-link>.',
		'error-1204' : 'Error: Invalid email address',
		'error-1205' : 'Error: Invalid or expired registration code.',
		'error-1206' : 'Error: The provided email address does not match an expected value.',
		'error-1207' : 'Error: Expired registration code.',
		'error-1208' : 'Error: Your invite was not found; please verify and try again.',
		'error-1209' : 'Error: Administrator codes can only be applied to Administrator accounts.',
		'error-1210' : 'Error: Teacher codes can only be applied to Teacher accounts.',
		'error-1211' : 'We were unable to create your account; please try again. If the error persists, please <router-link :to="{name: \'Support\', query:{type:\'technical\'}}">contact customer support</router-link>.',
		// = Class Code Errors
		'error-1250' : 'Your account cannot be added to this class. Logout and try again with a different account or create a new account.',
		'error-1251' : 'Your account was created and added to the class but was not granted permission to access Gizmos. Please alert your teacher.',
		'error-1252' : 'Your class code cannot be redeemed; please try again. If the error persists, please alert your teacher.',
		'error-1253' : 'You are not allowed to join this class.',
		// = Invalid Input
		'error-1300' : 'Error: The provided data is not formatted correctly.',
		'error-1301' : 'Error: Invalid email address.',
		'error-1302' : 'You have used this password before. Please choose a new password.',
		// = Disabled Features
		'error-1400' : 'Error: Unable to update subscription.',
		'error-1401' : 'Error: The online catalog is not currently available. ',
		// = Matching SQL Server Errors
		'error-50001' : 'The class you are attempting to join is no longer able to redeem class codes.',
		'error-50002' : 'The class you are attempting to join is no longer able to redeem class codes.',
		'error-50003' : 'Your class code cannot be redeemed at this time; your teacher has paused class code enrollment.',
		'error-50004' : 'Your class code cannot be redeemed; this class has been deleted.',
		'error-50005' : 'Your class code cannot be redeemed; this class has been deleted.',
		'error-50006' : 'The class you are attempting to join is no longer able to redeem class codes.',
		accountAlreadyExists : 'There is already a subscription/trial associated with this email address. Please <router-link :to="{name:\'Subscriptions\'}">sign in</router-link> to check your account\'s status.',
		unknown : 'Unknown Error',
		ineligibleAccount : 'This account is not eligible for this feature.',
		invalidCode : 'Invalid Reset Code',
		invalidEmail : 'Cannot reset this account. Please contact Customer Service.',
		invalidRegCode : 'Your account activation link is not able to be used. If you’ve previously activated your account but are unable to login, please <router-link :to="{name: \'ForgotPassword\'}">reset your password</router-link>. If additional assistance is needed please <router-link :to="{name: \'Support\', query:{type:\'technical\'}}">contact Support</router-link>.',
		invalidGrantCode : 'Your account activation link is not able to be used. If you’ve previously activated your account but are unable to login, please <router-link :to="{name: \'ForgotPassword\'}">reset your password</router-link>. If additional assistance is needed please <router-link :to="{name: \'Support\', query:{type:\'technical\'}}">contact Support</router-link>.',
		invalidTrialCode : 'Invalid or expired trial code. You may have already used this code.',
		invalidPassword : 'Invalid Password',
		invalidPromoCode : 'Invalid Promo Code',
		malformedEmail : 'Invalid Email Address',
		notAdmin : 'You do not have the permissions needed for the page you attempted to reach.',
		studentsNotEligible : "Student accounts are not eligible.",
    	unmatchingPasswords : 'Passwords Do Not Match',
    	unmatchingEmails : 'Emails Do Not Match',
    	usernameUnavailable : 'Username is unavailable',
		contactSupport : 'Something went wrong on our end... Please try again, and if you are still unable to submit your message, call us at 866-882-4141',
		cookieWarning : 'This website requires cookies to be enabled in your browser.',
		renewSub : 'Please click the cart below to renew.',
		expiredCard : 'Your credit card has expired.',
		illegalChars : 'You have entered an invalid character.',
		idInUse :  'A student with this student ID already exists. You can add them from the school roster.',
		usernameInUse : 'A student with this username already exists.',
		idInClass : 'A student with this student ID already exists in your class: {name}.',
		idInRoster : 'A student with this student ID already exists in your school roster: {name}.',
		idInTenant : `A student with this student ID already exists within your school's district: {name}.`,
		studentIsRosteredTransfer : '{name} already has an account within a rostered school, transferring this student is not allowed.',
		adminIdInTenant : 'A student with this student ID already exists within your school district: {name}',
		adminTransferAlert : '{student} already has an account at "{school}". Continuing will transfer that student to your school. Would you like to complete this transfer?',
		transferAlert : '{student} already has an account at "{school}". Continuing will transfer that student to your school and add them to your class. Would you like to complete this transfer?',
		addToClassAlert : '{student} is already in your school roster. Continuing will add that student to your class. Would you like to add this student to your class?',
		import : 'There was an issue trying to import your file. Make sure you are connected to the internet and the file is formatted correctly.',
		unableToLink : 'We are, at this time, unable to link your account to this subscription.',
		existingSub : 'You already have either a subscription or have had a previous free trial to this product.',
		fieldLength : "{fieldName} field can not exceed {charLimit} characters.",
		concurrentLogins : "This account has just been logged into on another computer, triggering a logout of this session.",
		unableToCreateAndEnroll : "There was an error creating your account.",
		snackbarError : 'An error has occurred. Please try again.',
		studentCredsInNonStudentForm : 'Invalid permissions. If you are a student, select the Student tab above and submit your username.',
		nonStudentCredsInStudentForm : 'Invalid permissions. If you are an educator, select the Educator tab above and submit your username and password.'
	},
	success : {
		emailSentRecovery : 'Check your email for further information about recovering/resetting your password.',
		emailSentTrial : 'Check your email for further information about starting your free trial.',
		passwordReset : 'Password successfully reset.',
		passwordUpdate : 'Password Updated',
		profileUpdate : 'Profile Updated',
		usernameAvailable : 'Username is available',
		contact : 'Thank you for contacting us! An ExploreLearning representative will be in contact with you shortly.',
		welcome : 'Your ExploreLearning account is fully set up and ready to use!',
	},
	help : {
		helpCenter : 'Help Center',
		contact : 'Contact Customer Support',
		PDOD : 'On-Demand PD',
		teacherGuide : 'Teacher Guide',
		intercomChat : "For faster service, click on the circle in the bottom corner of the screen to chat with support agents."
	},
	flow : {
		accessibility: 'Accessibility Statement',
		'admin-contact' : 'Admin Contact Update',
		app : 'Student App Login',
		cart : 'Shopping Cart',
		catalog: 'Online Catalog',
		class : 'Class Management',
		conference : 'Conferences & Events',
		demo : 'Request a Demo',
		dontsell : 'Do Not Sell My Information',
		email : 'Email Validation',
		free : 'Free Access',
		grant : 'Grant Application',
		'grant-act' : 'Grant Activation',
		home : 'Home',
		login : 'Login',
		parent : 'Parent Registration',
		passExp : 'Expired Password',
		passReset : 'Password Recovery',
		'pre-approved' : 'Pre-Approved Grant',
		privacy : 'Privacy Policy',
		profile: 'My Account',
		quote : 'Contact a Sales Representative',
		reg : 'Account Registration',
		support : 'Contact Support',
		student : 'Student Login',
		terms : 'Terms & Conditions of Use',
		trial : 'Trial Registration',
		user : 'User Management',
		join : 'Join Class',
	},
	month : {
		jan : 'January',
		feb : 'February',
		mar : 'March',
		apr : 'April',
		may : 'May',
		jun : 'June',
		jul : 'July',
		aug : 'August',
		sep : 'September',
		oct : 'October',
		nov : 'November',
		dec : 'December',
	},
	warning : {
		// This needs to be here even if it is empty
		expiredSub : 'One or more of your subscriptions has expired.',
		noActiveSub : 'You do not have an active {product} subscription. View and manage your subscriptions from your ',
		usernameConflict : 'Welcome to the new ExploreLearning experience! Unfortunately, an issue with your username forced us to modify it. Please take this opportunity to set a new, easily remembered username.',
		ssoExpiration : 'You have been redirected to this page because your account no longer supports single sign-on (SSO). Log in below to access your account.'
	},
	info : {
		// This needs to be here even if it is empty
		demo : 'Schedule a FREE 30-minute live demo with a product specialist. Learn about options to evaluate and pilot at your school.',
		notCounted : 'Note that this class does not count towards your class limit.',
		notOwner : 'Only the class owner can update class settings.',
		regLogin : 'Log in to your existing account to link your new subscription.',
		expiredPassword : 'Your password has expired. Please set a new password.'
	},
	header: {
		home : 'Home',
		classes : 'My Classes',
		account : 'My Profile',
		logout : 'Logout',
		teacher : 'Teacher',
		admin : 'Administrator',
		parent : 'Parent',
		student : 'Student',
		employee : 'Employee'
	},
	// Component Specific
	footer: {
		cambium : 'ExploreLearning<sup>®</sup> is a <u>Cambium Learning</u><sup>®</sup> Company.',
		pre : 'ExploreLearning. All rights reserved. Frax, Gizmo, Reflex, and Science4Us are registered trademarks of ExploreLearning. Please carefully review the',
		terms : 'Terms & Conditions of Use',
		mid : 'and our',
		privacy : 'Privacy Policy',
		accessibility: 'Accessibility Statement',
		post : 'before using this site. Your use of the site indicates your agreement to be bound by the Terms & Conditions of Use.'
	},
	loginChoice : {
		header : 'Login as:',
		students : 'Student',
		educators : 'Educator or Parent',
		trial : 'Start a Free Trial'
	},
	teacherLogin : {
		header : 'Educator Login',
		headerParent : 'Parent Login',
		headerEmployee : 'Employee Login',
		headerUniversal : 'Login',
		back : 'Student Login',
		CSA : 'CSA',
		createAccount : 'Create an account',
		otherProduct : 'Please log in using your {product} username and password.'
	},
	forgotPassword : {
		header : 'Reset Password',
	},
	expiredPassword : {
		header : 'Expired Password'
	},
	emailSent : {
		header : 'Almost there...',
		subHeader : 'Please check your email address for additional instructions.'
	},
	chooseAccount : {
		header : 'Password Reset',
		instruction : 'Select Account'
	},
	resetPassword: {
		back : 'Wrong Account'
	},
	studentLogin : {
		header : 'Student Login',
		back : 'Educator Login',
		sorry : "Sorry, we can't log you into {product}.",
		instructions : 'Please ask your teacher to set your {product} {assignmentType}.'
	},
	chooseClass : {
		header : 'Select Your Class',
		subHeader : 'Teacher:',
		back : 'Wrong Teacher?'
	},
	chooseStudent : {
		header : 'Select Your Name',
		subHeader : 'Class:',
		back : 'Wrong Class?'
	},
	studentPassword : {
		header : 'Enter Password',
		subHeader : 'Student:',
		back : 'Not You?',
		loggedIn : 'Logged In',
		launch : 'Launch'
	},
	email: {
		header : 'Trial Signup',
		subHeader : 'Provide Your Email To Get Started',
		success : 'Check your email to continue signing up for a free trial.',
		footer : 'Already have an account?',
		signIn : 'Sign in',
		ageCheck : 'I am 18 years of age or older'
	},
	trial : {
		header : 'Free Trial',
		existingHeader : 'New Product Setup',
		step1 : 'User Information',
		step2 : 'School Information',
		step3 : 'Success',
		hold : 'Please wait while we get everything squared away. You will be automatically redirected. No need to refresh this page.'
	},
	free : {
		header : 'Free Access Signup',
	},
	accountInfo : {
		header : 'User Information',
		contactDetails : 'Contact Details',
		for : 'for',
		createLogin : 'Create Your Login',
		havePromo : 'Have a promo code?',
		emailCheck : 'If your email address is not <b>{email}</b> then you have been given the wrong link/code. Do not continue filling out this form. Contact your  administrator to receive your correct link/code.',
	},
	schoolInfo : {
		role : 'Making Contact on Behalf Of',
		roleTeacher: 'An Individual Educator or School',
		roleDistrict: 'A District or School System',
		header : 'School Information',
		districtHeader : 'District Information',
		accountType : 'Account Type',
		choose : 'Choose Home or Educator',
		teacherName : 'Educator Account',
		teacherDescription : 'For classroom teachers and school employees.',
		homeName : 'Home Use Account',
		homeDescription : 'For parents of homeschoolers, tutors, and others.',
		schoolDetails : 'School Details',
		districtDetails : 'District Details',
		location : 'Your Location'
	},
	chooseSchool : {
		switchToLocation : 'Find School By Country / State',
		switchToZip : 'Find School By Zip',
		institutionTypes : 'Institution Type',
		schoolNotListed : 'School Not Listed',
		international : "Find School By Country",
		districtEmployee : "I'm a district employee"
	},
	chooseLocation : {
		country : 'Country',
		selectCountry : 'Choose Your Country',
		state : 'State / Province',
		selectState : 'Choose Your State'
	},
	additionalInfo : {
		aboutYou : 'A Little More About You',
		gradeLevels : 'Grades you teach',
		gradesYouWorkWith : 'Grades you work with',
		titles : 'Your roles',
		yourRole : 'Your role',
		gradeLevel : {
			prek : 'Pre-Kindergarten',
			k : 'Kindergarten',
			grade1 : 'Grade 1',
			grade2 : 'Grade 2',
			grade3 : 'Grade 3',
			grade4 : 'Grade 4',
			grade5 : 'Grade 5',
			grade6 : 'Grade 6',
			grade7 : 'Grade 7',
			grade8 : 'Grade 8',
			grade9 : 'Grade 9',
			grade10 : 'Grade 10',
			grade11 : 'Grade 11',
			grade12 : 'Grade 12',
			college : 'College/University',
			k2 : 'K-2',
			k5 : 'K-5',
			k8 : 'K-8',
			k12 : 'K-12',
			sixEight : '6-8',
			sixTweleve : '6-12',
			nineTweleve : '9-12'
		},
		role : {
			teacher : 'Teacher',
			resourceTeacher : 'Resource Teacher',
			intervention : 'Intervention Specialist',
			mathChair : 'Math Department Chair',
			sciChair : 'Science Department Chair',
			mathCoach : 'Math Coach',
			sciCoach : 'Science Coach',
			principal : 'Principal',
			curriculum : 'Curriculum Coordinator',
			technology : 'Technology Coordinator',
			parent : 'Parent',
			tutor : 'Tutor',
			other : 'Other Educator',
			super : 'Superintendent',
			asstSuper : 'Assistant Superintendent',
			curriculumDirector : 'Curriculum Director',
			fedProgramDirector : 'Federal Programs Director',
			finDirector : 'Finance/Purchasing Director',
			mathDirector : 'Math Director',
			scienceDirector : 'Science Director',
			respIntDirector : 'Response to Intervention Director',
			spEdDirector : 'Special Education Director',
			stemCoord : 'STEM Coordinator',
			techCoord : 'Technology Coordinator',
			title1Coord : 'Title I Coordinator',
			studentTeacher : 'Student Teacher',
			otherAdmin : 'Other Administrator'
		}
	},
	trialConfirm : {
		header : 'Account Created',
		instructions : 'Your account is ready to use. Continue to manage classes, enroll students, and view reports.',
		btn : 'Continue'
	},
	regCode : {
		header : 'Account Setup',
		headerSSO : 'Welcome SSO Teacher',
		info : 'If you already use {product}, then getting started is a snap! You can use your existing account.',
		linkTo : 'Use {username}',
		linkDifferent : 'Wrong Account?',
		makeAccount : 'Create an Account',
		haveAccount : 'Log In to My Account',
		chooseSchool : 'Your account is associated with more than one institution. Please select the institution you would like to connect to your free trial.',
		trialRegistered : 'Success! Your free trial is all set up.',
		adminTrial : 'We are not currently offering trials/free access to administrator accounts. To take a trial you will need to create a new teacher account. This new account will not be tied to your existing account or subscription.',
		otherAccount : 'We are not currently offering trials/free access to administrator accounts. Our records indicate that a teacher account is also tied to your email. Click the button below to log in to that account and finish setting up your free trial.',
		teacherTrialBtn : 'Create a new Teacher Account',
		loginTeacher : 'Log in to Teacher Account',
	},
	classCode : {
		headline : 'Join a Class',
		classCode : 'Class Code',
		classCodes : 'Class Codes',
		classCodeHint : 'Please enter the Class Code provided by your teacher.',
		classCodeTooltip : 'A Class Code allows you to quickly join<br/>a class and create your student account.',
		pauseClassCode : 'Pause Class Code Enrollment',
		invalidCode : 'Invalid Class Code',
		instructionsStep0 : 'Enter your Class Code',
		aboutToJoin: "You are about to join {teacherName}'s <b>{className}</b> class.",
		instructionsStep1 : 'Create or log in to your Gizmos account',
		loginInstructions : "Log in to another class to join <b>{teacherName}'s {className} class</b>.",
		join: 'Join',
		instructionsStep2 : 'Please enter your information',
		unableToAdd : 'Something went wrong and we are unable to add you to the class.',
		instructionsStep3: 'Enter your student username and password',
		success: 'You have joined <b>{name}</b>',
		successLogin: 'For future logins, go to <a href="{loginURL}">{loginURL}</a>.',
		successCard: 'Your login card displays your username and password. Please take care to keep it private.',
		goToClass: 'Continue',
		downloadLoginCard: 'Download Login Card',
		schoolIsEnabled: 'This school is class code enabled. Learn more >',
		footer: "Can't remember your username or password?",
		ccStudentsAreHidden: 'Gizmos students are managed by teachers. Learn more >',
		loginWithTeacherUsername : "You entered a teacher username. From now on you will log in to Gizmos with your own username and password. If you don't know your credentials create a new account.",
		invalidCreds: "Invalid username or password.",
		wrongClass: "Wrong class?",
		tryAgain: "Try another Class Code",
		handout: 'Student Enrollment Handout',
		explainationHeader: 'Class Code enrollment enabled.',
		explainationSubheader: 'Teachers manage student accounts at the class level via class codes that allow students to self-enroll in a Gizmos class. Therefore, no school-level roster management is required.',
		teacherExplainationSubheader: 'Teachers manage student accounts at the class level via class codes that allow students to self-enroll in a Gizmos class.',
	},
	profile : {
		header : 'My Account',
		profileTab : 'Profile',
		passwordTab : 'Security',
		subscriptionTab : 'Subscriptions',
		parent : 'Parent Subscription',
		historyTab : 'Purchases',
		license : 'License',
		students : 'students in use',
		renew : 'Renew',
		addSeats : 'Add Students',
		noSubs : 'You do not currently have any subscriptions.',
		viewCart : 'View Shopping Cart',
		fieldSeats : 'Number of students',
		fieldAdditionalSeats : 'Number of additional students',
		fieldYears : 'Number of years',
		fieldAdditionalYears : 'Number of additional years',
		contactSupport : 'Need to make changes?',
		contactSales : 'Contact Sales',
		purchaseMsg : 'Get the best deal on ExploreLearning products! Complete this quick form for a personalized quote!',
		contactAdmin : "Contact your institution’s Reflex Administrator for help with making changes to this subscription.",
		invoiceName : 'ExploreLearning Order Invoice',
		invoiceHeader : 'Order Details for Order',
		printMsg : 'Please print this page for your records.',
		payee : 'Payee',
		orderNumber : 'ExploreLearning Order Number',
		orderDate : 'Order Placed',
		orderTotal : 'Order Total',
		orderItems : 'Items Ordered',
		orderItem : 'Order Item',
		for : 'for',
		till : 'till',
		perPer : 'Per Student Per Year',
		payment : 'Payment Method',
		invoiceFooter : 'ExploreLearning. All rights reserved.',
		noPurchases : 'You have not made any online purchases.',
		purchaseExceptionPre : 'Online purchases prior to August 2018 are not shown. Please',
		purchaseExceptionLink : 'contact customer support',
		purchaseExceptionPost : 'for information about past purchases.',
		displayNameBtn : 'Set a Display Name',
		displayNameTip : 'what your students call you',
		profileMsgBtn : 'Set a Profile Message',
		profileMsgTip : 'Personalized profile message',
		gizmoOptions : 'Gizmo Profile Options',
		personalInfo : 'Personal Information'
	},
	products : {
		header : 'Select a Product',
		noNativeProduct : 'You do not have an active {product} subscription.'
	},
	parent : {
		header : 'Parent Account Signup'
	},
	cart : {
		students : 'Students',
		years : 'Year(s)',
		cost : 'Cost',
		pending : 'Pending',
		expired : 'Expired',
	},
	address : {
		header : 'Billing Address',
		check : 'My billing address and shipping address are the same.',
		shipping : 'Shipping Address'
	},
	confirm : {
		tax : 'Tax',
		total : 'Total',
		coppaAgree : 'I give permission for my student(s) to use ExploreLearning\'s interactive online learning tools as detailed in the Student and Data Privacy Policy below.',
	},
	support : {
		header : 'How can we help you?',
		inquiryType : 'Inquiry Type',
		technical : 'Technical Issue or Question',
		feedback : 'Product Feedback',
		sales : 'Purchasing Question',
		comment : 'General Comment or Question',
		billing : 'Billing Question',
		product : 'Product You Are Contacting Us About',
		subject : 'Subject',
		msg : 'Message',
		msgPlaceholder : 'your comment, question, or feedback',
		thisComputer : 'Issue occurred on this computer',
		quote : 'Looking for a quote?',
		success : 'Your request has been submitted. You will receive an email shortly with your support case information. If you do not receive an email within 15 minutes, please check your spam folder.'
	},
	quote : {
		header : 'Contact Information',
		contactTime : 'Best Time to Contact',
		numTeachers : 'Number of Teachers',
		quoteDetails : 'Quote Details',
		numSchools : 'Number of Schools',
		purchaseType : 'Purchase Type',
		educator : 'Individual Educator',
		school : 'School',
		district : 'District',
		other : 'Other',
		comments : 'Your Comments',
	},
	demo : {
		header : 'Contact Information',
		msg : 'Tell Us More',
		msgPlaceholder : 'Let us know how we can help. Please include any questions, details needed, or let us know if you’d like to schedule a demo.',
		msgPlaceholderDemo : 'Please include any questions or product details you would like to cover, as well as specific dates/times you are available for a demo.',
		submit : 'Request Demo'
	},
	conference : {
		header : 'Conferences & Events',
		success : 'Thank you for your interest in ExploreLearning! We will be in touch with more information.'
	},
	contactSuccess : {
		header : 'Success'
	},
	validation : {
		header : 'Thank you!',
		subHeader : 'Your email address has been validated.',
		failHeader : 'Oh no!',
		failSubHeader : 'Something has gone wrong. Try again.',
	},
	grant : {
		header : 'Grant Application',
		headerInternational : 'International Grant Application',
		inquiry : 'Grant Inquiry',
		contact : 'Contact Information',
		closedWarning : 'Please share your contact and school information with us so we can notify you as soon as the next {product} Grant application period opens.',
		principalName : 'Principal\'s Name',
		principalEmail : 'Principal\'s Email Address',
		referrer : 'How did you hear about the grant?',
		referrerOptions : 'Colleague|Supervisor|Email|Social Media|Google|Prior Grant|Other',
		questions : 'Questions',
		reflex : {
			question1 : 'Please provide some background on your teaching experience (and tell us what grades/subjects you currently teach).',
			question2 : 'What methods are you currently using to help students develop math fact fluency? How do you feel they are working?',
			question3 : 'How could focusing on math fact fluency help you address the challenges your students face in mathematics?',
			question4 : 'This grant requires an average student use of three times a week. How would you plan to implement Reflex into your instructional program to accommodate this best use case of the product?',
		},
		frax : {
			question1 : 'Please provide some background on your teaching experience (and tell us what grades/subjects you currently teach).',
			question2 : 'What methods are you currently using to help students begin to understand fractions? How do you feel they are working?',
			question3 : 'How could focusing on the conceptual understanding of fractions, with an adaptive and game-based solution, help you address the challenges your students face in mathematics?',
			question4 : 'How would you plan to implement Frax into your instructional program?'
		},
		ofTotal : '[{num} of {total}]',
		terms : 'Grant Terms',
		mustAgree : 'To be accepted into our Grant program, we also require that you are able to meet these requirements',
		'term0-0' : 'You confirm that the computers you will be using with students are able to run {product}. Please refer to the',
		'term0-1' : 'technical requirements',
		'term0-2' : 'for more information.',
		term1 : 'You agree to our website\'s',
		term2 : 'You agree to actively participate in one 2-hour webinar training session (offered several times weekly) within three weeks of acceptance into the Grant program.',
		term3 : 'You agree to have your students use Reflex at least three times per week until they reach full fluency. Options include usage during classroom time, in computer labs, during after-school programs, and at home.',
		term4 : 'You agree to provide periodic feedback via online surveys.',
		term5 : 'You agree to be contacted by an ExploreLearning representative during the Grant period.',
		term6 : 'You agree to receive company marketing messages via email and that your survey comments may be used in company marketing materials.',
		agree : 'I agree to the ExploreLearning\'s {product} Grant Program requirements',
		step1 : 'Contact Info',
		step2 : 'School Info',
		step3 : 'Questions',
		step4 : 'Terms'
	},
	grantComplete : {
		header : 'Application Received',
		message0 : "A grant committee member will contact you once we’ve processed your application. Thank you!",
		message1 : 'Next Steps:',
		nextStep0 : 'Add <strong>outreach@attention.explorelearning.com</strong> to your safe sender list to receive all grant follow-up emails.',
		nextStep1 : 'Explore and <a href="https://info.explorelearning.com/EL-products.html">take a free trial</a> of any of our math and science programs!',
		'message2-0' : 'If you have any questions, please contact the',
		grantsTeam : 'EL Grants Specialist Team'
	},
	grantCompleteClosed : {
		header : 'Information Received',
		message : 'Thank you for your interest in the our grant program.',
	},
	grantActivation : {
		header : 'Grant Activation',
		readTerms : 'Read & Accept Terms of Use',
		existingUser : 'I Have An Account',
		newUser : 'I Am A New User',
	},
	grantCreated : {
		header :  'Your Grant Is Ready',
		message : 'You are all set to begin using your grant. Click the button below to get started.',
		btn : 'Start Using Your Grant'
	},
	classMgmt : {
		header : 'Classroom Management',
		settings : 'Class Settings',
		classCapExplained : 'You have a limit of {cap} classes. Only classes you are the owner of count towards this limit.',
		classCapExplainedRostered : 'You have a limit of {cap} non-rostered classes. Only non-rostered classes you are the owner of count towards this limit.',
		classCapReached : 'You have reached the limit of {cap} classes per teacher.',
		classCapReachedRostered : 'You have reached the limit of {cap} non-rostered classes per teacher.',
		studentCapExplained: 'You can only have {cap} students per class.',
		studentCapExplainedMore: 'Your class currently has {seatsUsed} students and you have selected {count} more. You cannot add that many students. You may delete them from the roster.',
		name : 'Name',
		students : 'No Students | Student | Students',
		administrators : 'No Administrators | Administrator | Administrators',
		teachers : 'No Teachers | Teacher | Teachers',
		studentID : 'Student ID',
		password : 'Password',
		assignment : 'No Assignment | Assignment | Assignments',
		noData : '(no records found)',
		noStudentRosterData: 'No students available outside of the current class.',
		all : 'All Students',
		addStudents : 'Add Students',
		print : 'Print',
		gradeLevel : 'Grade Level',
		remove : 'Remove',
		delete : 'Delete',
		stillInRoster : 'Removed students are still on the school roster and can be added back to a class at any time.',
		pleaseConfirmRemove : 'Please confirm that you would like to remove ',
		from : 'from',
		pleaseConfirm : 'Please confirm that you would like to delete these student accounts | Please confirm that you would like to delete this student account | Please confirm that you would like to delete these student accounts',
		deleteMessage : '– with the understanding that this is permanent and that the student’s data cannot be recovered.',
		studentPossessive: 'no student\'s | student\'s | students\'',
		sure : 'Are you sure?',
		willBeRemoved : 'will be removed from',
		filter : 'filters',
		transfer : 'Transfer',
		addToClass : 'Add To Class',
		noStudentsToManage : 'No students',
		selectASchoolStudents : 'Please select a school to manage students',
		selectASchoolTeachers : 'Please select a school to manage teachers',

		//start of Classes.vue Headers
		deleteFromSchoolRoster : 'Delete Student Accounts | Delete Student Account| Delete Student Accounts',
		removeFromClass : 'Remove from Class',
		schoolRoster : 'School Roster',
		roster : 'Roster',
		class : 'No Class | Class | Classes',
		school : 'No School | School | Schools',
		editAssignmentHeader : 'Set Reflex Assignment',
		editSectorHeader : 'Set Frax Assignment',
		addStudentNewHeader : 'Create New Student',
		transferStudentHeader : 'Transfer Student',
		addToClassStudentHeader : 'Add Student to Class',
		addStudentFromRoster : 'No Students To Add | Add Student from School Roster | Add Students from School Roster',
		studentSelfEnrollment : 'Class Code',
		createClassHeader : 'Add A New Class',
		classSettingsHeader : 'Class Settings',
		deleteClassHeader : 'Delete Class',
		formattingHeader : 'File Formatting Instructions',
		rosteredClass : 'Rostered Class',
		noAssignmentAlert : '{student} is unable to log in to any products.',
		accessEnabled : 'Access enabled',
		noProductAccessAlert : 'Without {missing}, {student} will be unable to log in to {product}.',
		fraxUpgradeAlert : '{student} has completed all the missions in {sector}.',
		classConfiguredForAutomaticRostering : 'This class is configured for automatic rostering. The students in this class are managed automatically by your institution\'s Student Information System.',
		rosteringHelpPreLinkText : 'For more information about how rostering works, please visit our',
		rosteringHelpLink : 'help center',
		createYourFirstClass : 'CREATE YOUR FIRST CLASS',
		reflex : 'Reflex',
		frax : 'Frax',
		gizmos : 'Gizmos',
		scienceForUs : 'Science4Us',
		studentLoginCards : 'Student Login Cards',
		classRoster : 'Class Roster',
		parentAccountInviteEnglish : 'Parent Account Invite (English)',
		parentAccountInviteSpanish : 'Parent Account Invite (Spanish)',
		youDoNotHaveAnyActiveSubscriptions : 'You do not have any active subscriptions.',
		visitYour : 'Visit your',
		pageToLearnMore : 'page to learn more.',
		page : 'page.',
		addClass : 'Add Class',
		non : 'Non-',
		bulk : 'Bulk',

		//start of Admin.vue
		filterHeader : 'Custom Filter',
		addStudentToRosterHeader : 'Add New Student to Roster',
		resetPasswordHeader : 'Reset Password',
		transferHeader : 'No Users Selected | Transfer User | Transfer Users',
		invite : 'No Invitations|Invitation|Invitations', //userType follows this (appended in Admin.vue)
		deleteConfirmHeader : 'No Users Selected | Delete User | Delete Users',
		csvFormattingHeader : '.CSV Formatting Instructions',
		productAccessHeader : 'Set Product Access',
		inviteAdmins : 'Invite Administrators',
		inviteTeachers : 'Invite Teachers',
		inviteDistrictAdmins : 'Invite District Administrators',
		inviteSchoolAdmins : 'Invite School Administrators',
		schoolSettingsHeader : 'Settings',
		rosteredSchool : 'Rostered School',
		importStudentsFromFile : 'Import Students From File',
		export : 'Export',
		productAccessEnabled : '{product} Access Enabled',
		productAccessDisabled : '{product} Access Disabled',
		toReflex : 'to Reflex',
		toFrax : 'to Frax',
		productAccess : 'Product Access',
		institution : 'No Institution | Institution | Institutions',
		userStatusActive : 'Active',
		userStatusPending: 'Invited',
		selectASchool: 'Please select a school',
		onlyTeachersManageCC: 'Only teachers can manage Gizmos students in this school',
		trainerCCTabMsg: 'Only teachers can manage students',
		pendingInvites: 'Pending Invites',
		accounts: 'Accounts',

		//used in both Classes.vue and Admin.vue
		addANewStudent : 'Create a New Student',
		importStudentHeader : 'Import Students from File',
		editGradeLevelHeader : 'Set Grade Level',

		//AddNewStudentModal
		addNewStudentButton : 'Create Student',
		successfullyAddedToClass : 'was successfully added to the class.',
		passwordAutomaticallyGenerated : 'Automatically generated',
		loginInfo : 'LOGIN INFO',
		loginPage : 'Login Page',
		username : 'Username',
		useTeacherUsername : '* Students use their teacher\'s username to login.',
		addAnotherStudent : 'Create Another Student',
		okayButton : 'OK',
		assignmentTypeConjunction : 'and',
		studentInfo : 'Student Info',
		confirmation : 'Confirmation',
		skip : 'Skip', 
		next : 'Next', 
		back: 'Back',
		productSettings : 'Product Settings',
		studentCapReached : '{product} limit of {count} students reached',
		autoEnroll : 'Students are automatically granted {product} access',
		transferSuccess : 'Transfer Successful',
		successfullyCreated : 'was successfully created.',

		//ClassCodeModal
		classCodeHeader: 'Class Code',
		classCodeSubHeader : 'Class: {className}',
		classCodeIntro : 'Students use the Class Code below to enroll in this class. <a href="{joinURL}">{joinURL}</a>.',
		classCodePaused : 'Class Code enrollment is currently paused.',
		copyCode : 'Copy Code',
		
		//ClassSettingsModal
		className : 'Class Name',
		classOwner : 'Class Owner',
		currentOwner : 'current owner',
		ownerPrivileges : 'Settings for this class can only be updated by the class owner.',
		generalSettings : 'General Settings',
		applyToAllClasses : 'These setting will apply to all of your classes.',
		additionalTeachers : 'Additional Teachers',
		ownerExplanation : 'Teacher able to edit class settings',
		leaveClass : 'Leave Class',
		ownershipHeading : 'Ownership Change',
		ownershipWarning : 'You are about to set another teacher as the owner of this class. By clicking "Accept", you acknowledge that after this change takes place you will lose the ability to do the following:',
		ownershipBullet1 : 'Change Class Name',
        ownershipBullet2 : 'Add/Remove Additional Teachers',
        ownershipBullet3 : 'Delete Class',
		leaveHeading : 'Leave Class',
		leaveWarning : 'You are about to leave this class. By clicking "Accept", you will lose all access to this class.',
		
		//CreateClassModal
		addClassButton : 'Add Class',
		
		//DeleteClassModal
		areYouSureDelete : 'Are you sure you want to delete <b>{className}</b>? This action cannot be undone.',
		deleteWhileAboveCap : 'Your {numClasses} classes currently exceed the limit of {cap} classes. If you delete <b>{className}</b>, you will not be able to create another class to replace it until your number of classes falls below the limit.',
		deleteWhileAboveCapRostered : 'Your {numClasses} non-rostered classes currently exceed the limit of {cap} classes. If you delete <b>{className}</b>, you will not be able to create another class to replace it until your number of non-rostered classes falls below the limit.',
		
		//DeleteUserModal
		theSchoolRoster : 'the school roster.',
		inviteHeader : 'No Invites Selected | Delete Invitation | Delete Invitations',
		invalidateEmails : ' |The registration link previously sent to the selected email address will be invalidated.|The registration links previously sent to the selected email addresses will be invalidated.',
		
		//EditAssignmentModal
		previousAssignment : 'Previous Assignment:',
		settingNoAssignmentForStudents : 'Setting "No Assignment" for a student will suspend their Reflex account and prevent them from being able to log in.',
		youMightBeAboutToChange : 'You may be about to change some or all students\' assignment group from "Addition and Subtraction" to "Multiplication and Division" or vice versa.',
		ifSoTheIndividualReports : 'If so, the individual reports for those affected students\' current assignment group will still be available for reference. But, if their assignment group changes back in the future, that data and their progress will be erased.',
		youAreAboutToChange : 'You are about to change this student\'s assignment group from',
		to : 'to',
		thisStudents : 'This student\'s',
		reportsStillAvailable : 'reports will still be available for reference. But, if their assignment group changes back in the future, that data will be erased.',
		ifStudentHasPrior : 'If the student has any prior',
		dataWillBeErased : 'data, that data and their progress will be erased.',
		
		//EditGradeLevelModal has no unique text
		
		//EditNonStudentModal
		toggleInstructions: 'click to toggle access',
		teacherProductAccess: "set by teacher's administrator",
		enabled : 'Enabled',
		disabled : 'Disabled',
		disable : 'Disable',
		enable : 'Enable',
		userName : 'User Name',
		email : 'Email Address',
		limitReached : 'Limit Reached',
		
		//EditSectorModal
		settingNoAssignment : 'Setting "No Assignment" for a student will suspend their Frax account and prevent them from being able to log in.',
		studentSectorReduction : "|You are about to reduce the sectors this student's is assigned.  This student will be limited to the activities of the sector(s) selected above and they will not be able to access any work belonging to other sectors that may be in progress.  Student data for all sectors will still be available in reports for reference. | You are about to reduce the sectors assigned to at least one of the selected students.  These students will be limited to the activities of the sector(s) selected above and they will not be able to access any work belonging to other sectors that may be in progress.  Student data for all sectors will still be available in reports for reference.",

		//EditStudentModal
		adminInstructions: "set by student's teachers",
		teacherInstructions: 'click to edit',
		studentPassword: 'Student Password',
		specialEdStatus : 'Special Ed Status',
		language : 'Language',
		limitedEnglishProficiency : 'Limited English Proficiency',
		gender : 'Gender',
		raceEthnicity : 'Race / Ethnicity',
		economicallyDisadvantaged : 'Economically Disadvantaged',
		reports : 'Reports',
		productsAndAssignments : 'PRODUCTS AND ASSIGNMENTS',
		
		//FilterModal
		onlyDisplayStudentsMeeting : 'Only display students meeting the selected criteria:',
		clearAllFilters : 'Clear All Filters',
		
		//FormattingModal
		toImportYouNeedCSV : 'To import/bulk update a group of students, you will need to upload a comma delimited .CSV file. The file must be formatted with the first row containing the column names. All Student IDs must be unique.',
		toImportYouNeedCSVClassCode : 'To import/bulk update a group of students, you will need to upload a comma delimited .CSV file. The file must be formatted with the first row containing the column names.',
		fieldCannotBeAltered : '* Field cannot be altered for rostered users',
		requiredColumns : 'Required Columns:',
		optionalColumns : 'Optional Columns:',
		firstNameColumn : 'FirstName',
		lastNameColumn : 'LastName',
		studentIDColumn : 'StudentID',
		gradeLevelColumn : 'GradeLevel',
		middleNameColumn : 'MiddleName',
		languageColumn : 'Language',
		passwordColumn : 'Password',
		genderColumn : 'Gender',
		ethnicity : 'Ethnicity',
		limitedEnglishProficiencyColumn : 'LimitedEnglishProficiency',
		economicallyDisadvantagedColumn : 'EconomicallyDisadvantaged',
		specialEdStatusColumn : 'SpecialEdStatus',
		ethnicityCodes : 'Ethnicity Codes:',
		ethnicity11 : '11 - Hispanic / Latino',
        ethnicity12 : '12 - American Indian / Alaska Native',
        ethnicity13 : '13 - Asian',
        ethnicity14 : '14 - Black / African American',
        ethnicity15 : '15 - Native Hawaiian / Pacific Islander',
        ethnicity16 : '16 - White / Caucasian',
        ethnicity17 : '17 - Two or More Races',
		
		//ImportStudentModal
		help : 'Help',
		instructionsAndSample: 'Instructions & Sample',
		formattingInstructions : 'Formatting Instructions',
		downloadSampleFile : 'Download Sample File',
		importStudents : 'Import Students',
		startOver : 'Start Over',
		tryAgain : 'Try Again',
		
		//ProductAccessModal
		productAccessGrantToast: '{product} access granted to {num} {role}.',
		productAccessRemoveToast: '{product} access removed from {num} {role}.',
		productAccessEmail: 'A welcome email has been sent to the {role} gaining access.',
		subscriptionsUpdated: "Product access has successfully been updated.",
		
		//RegCodeModal
		regCodeHeader: 'Add', //userType follows this (appended in Admin.vue)
		regCodeToast: 'New Link Generated',
		setUpForAutoRostering: 'Your institution is set up for automatic rostering. Any teachers invited will be removed next time your teacher roster is processed if they are not also included in the district roster.',
		addNonRosteredTeachers : 'Add Non-Rostered Teachers',
		selectWhichProductsToInvite : 'Select which product(s) to invite',
		toCreateANewAccount : 'to create a new account.',
		invitationLink : 'Invitation Link',
		forNonRegisteredTeachers : 'for Non-Rostered Teachers',
		clickToCopyLink : '(Click to Copy Link)',
		clickToCopyLinkMsg : 'URL Copied',
		expiresOn : 'Expires On:',
		generateANewLink : 'generate a new link',
		needHelp : 'Need Help?',
		composeEmail : 'Compose Email',
		copyInstructions : 'Copy Instructions',
		copyInstructionsMsg : 'Instructions Copied',
		
		//RemoveStudentModal
		permanentlyDeleteStudent : 'Delete No Student Accounts | Delete Student Account | Delete Student Accounts',
		removeStudent : 'Remove No Students | Remove Student | Remove Students',
		deleteStudent : 'Delete No Students | Delete Student | Delete Students',
		
		//ResetPasswordModal
		thisWillSendAnEmailTo : 'This will send an email to',
		toAllowThisUserToReset : 'to allow this user to reset their password.',
		
		//SchoolSettingsModal has no unique text
		
		//StudentRosterModal
		deleteFromRoster : 'Delete From Roster',
		
		//TransferStudentModal has no unique text
		
		//Edit Access Modal
		editAccessHeader : "Set {product} Access",

		//Invite Modal
		inviteModalHeaderSuccessTeacher :  "Product Access Granted|Invited Teacher|Invited Teachers",
		inviteModalHeaderSuccessAdminDistrict :  "Product Access Granted|Invited District Administrator|Invited District Administrators",
		inviteModalHeaderSuccessAdminSchool :  "Product Access Granted|Invited School Administrator|Invited School Administrators",
		inviteInstructionsAdmins : 'Send email invitations to join this institution as administrators.',
		inviteInstructionsTeachers : 'Send email invitations to join this institution as teachers.',
		multiEmail : 'Enter multiple email addresses',
		emailsHint : 'Separate multiple email addresses with a space or comma',
		role: 'Role',
		products: 'Product(s)',
		existingEmail : "This email address is already associated with an existing {role}, {firstName} {lastName}.",
		existingEmails : "This email address is already associated with {count} accounts.",
		existingEmailsRole : "This email address is already associated with {count} {role} accounts.",
		existingInvite : "This email address is already associated with an existing {role} invitation.",
		noInviteSent : "Their existing invitation will be resent.",
		additionalInviteSent : "They will receive a second, {role} invitation.",
		resentNote : "Note: The product(s) this email address has been invited to remain unchanged.|Note: The product(s) these emails addresses have been invited to remain unchanged.",
		gainProducts : "Any of those accounts that don't have access to {products} will gain access.",
		newRole : "An invitation for a new {role} account will be sent to this email address.",
		mayGetProduct : "This educator may gain access to additional products depending on the products chosen.",
		newProductAccess : "This educator will gain access to {products}.",
		noProductChange: "This educator will not change.",
		invitesSent: "{count} {userType} invitation email sent|{count} {userType} invitation emails sent",
		inviteAccess: "{count} existing {userType} Account granted access to {products}|{count} existing {userType} Accounts granted access to {products}",
		inviteSuccess: "Product access updated.|Invite sent!|Invites sent!",
		noInvitesSent: "No changes were made. All submitted email addresses already have {role} Accounts with {products} access.",
		additionalInvites: "Send Additional Invitations",
		additionalInvitesShort: "Send Additional Invites",
		seeInvites: "See Invitation|See Invitation|See Invitations",
		seeInvitesShort: "See Invite|See Invite|See Invites",
		sendInvitation: "Send",
		overTeacherLimit: "Your {name} subscription is currently using {teachersUsed} of {teachersCap} teacher licenses. Your attempt to license {newSeats} more will exceed the limit by <b>{teacherOverCap}</b>; please adjust accordingly.",

		// Invite Table
		inviteStatus : 'Invitation Status',
		statusPending : 'Pending Delivery',
		statusPendingTooltip : 'Invitation will be sent to recipient shortly.',
        statusDelivered : 'Delivered',
		statusDeliveredTooltip : 'Invitation has been delivered to recipient\'s email address.',
        statusOpened : 'Opened',
        statusError : 'Undeliverable',
		statusErrorTooltip : 'Invitation cannot be delivered. Click for details.',
		statusErrorDetails : "Unable to deliver invitation to <b>{email}</b>.<br/>Verify that the email address is correct.<br/>To resend via your own email address, click '<i>Other Options</i>’ below.",
		resendInvite : 'Resend',
		refreshInvites : 'Refresh',
		refreshInvitesSuccess : 'Invitation data refreshed.',
		invitesResent : 'No Invitations Resent|{count} Invitation Resent|{count} Invitations Resent',
		expiredInvite : 'Expired',
		expiredInviteTooltip : 'Invitation expired. Click to resolve.',
		expiredInviteDetails : "This invitation has expired.<br/>Click '<i>Resend</i>' to send a new invitation.",
		deliveryIssue : 'Delivery Issue',
		deliveryIssueTooltip : 'Invitation not yet delivered. Click for details.',
		deliveryIssueDetails : "Invitation not yet delivered.<br/>To resend via your own email address, click '<i>Other Options</i>' below.",
		inviteDataDelay : 'Invitation status may take a minute to update.',

		// View Invite Modal
		inviteInfo: 'Invitation Information',
		inviteCreated: 'Creation Date',
		inviteExpires: 'Expiration Date',
		resendDate : 'Last Invite Sent (by ExploreLearning)',
		otherOptions : 'Other Options',
		resendInvitePersonal : 'Resend From My Email',
		copyInvite : 'Copy Invitation to Clipboard',
		copyInviteSuccess : 'Invitation copied to clipboard.',
		resendInfo : 'Not updated until email is actually resent. This can take up to two minutes.',
		invited : 'invited',
		
		//More than one modal
		accept : 'Accept',
		save : 'Save',
		cancel : 'Cancel',
		edit : 'Edit',
		editProfile : 'Edit Profile',
		access : 'Access',
		reflexNotRecommended : 'Reflex is not recommended for students below Grade 2.',
		fraxNotRecommended : 'Frax is not recommended for students below Grade 3.',
		subscriptionLimit : 'Your {product} subscription has a limit of {seatCap} active students (students with an assignment).',
		reachedTheLimit : 'Your subscription has reached that limit.',
		currentlyThereAre : 'Currently there are {studentsUsed} active students. Of the {numberOfSelectedStudents} students you are trying to edit, {numberOfUnassignedStudents} {inactive}',
		inactivePlural : 'none inactive. | is inactive. | are inactive.',
		toMakeThisChangeFirst : 'To make this assignment change, you need to first make {countStudentsToMakeInactive} {students} inactive.',
		changingMultipleStudentAssignments : 'You are changing the assignment for multiple students.',
		iUnderstand : 'I Understand',
		rostered : 'ROSTERED',
		profile : 'PROFILE',
		fieldsMarkedWithRequired : 'Fields marked with * are required.',
		fullName : 'Full Name',
		resetPassword : 'Reset Password',
		usedInReports : 'Used in reports, this month/day determines the earliest',
		dateToConsider : 'date to consider as part of',
		thisSchoolYear : 'this school year',
		changeDefaultLanguage : 'Change the default language of the student app.',
		doesNotAffectTeacher : 'This does not affect the educator experience.',
		updateSettingsButton : 'Update Settings',
		licenseLimitReached : 'You have used all {teachersCap} {name} Licenses. Disable other teachers to free up licenses.',
	},
	schoolMgmt : {
		settings : 'Settings',
		classSettings : 'Class Settings',
		waitTime : 'Allow 30 minutes for changes to apply',
		districtDate : 'You are updating the start date for a district. This date will also be applied to every school in this district that has not yet set a start date.',
		startDate : 'Start Date for Current School Year',
		defaultLang : 'Default Language',
		allowEnroll : 'Allow Teachers to Enroll Students',
		allowEnrollExplained : 'If checked, Teachers can create new students -- manually or via CSV Import -- and permanently delete students from the school roster.',
		allowEnrollClassCodeModifier : 'This only applies to teachers of classes that do not use classcodes.'
	},
	parent_letter : {
		intro:
		  'Great news! Your child is using ExploreLearning Reflex, the world\'s ' +
		  'most effective (and fun!) program for helping students master their ' +
		  'basic addition, subtraction, multiplication, and division facts. This ' +
		  'letter contains student login information, as well as instructions for ' +
		  'setting up your own Reflex Parent Reporting Account that will allow you ' +
		  'to monitor and support your child\'s journey to math fact fluency.',
		addChild:
		  '*To add your child, you will need information from your child\'s ' +
		  'Student Login Card',
		access:
		  'To access your reporting account in the future, visit ' +
		  'reflexmath.com or go-el.com, then log in with your username and password.',
		copy:
		  'Since Reflex includes fun games, your child\'s siblings or friends may ' +
		  'want to give it a try. Reflex continually monitors student progress and ' +
		  'adapts to each student\'s needs. Because of this adaptivity, having ' +
		  'multiple children use the same account will have a negative impact on ' +
		  'your child\'s progress. You can sign up for a free 30-day trial for up ' +
		  'to 5 other children to try Reflex, at www.reflexmath.com/trial.',
		doNotShare:
		  'It is important that other children do not share your child\'s account.',
		student:
		  'Student',
		dearParent:
		  'Dear Parent or Guardian',
		loginCard:
		  'Student Login Card',
		parentSignUp:
		  'Parent Reporting Account Sign-Up',
		visit:
		  'Go to',
		goTo:
		  'Go to',
		followInstructions:
		  'Follow the onscreen instructions to get free access.',
		onceIn:
		  'Once you are logged in, follow the instructions to add your child.',
		questions:
		  'Questions about Reflex?',
		contactEL:
		  'Contact ExploreLearning Customer Service',
	},
	login_card : {
		loginInfo : 'LOGIN INFO',
		website : 'WEBSITE',
		teacherUsername : 'TEACHER USERNAME',
		class : 'CLASS',
		password : 'PASSWORD',
		doNotShareLogin : 'Do not share your login with others.',
	},
	home : {
		welcome: 'Welcome, {name}!',
		welcomeWord: 'Welcome',
		studentBtn: 'Manage Students',
		userBtn: 'Manage Users',
		products: 'My ExploreLearning products:',
		teacherHub: 'Check out the ExploreLearning Teacher Resource Hub',
		newSub: 'Your new {product} {subType} is all set up and ready to go! | Your new {product} {subType}s are all set up and ready to go!',
		ssoTrial : 'You are all set. Your trial has been created. All ExploreLearning products, Gizmos, Reflex, Frax and Science4Us, use the same login. In the future, access any by clicking the existing icon in your LMS.',
		employee: 'Employee tools:',
		subscriptions: 'Subscriptions',
		productSelections: 'Product Selections',
		enableDisableProducts: 'Enable/Disable Products',
		gizmosClasses: 'Gizmos Classes',
		gizmosStudents: 'Gizmos Students',
		newClass: 'New Class',
		admins: 'Administrators',
		teachers: 'Teachers',
		students: 'Students',
		learnMore: 'Learn more about product-specific class rosters',
		myClasses: 'My Classes',
		manageUsers: 'Manage Users',
		myProducts: 'My Products',
		employeeApps: 'Employee Apps',
		trial : {
			headline: 'Learn More and Try for Free',
			intro: 'ExploreLearning offers an entire suite of STEM-focused solutions to help students in Grades K-12 achieve success. For more information and product demonstrations, see below.  Note that if you have or recently had a subscription, trial, or grant to one of our products you are ineligible to start a trial with that same product. Additionally, if you have opted out of any products they will be included below.',
			selectSchool: 'Select which school to associate your trial with',
			learnMore: 'Learn More',
			takeTrial: 'Take A Trial',
			instructions: 'Please <b>close this browser tab</b> and then <b>check your email address</b>, {email}, for a trial activation link.',
			errorMsg: 'We are unable to set up your trial at this time.',
			schoolSelectionInfo: 'You belong to more than one school. Select the school you would like to associate your trial with.',
			optIn: 'Opt In',
			startTrial: 'Start Trial'
		},
		firstClass : {
			welcome : 'Welcome to ExploreLearning!',
			setup : 'Let\'s get your account set up:',
			instruction : 'Set up your first class:',
			label : 'Class name',
			default : 'My First Class',
			btn : 'Continue',
			info : 'Classes and students are shared across all products.',
			success: 'Created class: {name}', 
			genericSuccess: 'Your display name has been saved.'
		},
		freemium : {
			header: 'Gizmos Free Access',
			intro : 'How it works',
			bullet1 : 'You get 20-40 Free Gizmos to teach with',
			bullet2 : 'Access lesson materials for Free Gizmos',
			subbullet2 : 'Teacher guides, lesson plans, and more',
			bullet3 : 'All other Gizmos are limited to a 5 Minute Preview',
			subbullet3 : 'They can only be used for 5 minutes a day',
			bullet4 : 'List of Free Gizmos changes each semester',
			freeGizmos : ' View list of free gizmos'
		}
	},
	optin : {
		products: 'Select the products you plan to teach with:'
	},
	passwordRule : {
		intro : 'In order to protect your account, please make a strong password.',
		length : '{min} character|{min}-{max} characters',
		minDigitChars : '{min} number|{min} numbers',
		minLowercaseChars : '{min} lowercase',
		minSpecialChars : '{min} special character|{min} special characters',
		minUppercaseChars : '{min} uppercase',
	},
	dropzoneImport : {
		too_many_fname_characters: `
		First names must be 30 characters or less: {fields}.
	  `,
	  too_many_lname_characters: `
		Last names must be 30 characters or less: {fields}.
	  `,
	  too_many_password_characters: `
		Passwords must be 30 characters or less: {fields}.
	  `,
	  too_many_id_characters: `
		Student IDs must be 50 characters or less: {fields}.
	  `,
	  no_students: `
		We couldn't find any rows with student data in the file you provided.
		Check that you file is formatted correctly.
	  `,
	  no_diacritics: `
		Passwords cannot include any diacritics: {fields}.
	  `,
	  missing_headers: `
		We couldn't find the following required headers: {headers}.
	  `,
	  duplicate_headers: `
		We found the following duplicate column headers: {headers}.
	  `,
	  guessed_columns: `
		Check to be sure the student info is in the correct columns.
	  `,
	  bad_file_format: `
		The file provided is not formatted correctly so we could not process it.
		See the formatting instructions in the "Help" menu below.
	  `,
	  data_errors: `
		We found errors with the data in the file provided.
	  `,
	  missing_required_data: `
		First Name, Last Name, Student ID, and Grade Level values are required for
		each student. We found {count} row(s) missing one or more of these
		required fields.
	  `,
	  duplicate_student_ids: `
		Student IDs must be unique. The following student IDs are included
		multiple times: {ids}
	  `,
	  existing_student_ids: `
		There are already accounts for the following students which have last names
		different than those provided in the file.
	  `,
	  invalid_language_vals: `
		We could not parse the following values provided in the Language column:
		{vals}. Use "English", "French", or "Spanish" as the value for any row
		specifying a Language value.
	  `,
	  invalid_grade_level_vals: `
		We could not parse the following values provided in the GradeLevel column:
		{vals}. Use "Kindergarten", "Grade 1", "Grade 2", "Grade 3", etc., for the
		GradeLevel value of each row.
	  `,
	  invalid_gender_vals: `
		We could not parse the following values provided in the Gender column:
		{vals}. Use "Female" or "Male" for the Gender value of each row.
	  `,
	  invalid_eng_prof_vals: `
		We could not parse the following values provided in the
		LimitedEnglishProficiency column: {vals}. Use "Yes" or "No" for the
		LimitedEnglishProficiency value of each row.
	  `,
	  invalid_ethnicity_vals: `
		We could not parse the following values provided in the Ethnicity column:
		{vals}. Use a valid Ethnicity ID for the Ethnicity value of each row. The
		"Formatting Instructions" in the Help menu list the Ethnicity IDs.
	  `,
	  invalid_disadv_vals: `
		We could not parse the following values provided in the
		EconomicallyDisadvantaged column: {vals}. Use "Yes" or "No" for the
		EconomicallyDisadvantaged value of each row.
	  `,
	  invalid_special_vals: `
		We could not parse the following values provided in the SpecialEdStatus
		column: {vals}. Use "Yes" or "No" for the SpecialEdStatus value of each row.
	  `,
	  transfer_warning: `
		Students from other institutions were found with User IDs matching ones
		from the file provided. Before being added to your class, these students
		will be transferred to your institution and will be updated with the
		information specified in the file. If it is not your intent to transfer
		these students, change the User ID values of the matching rows of data.
	  `,
	  overwrite_warning: `
		Students from this institution were found with User IDs matching ones from
		the file provided. Before being added to your class, these students will
		be updated with the information specified in the file. If it is not your
		intent to update these students, change the User ID values of the matching
		rows of data.
	  `,
	  product_add_failure: `
		We were unable to add the imported students to the product. Try again by
		specifying an assignment for the students in the roster.
	  `,
	  import_success: 'The import was successful!'
	}
}
