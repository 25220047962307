<template>
    <UserManagmentModal
        :value="value"
        :headline="$t('classCode.classCodes')"
        @closeDialogs="closeDialogs()"
        size="600"
    >
        <v-container>
            <v-layout wrap my-5>
                <v-flex class="d-flex mb-2" xs12>
                    <v-icon class="large-chat-bubble mx-auto" color="primary">chat_bubble</v-icon>
                </v-flex>
                <v-flex xs10 class="mx-auto">
                    <div class="text-xs-center title mb-3">{{$t('classCode.explainationHeader')}}</div>
                    <div class="text-xs-center subheading">{{isAdmin ? $t('classCode.explainationSubheader') : $t('classCode.teacherExplainationSubheader') }}</div>
                </v-flex>
            </v-layout>
        </v-container>
    </UserManagmentModal>
</template>

<script>
    import UserManagmentModal from 'components/layout/UserManagmentModal'

	export default {
        name: 'ClassCodeInfoModal',
        components: { UserManagmentModal },
        props: {
            value: Boolean,
            isAdmin: Boolean
        },
		computed : {
        },
        methods: {
            closeDialogs() {
                this.$emit('closeDialogs',)
                this.$emit('close')
            },
        }
	}
</script>

<style lang="less" scoped>
</style>