export default {
	methods: {
        filteredTableData(tableRef){
            if(this.$refs[tableRef]){
                return this.$refs[tableRef].filteredItems
            } else {
                return []
            }
        },
		toggleAll(selected,data) {
            if (this[selected].length){
                this[selected] = []
            } else {
                this[selected] = data
            }
        },
	}
}
