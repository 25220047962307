export default {
  // Common
  form : {
    username : 'Hodor', //Username
    teacherUsername : 'Hodor', //Teacher Username
    password : 'Hodor', //Password
    passwordConfirm : 'Hodor', //Confirm Password
    email : 'Hodor', //Email
    passwordNew : 'Hodor', //New Password
    forgotPassword : 'Hodor?', //Forget your password?
    submit : 'Hodor', //Submit
  },
  navigation : {
    back : 'Hodor', //Back
  },
  error : {
    404 : 'Hodor', //Unable to connect to server. Check Internet connection.
    unknown : 'Hodor', //Unknown Error
    invalidCredentials : 'Hodor', //Invalid Username or Password
    invalidPassword : 'Hodor', //Invalid Password
    invalidUsername : 'Hodor', //User Not Found
    invalidEmail : 'Hodor', //No Account Associated With That Email
    invalidCode: 'Hodor', //Invalid Reset Code
    unmatchingPasswords : 'Hodor', //Password Fields Do Not Match
  },
  success : {
    emailSent : 'Hodor', //Check your email for further information about recovering/resetting your password.
    passwordReset : 'Hodor', //Password successfully reset.
  },
  // Component Specific
  loginChoice : {
    students : 'Hodor', //Students
    educators : 'Hodor', //Educators and Parents
    educatorsSub : 'Hodor', //Reports and Administration
  },
  teacherLogin : {
    header : 'Hodor', //Educator/Parent Login
  },
  chooseAccount : {
    header : 'Hodor', //Password Reset
    instruction : 'Hodor', //Select Account
  },
  resetPassword: {
    back : 'Hodor', //Wrong Account
  },
  studentLogin : {
    header : 'Hodor', //Student Login
  },
  chooseClass : {
    header : 'Hodor', //Select Your Class
    subHeader : 'Hodor', //Teacher:
    back : 'Hodor?', //Wrong Teacher?
  },
  chooseStudent : {
    header : 'Hodor', //Select Your Name
    subHeader : 'Hodor', //Class:
    back : 'Hodor?', //Wrong Class?
  },
  studentPassword : {
    header : 'Hodor', //Enter Password
    subHeader : 'Hodor', //Student:
    back : 'Hodor?', //Not You?
  }
}
