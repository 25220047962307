import Constants from 'mixins/Constants/'
import ReportPrinter from 'utils/ReportPrinter'
import _each from 'lodash/each'
import _sortBy from 'lodash/sortBy'
import { mapGetters } from 'vuex'

export default {
    mixins: [ Constants, ReportPrinter ],
    data(){
        return {
            pdfMap: {
                'cards': 'studentLoginCards',
                'roster': 'roster',
                'letters-en': 'parentLetters',
                'letters-es': 'parentLetters',
            },
            sortMap: {
                'name' : {label: 'Last Name', sortBy: function(s){return[s.lastName.toLowerCase(),s.firstName.toLowerCase()]}},
                'sisUserId' : {label: 'Student ID', sortBy: function(s){return[s.sisUserId.toLowerCase(), s.lastName.toLowerCase(),s.firstName.toLowerCase()]}},
                'reflexJson' : {label: 'Assignment', sortBy: function(s){return[s.assignment, s.lastName.toLowerCase(),s.firstName.toLowerCase()]}}
            }
        }
    },
    computed: {
        ...mapGetters('platformData/profile', ['profile']),
    },
	methods: {
		onPrint(id,userIdToPrint) {
            this.getPDF(id,userIdToPrint).then(() => this.ReportPrinter.print());
        },
        onSave(id,userIdToPrint) {
            this.getPDF(id,userIdToPrint).then(() => this.ReportPrinter.download(id + '.pdf'));
        },
        async getPDF(id,userIdToPrint) {
            let students
            if(typeof userIdToPrint === 'object' && userIdToPrint !== null){
                students = [userIdToPrint]
            } else if(userIdToPrint){
                let userToPrint = this.students.find(function(student){
                    return student.id == userIdToPrint
                })
                students = [userToPrint]
            } else {
                students = this.selectedStudents.length ? this.selectedStudents : this.studentsInSelectedClass
                students = _sortBy(students, [this.sortMap[this.paginationOptions.sortBy].sortBy])
                if(this.paginationOptions.descending){
                    students = students.reverse()
                }
                students.forEach(student => {
                    student.name = `${student.lastName}, ${student.firstName}`
                    student.sisUserId = student.sisID
                    student.passwordClear = student.password
                    student.grade = this.getGradeLevel(student.gradeID)
                    student.language = this.getLanguage(student.languageID)
                })
            }
            let username = this.profile.username
            let labels = {
                className: this.selectedClass ? this.selectedClass.name : null,
                teacherName: this.profile.lastName+', '+this.profile.firstName,
                username,
            }
      
            let config = { students, labels }
      
            let loginCardTerms = [
                'loginInfo', 'website', 'teacherUsername', 'class', 'password',
                'doNotShareLogin'
            ];
            let parentLetterTerms = [
                'intro', 'addChild', 'access', 'doNotShare', 'copy', 'student',
                'dearParent', 'loginCard', 'parentSignUp', 'visit', 'goTo',
                'followInstructions', 'onceIn', 'questions', 'contactEL'
            ]
      
            if (id === 'cards') {
                config.labels = {
                    ...config.labels,
                    ...this.getTranslations('print_messages.login_card', 'en', [
                        ...loginCardTerms
                    ])
                }
            } else if (id.indexOf('letters') === 0) {
                let locale = id.split('-')[1];
                config.labels = {
                    ...config.labels,
                    ...this.getTranslations('parent_letter', locale, [
                        ...parentLetterTerms
                    ]),
                    ...this.getTranslations('login_card', locale, [
                        ...loginCardTerms
                    ])
                }
            } else if (id === 'roster') {
                let headers = [
                    'Student', 'Password', 'Grade', 'Language'
                ];
                if(this.selectedClass.isClassCodeEnabled){
                    headers.splice(1,0,'Username')
                } else {
                    headers.splice(1,0,'Student ID')
                }
                let sortBy = this.sortMap[this.paginationOptions.sortBy].label
                let sortMessage = '* Sorted by '+sortBy;
                sortMessage += this.paginationOptions.descending ? ' (reverse)' : ''
        
                config.headers = headers;
                config.labels.sortMessage = sortMessage;
            }
            await this.ReportPrinter.renderDoc(this.pdfMap[id], config);
        },
        getTranslations(module, locale, keys) {
            let prevLocale = this.$i18n.locale;
            this.$i18n.locale = locale;
        
            let translations = {}
            _each(keys, k => translations[k] = this.$t(`${module}.${k}`));
        
            this.$i18n.locale = prevLocale;
        
            return translations;
        }
	}
}
