<template>
	<v-expansion-panel id="legal">
		<v-expansion-panel-content>
			<v-flex slot="header">Student and Data Privacy</v-flex>
			<v-card>
    	<v-card-text class="grey lighten-3">
					<v-flex mb-2>{{productName}} is an online learning tool from ExploreLearning that may collect personal information from children, including the following:</v-flex>
					<v-flex pl-4 mb-2>
						<ul>
							<li><b>Open Text</b>: Children may submit written responses to various prompts, which account holders and parents may access.</li>
							<li><b>Persistent Identifiers</b>: We automatically receive and record certain technical information from children's browsers as they use our products, including their IP address, using technologies such as cookies. We collect and use this information only within our company in order to operate and improve our products.</li>
						</ul>
					</v-flex>
					<v-flex mb-2>
						By submitting this application you are granting ExploreLearning permission to collect, use, and disclose the personal information described above for children you use {{productName}} with. Including children under the age of 13.
					</v-flex>
					<v-flex>
						We will not provide any personal information we collect from children to third parties. We use this information for the above purposes only. For detailed information about our privacy practices, please read our <router-link :to="{name:'Privacy'}">{{$t('footer.privacy')}}</router-link>.
					</v-flex>
				</v-card-text>
			</v-card>
		</v-expansion-panel-content>
	</v-expansion-panel>
</template>

<script>
	export default {
		name: 'COPPA',
		props: {
			productName: String,
		}
	}
</script>

<style lang="less" scoped>
	#legal {
		.card{
			border-radius: 0;
			padding: 0;
		}
	}
</style>
