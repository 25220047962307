import PDFDocument from 'utils/PDFDocument'
import imgs from 'utils/Imgs.js'
import _each from 'lodash/each'
import _defaultTo from 'lodash/defaultTo'
import _map from 'lodash/map'
import { getUrls } from '@explorelearning/urls';

/**
 * Functions for generating pdfs based on various reports and views in the
 * reporting app
 */
const Generators = {    
  /**
   * generates a pdf for the roster view
   * @param  {Object} [config={}]
   *         configuration for the pdf
   * @param  {Array} [config.headers]
   *         titles for each table collumn
   * @param  {Array} [config.students]
   *         data for each student to render to pdf
   * @param  {Object} [config.labels]
   *         keyed labels for text to add to pdf
   * @return {PDFDocument}
   *         roster view pdf document
   */
  roster({ headers, students, labels } = {}) {
    let { className, teacherName, sortMessage } = labels || {};
    let filename = className.trim() + ' Roster';
    let doc = new PDFDocument({ filename });
    let header = { title: filename, subText: teacherName };

    let rows = _map(students, (student) => {
      if(student.isClassCodeEnabled){
        let { name, username, passwordClear, grade, language } = student;
        return [ name, username, passwordClear, grade, language ];
      } else {
        let { name, sisUserId, passwordClear, grade, language } = student;
        return [ name, sisUserId, passwordClear, grade, language ];
      }
    });

    return doc.drawHeader(header)
      .moveCursor({ x: 20, y: 10 })
      .write(sortMessage, 'b')
      .moveCursor({ y: 10 })
      .table({ headers, rows })
      .drawBorder();
  },

  /**
   * generates a pdf of the login cards for the provided students
   * @param  {Object} config
   *         configuration for the pdf
   * @param  {Array} [config.students]
   *         data for each student to render login cards for
   * @param  {Object} [config.labels]
   *         keyed labels for text to add to pdf
   * @return {PDFDocument}
   *         login cards pdf document
   */
  studentLoginCards({ students, labels }) {
    let doc = new PDFDocument({ filename: 'Student Login Cards' });
    _templates.studentLoginCards({ doc, students, labels });
    return doc;
  },

  /**
   * generates a pdf of the parent invite letters for the provided students
   * @param  {Object} [config]
   *         configuration for the pdf
   * @param  {Array} [config.students]
   *         data for each student to render login cards for
   * @param  {Object} [config.labels]
   *         keyed labels for text to add to pdf
   * @return {PDFDocument}
   *         login cards pdf document
   */
  parentLetters({ students, labels }) {
    let doc = new PDFDocument({ filename: 'Parent Letters' });
    _templates.parentLetters({ doc, students, labels });
    return doc;
  }
};


/**
 * TEMPLATES - functions for rendering pdf templates that are either common or
 * require granular manipulation in the PDFDocument via the jspdf library
 */
const _templates = {
   /**
   * Template renderer for student login cards
   * @param  {Object} [config={}]
   *         configuration for the template
   * @param  {PDFDocument} [config.doc]
   *         reference to the current pdf document
   * @param  {Array} [config.students]
   *         data for each student to generate a login card for
   * @param  {Object} [config.labels]
   *         keyed labels for text to add to pdf
   * @return {void}
   */
  studentLoginCards({ doc, students, labels = {} }) {
    let { pdf } = doc;
    let { username, className } = labels;
    let config = {
      marginTB: 32,
      marginLR: 32,
      gutter: 62,
    };
    pdf.setFont('helvetica').setFontType('bold');
    let width = pdf.internal.pageSize.getWidth();
    let height = pdf.internal.pageSize.getHeight();
    let margin = {
      top: config.marginTB,
      bottom: height - config.marginTB,
      left: config.marginLR,
      right:  width - config.marginLR
    };
    pdf.setDrawColor(0).setFillColor(255, 255, 255);

    let x = margin.left
    let y =  margin.top;
    let oX = margin.left;
    let oY = margin.top;
    let w = (width/2) - margin.left - (config.gutter/2);
    let h = (height/2) - margin.top - (config.gutter/2);
    
    pdf.addImage(imgs.scissors, 'PNG', x, (height/2) - 6, 12, 0);

    for (let i = 1; i <= students.length; i++) {
      let student = students[i-1];

      pdf.setLineWidth(2).setDrawColor(230);
      pdf.roundedRect(x, y, w, h, 1, 1, 'S');

      pdf.setLineWidth(.55).setDrawColor(60).setFillColor(255);
      pdf.roundedRect(x, y, w, h, 1, 1, 'DF');

      let imgW = 200
      let { exploreLearningLogo, elLogo } = imgs;
      pdf.addImage(exploreLearningLogo, 'PNG', x + (w/2) - (imgW/2), y + 17, imgW, 0);
      pdf.addImage(elLogo, 'PNG', x + w - 74, y + h - 74, 56, 0);

      let infoX = x + 17;
      let infoY = y + 80;
      let infoMargin = 42;

      pdf.setFontSize(12).setTextColor(130);
      pdf.text(infoX, infoY, 'LOGIN INFO');

      let name = student.firstName+' '+student.lastName.substring(0,1)+'.'
      let nLen = name.length;
      let nameFontSize = (nLen > 28) ? 8 : ((nLen > 20) ? 10 : ((nLen > 18) ? 14 : 19));
      pdf.setFontSize(nameFontSize).setTextColor(25);
      pdf.text(infoX, infoY + 31, _defaultTo(name, ''));
      pdf.setLineWidth(0.25).setDrawColor(180);
      pdf.line(infoX, infoY + 6, infoX + w - 34, infoY + 6);

      infoY = infoY + 60;

      let studentLoginURL = getUrls().platformShortLink
      let password = student.passwordClear || student.password
      let info = [
        { l: 'WEBSITE', v: studentLoginURL, c: '#1433AA' },
        { l: 'USERNAME', v: student.isClassCodeEnabled ? student.username : username.trim() }
      ];
      if(!student.isClassCodeEnabled){
        info.push({ l: 'CLASS', v: className.trim() });
      }
      info.push({ l: 'PASSWORD', v: password.trim() });

      for (let j = 0; j < info.length; j++) {
        let iItem = info[j];
        let iItemY = infoY + (j * infoMargin);
        pdf.setFontSize(10).setFontType('normal').setTextColor(100);
        pdf.text(infoX, iItemY, _defaultTo(iItem.l, ''));
        pdf.setFontSize(15).setFontType('bold');
        if (iItem.c) {
          pdf.setTextColor(15, 30, 65);
        } else {
          pdf.setTextColor(25);
        }

        // begin simple fix to make sure the values never overflow the border
        // todo: ideally, this whole function would use the PDFDocument api
        let prevPosition = {...doc.cursor};
        let prevFontSize = doc.pdf.internal.getFontSize();

        let text = _defaultTo(iItem.v, '');
        let textWidth = doc.getTextWidth(text);

        if (textWidth > 400) { // if the text is way too long, truncate it
          text = text.slice(0, 40) + '...';
          textWidth = doc.getTextWidth(text);
        }

        if (textWidth > 208) { // if the text is a little too long, resize it
          doc.resizeToWidth({ text, width: 208 });
        }

        doc.setCursor({ x: infoX, y: iItemY + 17 });
        doc.write(text);

        doc.setCursor(prevPosition);
        doc.pdf.setFontSize(prevFontSize);
        // end simple fix
      }

      if (i % 4 === 0) {
        pdf.addPage();
        x = oX;
        y = oY;
      } else if (i % 2 === 0) {
        y = h + margin.top + config.gutter;
        x = margin.left;
      } else {
        x = w + margin.left + config.gutter;
      }
    }
  },

  /**
   * Template renderer for parent letters
   * @param  {Object} [config={}]
   *         configuration for the template
   * @param  {PDFDocument} [config.doc]
   *         reference to the current pdf document
   * @param  {Array} [config.students]
   *         data for each student to generate a login card for
   * @param  {Object} [config.labels]
   *         keyed labels for text to add to pdf
   * @return {void}
   */
  parentLetters({ doc, students, labels }) {
    let { pdf } = doc;
    let { loginCard } = _templates;
    let { intro, addChild, access, doNotShare, copy } = labels;

    function getTextWidth(text) {
      let { getFontSize, scaleFactor } = pdf.internal;
      return pdf.getStringUnitWidth(text) * getFontSize() / scaleFactor;
    }

    let config = {
      marginTB: 32,
      marginLR: 32,
      padding: 17
    };
    let width = pdf.internal.pageSize.getWidth();
    let height = pdf.internal.pageSize.getHeight();
    let margin = {
      top: config.marginTB,
      bottom: height - config.marginTB,
      left: config.marginLR,
      right:  width - config.marginLR
    };

    for (let s = 1; s <= students.length; s++) {
      let x = margin.left;
      let y = margin.top;
      let w = width - (config.marginLR * 2);
      let h = height - (config.marginTB * 2);
      let student = students[s-1];

      // page border
      pdf.setLineWidth(.55).setDrawColor(60).setFillColor(255);
      pdf.roundedRect(x, y, w, h, 1, 1, 'S');

      // inset coordinates
      x += config.padding;
      y += config.padding;
      w -= (config.padding * 2);
      h -= (config.padding * 2);

      // make header
      pdf.addImage(imgs.reflexLogo, 'PNG', x, y, 130, 0);

      pdf.setFontSize(16).setFontType('bold').setTextColor(25);

      y += 20;
      let title = 'Reflex Parent Reporting Account';
      pdf.text(x + 150, y, _defaultTo(title, ''));
      pdf.setFontSize(12).setTextColor(100);
      let { name, grade } = student;
      pdf.text(x + 150, y + 17, `${labels.student}: ${name} - ${grade}`);

      y += 45;

      pdf.setLineWidth(0.25).setDrawColor(180);
      pdf.line(x, y, w + x, y);

      y += 34;

      pdf.setFontStyle('normal').setFontSize(12).setTextColor(0);
      pdf.text(x + 28, y, labels.dearParent + ',');
      y += 28;
      pdf.text(x + 28, y, pdf.splitTextToSize(_defaultTo(intro, ''), 468));

      y += 102;
      pdf.setFontStyle('bold');
      pdf.text(x + 300, y, labels.loginCard);
      pdf.text(x + 28, y, labels.parentSignUp);
      loginCard(pdf, x + 300, y + 17, 198, 272, student, imgs, labels);

      y += 28;
      pdf.setFontStyle('normal');
      let list = [
        [labels.visit, 'reflexmath.com/parents'],
        labels.followInstructions,
        labels.onceIn + '*'
      ];

      for (let i = 0; i < list.length; i++) {
        let listItem = list[i];
        let itemX = x + 28;
        let itemY = y + (i * 23);
        let num = i + 1;

        pdf.setFontSize(12).setFontType('normal').setTextColor(0);
        pdf.text(itemX, itemY, num + '. ');
        if (listItem instanceof Array) {
          pdf.text(itemX + 14, itemY, _defaultTo(listItem[0], ''));
          pdf.setFontType('bold').setTextColor(46, 109, 164);
          let itemText = _defaultTo(listItem[1], '');
          pdf.text(itemX + 14 + getTextWidth(listItem[0]), itemY, itemText);
        } else {
          let listItemWrapped = pdf.splitTextToSize(listItem, 227);
          pdf.text(itemX + 14, itemY, _defaultTo(listItemWrapped, ''));
        }
        if (listItem.length > 35) y += 17;
      }
      y += 70;
      pdf.setFontType('italic').setFontSize(10).setTextColor(100);
      pdf.text(x + 43, y, pdf.splitTextToSize(_defaultTo(addChild, ''), 227));

      y += 53;
      pdf.setFontStyle('normal').setFontSize(12).setTextColor(0);
      pdf.text(x + 28, y, pdf.splitTextToSize(_defaultTo(access, ''), 227));

      y += 139;
      pdf.setFontStyle('bold');
      pdf.text(x + 28, y, _defaultTo(doNotShare, ''));

      y += 28;
      pdf.setFontStyle('normal').setFontSize(12).setTextColor(0);
      pdf.text(x + 28, y, pdf.splitTextToSize(_defaultTo(copy, ''), 468));

      y += 108;
      pdf.setFontSize(20).setFontStyle('bold').setTextColor(160);
      pdf.text(x + 28, y, labels.questions);

      y -= 9;
      pdf.setFontSize(10).setFontStyle('normal').setTextColor(120);
      let splitText = pdf.splitTextToSize(labels.contactEL, 200);

      y -= 10 * (splitText.length - 1);
      _each(splitText, (text) => {
        let width = getTextWidth(text);
        pdf.text(x + 496 - width, y, text);
        y += 10;
      })
      pdf.setTextColor(46, 109, 164);
      pdf.text(x + 386, y + 1, 'support@reflexmath.com');

      if(s<students.length){
        pdf.addPage();
      }
    }
  },

  /**
   * Template for a single login card for a provided student
   * @param  {PDFDocument} pdf
   *         A reference to the current pdf document
   * @param  {Number} x
   *         The initial horizontal offset
   * @param  {Number} y
   *         The initial vertical offset
   * @param  {Number} w
   *         A reference to the width of the document
   * @param  {Number} h
   *         A reference to the height of the document
   * @param  {Object} student
   *         The student data to render the login card for
   * @param  {Object} imgs
   *         Keyed images to use when rendering
   * @param  {Object} labels
   *         keyed labels for text to add to pdf
   * @return {void}
   */
  loginCard(pdf, x, y, w, h, student, imgs, labels) {
    let { username, className } = labels;
    pdf.setLineWidth(.25).setDrawColor(60).setFillColor(255);
    pdf.roundedRect(x, y, w, h, 1, 1, 'DF');

    let imgW = 170;
    let { exploreLearningLogo, elLogo } = imgs;
    pdf.addImage(exploreLearningLogo, 'PNG', x + (w/2) - (imgW/2), y + 11, imgW, 0);
    pdf.addImage(elLogo, 'PNG', x + w - 51, y + h - 57, 40, 0);

    let infoX = x + 11;
    let infoY = y + 60;
    let infoMargin = 34;

    pdf.setFontSize(10);
    pdf.setTextColor(130);
    let name = student.firstName+' '+student.lastName.substring(0,1)+'.'
    let nLen = name.length;
    let nameFontSize = (nLen > 28) ? 8 : ((nLen > 19) ? 10 : ((nLen > 18) ? 12 : 16));
    pdf.text(infoX, infoY, labels.loginInfo);
    pdf.setFontSize(nameFontSize).setTextColor(25);
    pdf.text(infoX, infoY + 23, _defaultTo(name, ''));
    pdf.setLineWidth(0.25).setDrawColor(180);
    pdf.line(infoX, infoY + 6, infoX + w - 23, infoY + 6);

    infoY = y + 105;
    let info = [
      { l: labels.website, v: getUrls().platformShortLink, c: true },
      { l: labels.teacherUsername, v: username },
      { l: labels.class, v: className },
      { l: labels.password, v: student.password }
    ];

    let iItemY;
    for (let j = 0; j < info.length; j++) {
      let iItem = info[j];
      iItemY = infoY + (j * infoMargin);
      pdf.setFontSize(8).setFontType('normal').setTextColor(100);
      pdf.text(infoX, iItemY, _defaultTo(iItem.l, ''));
      pdf.setFontSize(12).setFontType('bold');
      pdf.setTextColor(...(iItem.c ? [46, 109, 164] : [25]));
      pdf.text(infoX, iItemY + 14, _defaultTo(iItem.v, ''), { maxWidth: 185 });
    }
    pdf.setFontSize(8).setTextColor(100);
    pdf.text(infoX + 14, iItemY + 60, labels.doNotShareLogin);
  },
};

// wrap all of the generator functions in Promises so that they are async
const PDFGenerator = {};
_each(Generators, (generator, name) => {
  PDFGenerator[name] = (opts) => {
    return new Promise((resolve, reject) => {
      try { resolve(generator(opts)); }
      catch (error) { reject(error); }
    })
  };
})

export default PDFGenerator;
