<template>
	<v-card xs12 flat color="grey lighten-5" :class="classes">
        <v-flex class="mb-1">{{ $t('passwordRule.intro') }}</v-flex>
		<v-chip 
            v-for="rule in rules" 
            :key="rule.ruleName" 
            :color="rule.fulfilled ? 'success' : 'grey'" text-color="white"
            style="pointer-events: none"
            tabindex = "-1"
            small
        >
            <v-avatar>
                <v-icon >check_circle</v-icon>
            </v-avatar>
           {{ rule.label }}
        </v-chip>
	</v-card>
</template>

<script>
    import PasswordValidation from 'mixins/PasswordValidation'
	export default {
		name: 'PasswordValidationIndicator',
        mixins: [PasswordValidation],
		props: {
			password: String,
            classes: {
                type: String,
                default: 'py-3'
            }
		},
		data(){
			return {
				
			}
		},
		computed: {
            rules(){
                const rulesArray = this.passwordRules.split('|')
                const self = this
                rulesArray.forEach(function(ruleString,index){
                    let rule = {}
                    const ruleArray = ruleString.split(':')
                    rule.ruleName = ruleArray[0]
                    rule.sortOrder = self.getSortOrder(rule.ruleName)
                    rule.values = ruleArray[1].split(',').map(val => Number(val))
                    const min = rule.values[0]
                    const max = rule.values[1]
                    rule.label = self.$tc('passwordRule.'+rule.ruleName,min,{min,max})
                    rule.fulfilled = self.testRule(rule)
                    rulesArray[index] = rule
                })
                return rulesArray.sort(this.sortRules)
            }
		},
		watch: {
			
		},
		methods: {
            testRule(rule){
                let passed = false
                const regExString = {
                    minLowercaseChars : '[a-z]',
                    minUppercaseChars : '[A-Z]',
                    minDigitChars : '[0-9]'
                }
                const functions = {
                    length : this.lengthCheck,
                    minSpecialChars : this.specialCharCheck
                }
                if(regExString[rule.ruleName]){
                    const regex = new RegExp(regExString[rule.ruleName])
                    let matchingChars = 0
                    for(let i = 0; i < this.password.length; i++){
                        let char = this.password.charAt(i)
                        if(regex.test(char)){
                            ++matchingChars
                        }
		            }
                    passed = matchingChars >= rule.values[0]
                } else if(functions[rule.ruleName]) {
                    passed = functions[rule.ruleName](rule)
                }
                return passed
            },
            getSortOrder(rule){
                const order = [
                    'length',
                    'minLowercaseChars',
                    'minUppercaseChars',
                    'minDigitChars',
                    'minSpecialChars'
                ]
                return order.indexOf(rule)
            },
            lengthCheck(rule){
                const min = rule.values[0] 
                const max = rule.values[1]
                const length = this.password.length
                return min <= length && length <= max
            },
            sortRules(a,b){
                return a.sortOrder - b.sortOrder
            },
            specialCharCheck(rule){
                let matchingChars = 0
                for(let i = 0; i < this.password.length; i++){
                    let char = this.password.charAt(i)
                    if(this.specialChars.includes(char)){
                        ++matchingChars
                    }
                }
                return matchingChars >= rule.values[0]
            }
		}
	}
</script>

<style scoped lang="less">
	
</style>
