<template>
    <v-tooltip left :disabled="!Boolean(msg)">
        <template #activator="{ on }">
            <v-icon color="info" :small="small" v-on="on" class="text--lighten-2">info</v-icon>
        </template>
        <span>{{msg}}</span>
    </v-tooltip>
</template>

<script>
    export default {
        name: 'InfoTooltip',
        props: {
            small : {
                type: Boolean,
                default: false
            },
            msg : {
                type: String
            }
        },
        data () {
            return {
                
            }
        }
    }
</script>