<template>
	<div class="text-xs-center" id="emailSent">
		<v-icon class="splash-icon">email</v-icon>
		<v-layout wrap>
			<Headline :headline="$t('emailSent.header')" flowOverride="none"></Headline>
			<v-flex xs12>
				{{$t('emailSent.subHeader')}}
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
	import Headline from 'components/layout/Headline'
  	export default {
		name: 'EmailSent',
		metaInfo : {
			title : 'Success'
		},
		components: {
			Headline
		},
		created(){
			this.$store.commit('setDisplayLogo', false)
		},
		destroyed(){
			this.$store.commit('setDisplayLogo', true)
		}
  	}
</script>

<style lang="less">
	#emailSent {
		.display-1 {
			text-align: center;
		}
	}
	.splash-icon{
		font-size: 100px !important;
		color: black;
		margin-bottom: 30px;
	}
</style>
