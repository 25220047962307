<template>
  <div class="row">
		<Headline :headline="$t('chooseAccount.instruction')"></Headline>
		<v-flex xs12>
			<Messaging outline></messaging>
		</v-flex>
		<v-flex xs12 v-for="account in accounts" :key="account.loginID">
      <v-btn @click="selectAccount(account.loginID)" color="primary" round class="option">
        {{account.username}}
      </v-btn>
    </v-flex>
  </div>
</template>

<script>
  import APIs from 'mixins/APIs'
  import Headline from 'components/layout/Headline'
  import Messaging from 'components/layout/Messaging'
  export default {
    name: 'SelectAccount',
    metaInfo : {
			title : 'Account'
		},
    components: {
    	Headline,
    	Messaging
    },
    mixins : [APIs],
    data () {
      return {
        error : '',
        accounts : []
      }
    },
    computed: {
      resetKey : function(){
        return this.$route.params.resetKey
      }
    },
    created : function(){
      if(!this.$route.params.resetKey){
       self.$router.replace({name:'ForgotPassword'})
      }
      this.$http.get(this.getAccountsAPI(this.resetKey)).then(response => {
        //success callback
        this.accounts = response.data.ArrayList
        if(this.accounts.length == 1) {
        	this.$store.commit('setPasswordResetBackBtn', false)
          this.selectAccount(this.accounts[0].loginID)
        }
      }, error => {
        //error callback
        let response = error.response
        console.log(response)
        if (response.status == 400) {
          this.$store.commit('setMessageError', this.$t('error.invalidCode'))
        } else if (response.status == 404 || response.status == 0) {
          this.$store.commit('setMessageError', this.$t('error.404'))
        } else {
          this.$store.commit('setMessageError', this.$t('error.unknown'))
        }
      })
    },

    methods : {
      selectAccount: function(accountID){
        var self = this;
        this.accounts.forEach(function(account){
          if(account.loginID===accountID){
           self.$store.commit('setResetAccount', account)
           self.$router.push({name:'ResetPassword', params: {resetKey: self.resetKey}})
          }
        })
      }
    }
  }
</script>
