export default {
  // Common
  form : {
    userName : 'Usuario', //Username
    teacherUsername : 'Usuario del Maestro', //Teacher Username
    password : 'Contraseña', //Password
    passwordConfirm : 'Confirmar Contraseña', //Confirm Password
    email : 'Correo Electrónico', //Email
    passwordNew : 'Contraseña Nueva', //New Password
    forgotPassword : 'Olvide mi Contraseña', //Forget your password?
    submit : 'Ingresar' //Submit
  },
  navigation : {
    back : 'Regresar' //Back
  },
  error : {
    404 : 'Ha fallado la coneccion al servidor. Verificar la conneccion a internet.', //Unable to connect to server. Check Internet connection.
    unknown : 'Error Desconocido', //Unknown Error
    invalidCredentials : 'Nombre de Usuario o contraseña inválida', //Invalid Username or Password
    invalidPassword : 'Contraseña inválida', //Invalid Password
    invalidUsername : 'Nombre de Usuario desconocido', //User Not Found
    invalidEmail : 'No existe una cuenta asociada con este correo', //No Account Associated With That Email
    invalidCode: 'Codigo para restablecer contraseña inválido', //Invalid Reset Code
    unmatchingPasswords : 'Las contraseñas no coinciden' //Password Fields Do Not Match
  },
  success : {
    emailSent : 'Revise su correo', //Check your email for further information about recovering/resetting your password.
    passwordReset : 'Contraseña restablecida exitosamente' //Password successfully reset.
  },
  // Component Specific
  loginChoice : {
    students : 'Estudiantes', //Students
    educators : 'Educadores y Padres', //Educators and Parents
    educatorsSub : 'Informes y Administración' //Reports and Administration
  },
  teacherLogin : {
    header : 'Nombre de Usuario Educador/Padre' //Educator/Parent Login
  },
  chooseAccount : {
    header : 'Restablecer contraseña', //Password Reset
    instruction : 'Seleccione su cuenta' //Select Account
  },
  resetPassword: {
    back : 'Cuenta Incorrecta' //Wrong Account
  },
  studentLogin : {
    header : 'Nombre de Usuario estudiante' //Student Login
  },
  chooseClass : {
    header : 'Seleccione su Clase', //Select Your Class
    subHeader : 'Maestro:', //Teacher:
    back : 'Maestro equivocado?' //Wrong Teacher?
  },
  chooseStudent : {
    header : 'Seleccione su Nombre', //Select Your Name
    subHeader : 'Clase', //Class:
    back : 'Clase equivocada?' //Wrong Class?
  },
  studentPassword : {
    header : 'Ingresar Contraseña', //Enter Password
    subHeader : 'Estudiante', //Student:
    back : 'No eres tu?' //Not You?
  },
  parent_letter : {
    intro:
      'Buenas noticias, Su hijo está usando ExploreLearning Reflex, el ' +
      'programa más efectivo (¡y divertido!) del mundo para ayudar a los ' +
      'estudiantes a dominar sus operaciones de matemáticas básicas de suma, ' +
      'resta, multiplicación y división. Esta carta contiene información de ' +
      'acceso del estudiante, así como instrucciones para configurar su propia ' +
      'Cuenta de Reportes de Reflex para Padres, que le permitirá monitorear y ' +
      'apoyar a su hijo en su trayecto hacia la fluidez en las operaciones ' +
      'matemáticas.',
    addChild:
      '*Para añadir a su hijo, necesitará la información de la Tarjeta de ' +
      'Acceso de Estudiante de su hijo.',
    access:
      'En el futuro, para acceder su Cuenta de Reportes visite reflexmath.com ó ' +
      'go-el.com, luego ingrese su información de nombre de usuario y contraseña.',
    copy:
      'Como Reflex incluye juegos divertidos, es muy probable que los hermanos ' +
      'o amigos quieran también probarlo. Reflex monitorea consistentemente el ' +
      'avance del estudiante y se adapta a las necesidades de cada estudiante. ' +
      'Justamente por esa adaptabilidad, al tener varios niños usando la misma ' +
      'cuenta tendrá un impacto negativo en el progreso de su hijo. Usted ' +
      'puede obtener una prueba de 30 días gratis para hasta 5 niños para ' +
      'experimentar Reflex en www.reflexmath.com/trial.',
    doNotShare:
      'Es muy importante que otros niños no usen la cuenta de su hijo.',
    student:
      'Estudiante',
    dearParent:
      'Estimado Padre o Tutor',
    loginCard:
      'Tarjeta de Acceso del Estudiante',
    parentSignUp:
      'Acceso a Cuenta de Reportes para Padres',
    visit:
      'Visite',
    goTo:
      'Ingrese a',
    followInstructions:
      'Siga las instrucciones en la pantalla para crear su cuenta gratuita.',
    onceIn:
      'Una vez dentro del programa, siga las instrucciones para añadir a su ' +
      'hijo.',
    questions:
      '¿Preguntas acerca de Reflex?',
    contactEL:
      'Contacte el Departamento de Servicio a Cliente de ExploreLearning',
  },
  login_card : {
    loginInfo: 'INFORMACIÓN DE ACCESO',
    website: 'SITIO WEB',
    teacherUsername: 'USUARIO DEL MAESTRO',
    class: 'CLASE',
    password: 'CONTRASEÑA',
    doNotShareLogin: 'No compartir esta información con otros.',
  }
  
}
