<template>
    <div class="login">
        <div class="body">
            <nav class="tabs" v-if="showTabs">
                <router-link v-for="(tab, i) in tabs" :to="tab.route" :key="i" :class="{'active':tab.user==userType}">
                    <span class="text">
                        <span :title="tab.label" class="ul-title">{{tab.text}}</span>
                    </span>
                </router-link>
            </nav>
            <div class="inner">
                <img id="login-logo" src="../../assets/explorelearning/img/logo.svg" alt="ExploreLearning" class="mt-4 mb-2">
                <Products></Products>
                <v-layout v-if="loginInstructionMessage" class="my-2">
                    <LoginInstructions :message="loginInstructionMessage"></LoginInstructions>
                </v-layout>
                <Messaging></Messaging>
                <div class="full">
                    <h1 class="ul-title">Log in to your {{this.userType}} account.</h1>
                    <form @submit.prevent="checkForm" action="#">
                        <div :class="['input', { bad : formErrors.username }, {'flat-bottom':!isStudent || studentPasswordMode}]">
                            <label :class="{ bad : formErrors.username, good: username }" for="username">
                                <i class="fas fa-user-circle"></i>
                            </label>
                            <input 
                                :disabled="wait" 
                                id="username" 
                                autocomplete="username"
                                name="username" 
                                v-model="username" 
                                ref="username" 
                                type="text" 
                                placeholder="username" 
                                value=""
                            />
                            <button 
                                :class="{ wait: wait }"
                                class="drop-shadow"
                                :disabled="wait || !username" 
                                v-tippy="{arrow : true, arrowType : 'round', animation : 'fade'}" 
                                content="Login" 
                                v-if="isStudent && !studentPasswordMode" 
                                type="submit"
                            >
                                <i class="fas fa-arrow-right"></i>
                            </button>
                        </div>
                        <div v-if="!isStudent ||  studentPasswordMode" :class="['input', { bad : formErrors.password }]">
                            <label :class="{ bad: formErrors.password, good: password }" for="password">
                                <i class="fas fa-key"></i>
                            </label>
                            <input 
                                :disabled="wait" 
                                id="password" 
                                autocomplete="current-password"
                                name="password" 
                                v-model="password" 
                                ref="password" 
                                :type="passwordVisible?'text':'password'" 
                                placeholder="password" 
                                value=""
                            />
                            <v-icon 
                                v-if="password.length && !passwordVisible" 
                                color="grey lighten-1" 
                                class="mr-1" 
                                @click="togglePasswordVisibility()"
                                v-tippy="{arrow : true, arrowType : 'round', animation : 'fade'}"
                                content="Show Password" 
                            >
                                visibility_off
                            </v-icon>
                            <v-icon 
                                v-else-if="password.length && passwordVisible" 
                                color="grey lighten-1" 
                                class="mr-1" 
                                @click="togglePasswordVisibility()"
                                v-tippy="{arrow : true, arrowType : 'round', animation : 'fade'}"
                                content="Hide Password" 
                            >
                                visibility
                            </v-icon>
                            <button 
                                :class="{ wait: wait }"
                                class="drop-shadow"
                                :disabled="wait || !password || !username" 
                                v-tippy="{arrow : true, arrowType : 'round', animation : 'fade'}" 
                                content="Login"  
                                type="submit"
                            >
                                <i class="fas fa-arrow-right"></i>
</button>
                        </div>
                    </form>
                </div>
                <div class="links text-xs-center">
                    <router-link v-if="!isStudent" :to="{name:'ForgotPassword', query:$route.query}">Forgot username or password?</router-link>
                    <a v-else-if="studentPasswordMode" @click="revertStudentLogin">Did you mean to login with your teacher's username?</a>
                    <router-link v-else :to="{name:'ClassCode', params:{environment:'gizmos'}}">Have a Class Code?</router-link>                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import APIs from 'mixins/APIs'
    import ErrorHandling from 'mixins/ErrorHandling'
    import EnvironmentDetector from 'mixins/EnvironmentDetector'
    import LoginInstructions from 'components/layout/LoginInstructions'
    import Messaging from 'components/layout/Messaging'
    import Products from 'components/login/Products'
    import ProductInfo from 'mixins/ProductInfo'
    import Tracking from'mixins/Tracking'
    import { getUrls } from '@explorelearning/urls';
	export default {
		name: 'Login',
		metaInfo() {
            return {
			    title : this.userType.charAt(0).toUpperCase() + this.userType.slice(1)
            }
		},
        components: { LoginInstructions, Messaging, Products},
		mixins: [APIs, EnvironmentDetector, ErrorHandling, ProductInfo, Tracking],
        data () {
            return {
                charLimit: 50,
                employeeRetry: false,
                expiredSubscription: false,
                formErrors: {},
                studentPasswordMode : false,
                username: '',
                password: '',
                passwordVisible: false,
                wait: false,
            }
        },
		computed: {
            legacyBaseURL(){
                return getUrls().gizmosLegacy
            },
            loginInstructionMessage(){
                if(this.flow=='trial'){
                    return 'teacherLogin.otherProduct'
                } else if (this.flow=='join'){
                    return this.$route.params.loginMessage
                }
            },
            isTouchDevice() {
                return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)
            },
            isStudent () {
                return this.userType=='student'
            },
            showTabs(){
                return (this.userType=='educator' || this.userType=='student') && !this.$route.params.hideTabs
            },
            tabs () {
                const tabs = [
                    { text: 'Student', user: 'student'},
                    { text: 'Educator', user: 'educator'}
                ]
                const userType = this.userType
                const query = this.$route.query
                tabs.forEach(function(t) {
                    t.route = {params: { userType: t.user }, query}
                    t.label = t.text + ' Login'
                    if(t.user==userType){
                        t.text = t.text + ' Login'
                    }
                })
                return tabs
            },
            userType(){
                if(this.$route.params.userType){
                    return  this.$route.params.userType.toLowerCase()
                } else {
                    return ''
                }
            }
        },
        watch : {
            password(val){
                if(val.length>this.charLimit){
                    this.formErrors.password=true
                    this.$store.commit('setMessageError', this.$t('error.fieldLength',{fieldName:'Password', charLimit:this.charLimit}))
                } else {
                    this.$store.commit('setMessageError', '')
                    this.formErrors.password=false
                }
            },
            username(val){
                if(val.length>this.charLimit){
                    this.formErrors.username=true
                    this.$store.commit('setMessageError', this.$t('error.fieldLength',{fieldName:'Username', charLimit:this.charLimit}))
                } else {
                    this.$store.commit('setMessageError', '')
                    this.formErrors.username=false
                }
            },
            '$route.params.userType'(){
                this.username = ''
                this.password = ''
                this.passwordVisible = false
                if(!this.isTouchDevice){
                    if(this.$refs.username){
                        this.$refs.username.focus()
                    }
                }
            }
        },
        created(){
            if(!this.$route.params.userType){
                this.$router.push({params:{userType:'student'}})
            } else {
                if(this.$route.query.u){
                    this.username = this.$route.query.u
                }
                if(this.$route.query.p){
                    this.password = this.$route.query.p
                }
                if(this.username && this.isStudent){
                    this.studentLogin()
                } else if(this.username && this.password){
                    this.nonStudentLogin()
                }
            }
        },
        mounted() {
            if(!this.isTouchDevice && this.$refs.username) {
                this.$refs.username.focus();
            }            
        },
        methods: {
            checkForm(){
                if (this.isStudent) {
                    if(this.studentPasswordMode){
                        this.studentPasswordLogin()
                    } else {
                        this.studentLogin()
                    }
                } else {
                    this.nonStudentLogin()
                }
            },
            // Direct Legacy Users
            directLegacyUser(response){
                this.createTimeStampCookie()
                let URL = this.legacyBaseURL
                const userType = response.data.USERTYPE.toLowerCase()
                const referrerGizmoID = this.extractGizmoId()
                if(referrerGizmoID){
                    URL += '?method=cResource.dspDetail&ResourceID=' + referrerGizmoID
                } else if (userType == 'teacher'){
                    URL += '?method=cUserSecure.dspTeacherHome'
                } else if (userType == 'student'){
                     URL += '?method=cUserSecure.dspChooseLoginClass'
                }
                this.forward(URL)
            },
            // Direct Platform Non-Students
            directNonStudents(response, destinationOverride){
                let app = this.$route.params.app || 'admin'
                this.createTimeStampCookie()
                var userInfo = this.decodeXclaims(response)
                this.$store.commit('setJTI', userInfo.jti)
                if(destinationOverride){
                    this.$router.push(destinationOverride)
                } else {
                    let destination = (userInfo.roleID==4) ? this.getReportingAppURL('reflex') : {name:'Home'}
                    const referrerGizmoID = this.extractGizmoId()
                    if(app == 'web'){			
                        destination = {name:'Subscriptions'}
                        if(this.expiredSubscription){
                            destination.query = {e:this.$t('error.error-23')+' '+this.$t('error.renewSub')}
                        }
                    } else if(referrerGizmoID){
                        destination = this.gizmos.reportingApp + '/launch-gizmo/' + referrerGizmoID
                    }
                    // Gizmo Forward
                    this.forward(destination)
                }
            },
            revertStudentLogin(){
                this.username = ''
                this.studentPasswordMode = false
            },
            studentLogin : async function(){
				var valid = await this.$validator.validateAll()
                this.wait = true
				if(valid){
					this.$http.post(this.getClassesAPI(),{username: this.username.trim()}).then(response => {
						//success
						this.$store.commit('setResponseData', response.data.AuthClassInfo)
                        const query = this.$route.query
						this.$router.push({ name: 'ChooseClass', query})
					}, error => {
						//failure
                        this.studentPasswordMode = true
                        this.wait = false
					})
				}
			},
            studentPasswordLogin : async function(){
                let gizmosLogin = this.legacyBaseURL + '?method=cUser.actAuthenticatePlatform'
                let creds = {'el-username':this.username.trim(), 'el-password': this.password.trim()}
                await this.$http.post(gizmosLogin,creds).then(response =>{
                    if(response.data.SUCCESS){
                        this.directLegacyUser(response)
                    } else {
                        if(response.data.KNOWNUSER){
                            this.$store.commit('setMessageError', response.data.ERRORMSG)
                        } else {
                            this.studentPasswordPlatformLogin()
                        }
                    }
                }, error => {
                    this.studentPasswordPlatformLogin()
                })
            },
            studentPasswordPlatformLogin : async function(){
                this.wait = true
                let product = 'gizmos' || this.currentEnvironment
                let credentials = {"username": this.username, "password": this.password}
                const destination = this.getStudentAppURL(product)
                this.$http.post(this.getLoginAPI('app', false, product), credentials ).then(response => {
                    //success
                    this.createTimeStampCookie()
                    this.forward(destination)
                }, error => {
                     // Check if Login Failed Due to Grizzly Migration Username Conflict
                     this.$http.post(this.getLoginAPI('app', true, product), credentials ).then(response => {
                        this.createTimeStampCookie()
                        this.forward(destination)
                    }, error2 => {
                        const e = this.handleError(error.response, [14,21])
                        if(e.errorID==14){
                            this.$store.commit('setMessageError', this.$t('invalidPassword'))
                        } else if(e.errorID==21) {
                            this.$store.commit('setMessageError', this.$t('error.nonStudentCredsInStudentForm'))
                        }
                        this.$store.commit('setDisableLogin', false)
                        this.wait = false
                    })
                })
            },
            togglePasswordVisibility(){
                this.passwordVisible = !this.passwordVisible
            },
            nonStudentLogin: async function(){
                let gizmosLogin = this.legacyBaseURL + '?method=cUser.actAuthenticatePlatform'
                let creds = {'el-username':this.username.trim(), 'el-password': this.password.trim()}
                await this.$http.post(gizmosLogin,creds).then(response =>{
                    if(response.data.SUCCESS){
                        this.directLegacyUser(response)
                    } else {
                        if(response.data.KNOWNUSER){
                            this.$store.commit('setMessageError', response.data.ERRORMSG)
                        } else {
                            this.nonStudentPlatformLogin()
                        }
                    }
                }, error => {
                    this.nonStudentPlatformLogin()
                })
            },
            nonStudentPlatformLogin: async function(){
                // Platform Login
                let app = this.$route.params.app || 'admin'
                if(this.userType=="employee" && !this.employeeRetry){
                    app = 'csa'
                }
                let userData = {"username": this.username.trim(), "password": this.password.trim()}
                this.wait = true
                this.$http.post(this.getLoginAPI(app), userData ).then(response => {
                    this.directNonStudents(response)
                }, error => {
                    // Check if Login Failed Due to Grizzly Migration Username Conflict
                    this.$http.post(this.getLoginAPI(app, true), userData ).then(response => {
                        this.directNonStudents(response,{name:'Account', query:{w:'usernameConflict',forward:true}})
                    }, error2 => {
                        // Error Callback
                        let hiddenErrors = [16,23]
                        if((this.userType=="employee" && !this.employeeRetry) || this.userType!="employee"){
                            hiddenErrors.push(21)
                        }
                        const e1 = this.handleError(error.response, hiddenErrors)
                        const e2id = this.getErrorID(error2.response)
                        // Special Error Handling
                        if(e1){
                            if(e1.errorID == 14){
                                // Highlight fields on failed login
                                this.formErrors = {
                                    username : true,
                                    password: true
                                }
                            } else if (e1.errorID == 16 || e2id == 16) {
                                // Catch Expired Passwords
                                let userID = error.response.data.loginID
                                if(e2id == 16){
                                    userID = error2.response.data.loginID
                                }
                                const username = this.username
                                const password = this.password                     
                                const params = {userID, username, password}
                                const route = {name:'ExpiredPassword', params, query: { i:'expiredPassword' } }
                                this.$router.push(route)
                            } else  if(e1.errorID == 21){
                                // Improper Permissions
                                if(this.userType=="employee"){
                                    // Log Employee's Into CSA rather than Platform
                                    this.employeeRetry = true
                                    this.nonStudentPlatformLogin()
                                } else {
                                    // Catch students trying to use non-student login form
                                    this.$store.commit('setMessageError', this.$t('error.studentCredsInNonStudentForm'))
                                }
                            } else if(e1.errorID == 23){
                                // Reroute Expired Subscriptions
                                this.expiredSubscription = true
                                this.$route.params.app = 'web'
                                this.nonStudentPlatformLogin()
                            }
                        }
                        this.wait = false
                    })
                })
			}
        }
	}
</script>

<style scoped lang="less">
    #login-logo {
        width: 80%;
    }
    .full {
        width: 100%;
    }

    .drop-shadow:focus {
        box-shadow: 0 0 2px 2px #0072B4;
    }
</style>
