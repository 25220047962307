 <template>
    <UserManagmentModal
        :value="value"
        :headline="$t(('schoolMgmt.classSettings'))"
        size="600"
        @closeDialogs="closeDialogs()"
    >
        <form v-if="value" @submit.prevent="updateClass()">
            <v-card-text>
                <v-flex v-if="isStandAloneTeacher" class="title mb-4">
                    {{selectedClass.name}} {{$t('schoolMgmt.settings')}}
                </v-flex>
                <Messaging class="mb-3" outline/>
                <v-layout wrap>
                    <v-flex :xs6="showOwner" :sm8="showOwner">
                        <v-text-field
                            v-model="className"
                            :label="$t('classMgmt.className')"
                            v-validate="'required'"
                            name="class name"
                            :error-messages="errors.collect('class name')"
                            :disabled="!isOwner"
                        ></v-text-field>
                    </v-flex>
                    <v-flex  v-if="showOwner" xs6 sm4 class="pl-1">
                        <v-select
                            v-model="ownerID"
                            :items="getTeachers(teacherIDs)"
                            item-value="id"
                            :item-text="function(teacher){return buildTeacherName(teacher,isOwner)}"
                            :label="$t('classMgmt.classOwner')"
                            v-validate="'required'"
                            name="class owner"
                            :error-messages="errors.collect('class owner')"
                            :disabled="!isOwner"
                            persistent-hint
                        >
                            <template #selection="{item:teacher}">
                                {{buildTeacherName(teacher,false)}}
                            </template>
                        </v-select>
                    </v-flex>
                    <v-flex v-if="teachers.length>1 || loadingTeachers" xs12>
                        <v-autocomplete
                            v-model="teacherIDs"
                            :items="teachersBesidesOwner"
                            item-value="id"
                            :item-text="function(teacher){return buildTeacherName(teacher,false)}"
                            :label="$t('classMgmt.additionalTeachers')"
                            :disabled="!isOwner"
                            placeholder="Not Shared"
                            chips
                            multiple
                            deletable-chips
                        ></v-autocomplete>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-divider v-if="isStandAloneTeacher"></v-divider>
            <v-expansion-panel v-if="isStandAloneTeacher" style="box-shadow:none;" expand :value="isExpansionPanelOpened">
                <v-expansion-panel-content class="modal-expansion-panel">
                    <template #header>
                        <v-flex class="title" style="margin-left:-8px;">
                            {{$t('classMgmt.generalSettings')}}
                        </v-flex>
                    </template>
                    <v-card-text >
                        <v-alert
                            :value="value"
                            color="info"
                            icon="info"
                            outline
                            class="mx-4 mb-3"
                        >
                            {{$t('classMgmt.applyToAllClasses')}} {{$t('schoolMgmt.waitTime',{settingType:'general'})}}
                        </v-alert>
                        <v-layout>
                            <v-flex xs8>
                                <v-select
                                    v-model="startMonth"
                                    :items="months"
                                    item-text="text"
                                    item-value="id"
                                    :label="$t('schoolMgmt.startDate')"
                                >
                                    <template #prepend>
                                        <v-tooltip right>
                                            <template #activator="{ on }">
                                                <v-icon color="info" v-on="on" class="text--lighten-2">info</v-icon>
                                            </template>
                                            <span>{{$t('classMgmt.usedInReports')}}<br/>{{$t('classMgmt.dateToConsider')}} <i>{{$t('classMgmt.thisSchoolYear')}}</i>.</span>
                                        </v-tooltip>
                                    </template>
                                </v-select>
                            </v-flex>
                            <v-flex xs4 class="ml-1">
                                <v-select
                                    v-model="startDay"
                                    :items="getDays(getDaysInMonth(startMonth))"
                                    item-text="label"
                                    item-value="id"
                                />
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-flex xs12>
                                <v-select
                                    v-model="defaultLanguage"
                                    :items="languages"
                                    item-text="label"
                                    item-value="id"
                                    :label="$t('schoolMgmt.defaultLang')"
                                >
                                    <template #prepend>
                                        <v-tooltip right>
                                            <template #activator="{ on }">
                                                <v-icon color="info" v-on="on" class="text--lighten-2">info</v-icon>
                                            </template>
                                            <span>{{$t('classMgmt.changeDefaultLanguage')}}<br/>{{$t('classMgmt.doesNotAffectTeacher')}}</span>
                                        </v-tooltip>
                                    </template>
                                </v-select>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-divider v-if="isStandAloneTeacher"></v-divider>
            <v-card-actions v-if="isOwner">
                <v-btn v-if="!selectedClass.selectedClassIsRostered" color="error" flat class="elevation-0" @click="openDeleteClassDialog()" >
                    <v-icon>delete</v-icon>&nbsp;{{$t('classMgmt.deleteClassHeader')}}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn :disabled="loadingTeachers" color="primary" class="elevation-0" type="submit">
                    <v-icon v-show="!loadingTeachers">check</v-icon>
                    <v-icon v-show="loadingTeachers">fas fa-circle-notch fa-spin</v-icon>
                    &nbsp;{{$t('classMgmt.updateSettingsButton')}}
                </v-btn>
            </v-card-actions>
            <v-card-actions v-else>
                <v-btn v-if="!selectedClass.selectedClassIsRostered" color="error" flat class="elevation-0" @click="leaveModal=true"  >
                    <v-icon>remove_circle</v-icon>&nbsp;{{$t('classMgmt.leaveClass')}}
                </v-btn>
            </v-card-actions>
        </form>
        <UserManagmentModal
            :value="ownershipModal"
            :headline="$t(('classMgmt.ownershipHeading'))"
            size="600"
            @closeDialogs="ownershipModal=false"
        >
            <v-card-text>
                <v-flex>
                    {{$t('classMgmt.ownershipWarning')}}
                    <ul class="mt-2">
                        <li>{{$t('classMgmt.ownershipBullet1')}}</li>
                        <li>{{$t('classMgmt.ownershipBullet2')}}</li>
                        <li>{{$t('classMgmt.ownershipBullet3')}}</li>
                    </ul>
                </v-flex>
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" flat class="elevation-0" @click="ownershipModal=false" >
                    <v-icon>close</v-icon>&nbsp;{{$t('classMgmt.cancel')}}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" class="elevation-0" @click="confirmUpdate()">
                    <v-icon>check</v-icon>&nbsp;{{$t('classMgmt.accept')}}
                </v-btn>
            </v-card-actions>
        </UserManagmentModal>
        <UserManagmentModal
            :value="leaveModal"
            :headline="$t(('classMgmt.leaveHeading'))"
            size="600"
            @closeDialogs="leaveModal=false"
        >
            <v-card-text>
                <v-flex>
                    {{$t('classMgmt.leaveWarning')}}
                </v-flex>
            </v-card-text>
            <v-card-actions>
                <v-btn color="error" flat class="elevation-0" @click="leaveModal=false" >
                    <v-icon>close</v-icon>&nbsp;{{$t('classMgmt.cancel')}}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" class="elevation-0" @click="leaveClass()">
                    <v-icon>check</v-icon>&nbsp;{{$t('classMgmt.accept')}}
                </v-btn>
            </v-card-actions>
        </UserManagmentModal>
    </UserManagmentModal>
</template>

<script>
    import  APIs from 'mixins/APIs'
    import Constants from 'mixins/Constants'
    import Messaging from 'components/layout/Messaging'
    import UserManagmentModal from 'components/layout/UserManagmentModal'
    import { mapGetters } from 'vuex'
	export default {
        name: 'ClassSettingsModal',
        components: { Messaging, UserManagmentModal },
        mixins: [APIs, Constants],
        props: {
            hasClassCap: Boolean,
            institutionID: Number,
            isStandAloneTeacher: Boolean,
            loadingTeachers: Boolean,
            productId: Number,
            selectedClass: Object,
            selectedClassIsRostered: Boolean,
            selectedSchool: Object,
            teachers: Array,
            tenantID: Number,
            universalSettingsPanel: Boolean,
            value: Boolean,
        },
        data() {
            return {
                className: "",
                defaultLanguage: null,
                isExpansionPanelOpened: [false],
                ownerID: null,
                startDay: null,
                startMonth: null,
                teacherIDs: [],
                ownershipModal: false,
                confirmOwnershipChange: false,
                leaveModal: false
            }
        },
		computed : {
            ...mapGetters('platformData/profile', ['profile']),
            isOwner(){
                return this.selectedClass.ownerID==this.profile.id
            },
            platform(){
                 return this.$store.state.platform
            },
            showOwner(){
                return this.teacherIDs.length>1
            },
            teachersBesidesOwner(){
                return this.teachers.filter(teacher => {
                    return teacher.id != this.ownerID
                })
            },
        },
        watch: {
            value(val){
                if (val){
                    this.setup()
                }
            }
        },
        created() {
            this.setup()
        },
        methods: {
            buildTeacherName(teacher, includeOwnerFlag){
                let name = teacher.lastName+', '+teacher.firstName
                if(includeOwnerFlag && teacher.id==this.selectedClass.ownerID){
                    name += ' ('+this.$t('classMgmt.currentOwner')+')'
                }
                return name
            },
            closeDialogs() {
                this.ownershipModal = false
                this.leaveModal = false
                this.$emit('closeDialogs')
            },
            confirmUpdate(){
                this.confirmOwnershipChange = true
                this.updateClass()
            },
            getTeachers(IDs){
                return this.teachers.filter(function(teacher){
                    return IDs.includes(teacher.id)
                })
            },
            async leaveClass(){
                let classId = (this.selectedClass.id)
                let teacherIds = [this.profile.id]
                await this.platform.removeTeachersFromClass({classId, teacherIds})
                await this.$emit("updateModel")
                this.closeDialogs()
            },
            openDeleteClassDialog() {
                this.$emit('openDialog', 'deleteClassModal')
            },
            setup(){
                this.className = this.selectedClass.name
                this.teacherIDs = this.selectedClass.teacherIDs
                this.ownerID = this.selectedClass.ownerID
                if(this.isStandAloneTeacher){
                    this.startMonth = this.selectedSchool.startOfSchool.month
                    this.startDay = this.selectedSchool.startOfSchool.day
                    this.defaultLanguage = this.selectedSchool.settings.defaultLanguageId
                }
                this.isExpansionPanelOpened = [this.universalSettingsPanel]
                if(!this.isOwner){
                    let msg = this.$t('info.notOwner')
                    this.$store.commit('setMessageInfo', msg)
                } else {
                    this.$store.commit('setMessageInfo', null)
                }
            },
            async updateClass(){
                var valid = await this.$validator.validateAll()
    		    if(valid){
                    if(this.selectedClass.ownerID !== this.ownerID && !this.confirmOwnershipChange){
                        this.ownershipModal = true
                    } else {
                        let originalTeacherIDs = this.selectedClass.teacherIDs
                        let teacherIDs = this.teacherIDs
                        let teachersToAdd = teacherIDs.filter(function(teacherID){
                            return !originalTeacherIDs.includes(teacherID)
                        })
                        let teachersToRemove = originalTeacherIDs.filter(function(teacherID){
                            return !teacherIDs.includes(teacherID)
                        })
                        let classId = this.selectedClass.id
                        let institutionId = this.institutionID
                        let productId = this.productId
                        let ownerId = this.ownerID
                        let name = this.className
                        let isClassCodeActive = this.selectedClass.isClassCodeActive
                        let isClassCodeEnabled = this.selectedClass.isClassCodeEnabled
                        let self = this;
                        (async function() {
                            if(teachersToAdd.length){
                                await self.platform.addTeachersToClass({classId, teacherIds: teachersToAdd})
                            }
                            await self.platform.updateClass({id:classId, institutionId, productId, ownerId, name, isClassCodeEnabled, isClassCodeActive})
                            if(teachersToRemove.length){
                                await self.platform.removeTeachersFromClass({classId, teacherIds: teachersToRemove})
                            }
                            if(self.isStandAloneTeacher){
                                await self.updateSchool()
                            } else {
                                await self.$emit("updateModel")
                                self.closeDialogs()
                            }
                            self.isExpansionPanelOpened = [false]
                        })()
                    }
                }
            },
            async updateSchool(){
                let tenantId = this.tenantID
                let id = this.selectedSchool.id
                let typeId = this.selectedSchool.typeId
                let settings = {
                    defaultLanguageId: this.defaultLanguage,
                    allowStudentEnrollmentByTeachers: this.allowEnrollment || true
                }
                let startOfSchool = {
                    month: this.startMonth,
                    day: this.startDay
                }
                await this.platform.updateInstitution({tenantId, id, typeId, settings, startOfSchool})
                await this.$emit("updateModel", id)
                this.$emit("handleSuccess", 'Settings Updated')
                this.closeDialogs()
            },
        }
	}
</script>