<template>
  <router-view></router-view>
</template>

<script>
	export default {
		name: 'AdminContainer',
		metaInfo: {
		titleTemplate: function(pageName){
			let admin = 'ADMIN'
			let environment = 'ExploreLearning'
			let title = admin + ' '
			if(this.$route.meta.flow){
				let flow = this.$t('flow.'+this.$route.meta.flow)
				title = title + flow
			}
			if(pageName){
				if(title){
					title = title + ' - '
				}
				title = title + pageName
			}
			if(title){
				title = title + ' | '
			}
			title = title + environment
			return title
			}
		}
	}
</script>
