<template>
	<v-layout wrap>
    	<Headline :headline="$t('grantCompleteClosed.header')"></Headline>
    	<v-flex xs12 mb-3>{{$t('grantCompleteClosed.message')}}</v-flex>
    	<v-flex xs12>{{$t('grantComplete.message2-0')}}&nbsp;<a href="mailto:elgrants@explorelearning.com">{{$t('grantComplete.grantsTeam')}}</a>.</v-flex>
    </v-layout>
</template>

<script>
  import Headline from 'components/layout/Headline'
  import EnvironmentDetector from 'mixins/EnvironmentDetector'
  export default {
    name: 'GrantComplete',
    metaInfo : {
			title : 'Complete'
		},
    components: {Headline},
    mixins: [EnvironmentDetector],
    created(){
  		if(!this.$store.state.navigation.allowConfirm){
    		this.$router.replace({name:'GrantSchoolInfo'})
    	}
  	}
  }
</script>
